import { createSlice } from "@reduxjs/toolkit";

const initialValue = { article: [] };

export const articleSlice = createSlice({
  name: "articleInfo",
  initialState: { value: initialValue },
  reducers: {
    setArticle: (state, action) => {
      console.log(action.payload);
      let article = action.payload;

      state.value = { article };
    },
    removeArticle: (state, action) => {
      state.value = initialValue;
    },
  },
});

export const { setArticle, removeArticle } = articleSlice.actions;

export default articleSlice.reducer;
