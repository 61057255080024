import plantAndInfraImg2 from '../img/DSC03773.JPG'
import plantAndInfraImg1 from '../img/DSC03667.JPG'
import { MdLocationOn } from 'react-icons/md'
import cust1 from '../img/erlLogo.png'
import cust2 from '../img/cgLogo.png'
import cust3 from '../img/InfosysLogo.png'
import cust4 from '../img/hitachiLogo.png'
import cust5 from '../img/SchneiderLogo.png'
import cust6 from '../img/abbLogo.png'
// import sns from '../img/sns-logo.png'
export default function Home() {
    const cardContent = [
        { 'header': 'We have ...', 'para': "Machinery and Infrastructure which define are our strengths. We are well equipped to have better work process and quality of work. ...", "href": "#plantAndInfra" },
        // { 'header': 'We Continue to...', 'para': "Innovation at work (Kaizen) is an important and most focused area at work for redefining the productivity with out quality compromise..." },
        { 'header': 'We Optimize...', 'para': "With 20+ years of experence, Innovation at work (Kaizen) is an important and most focused area at work for redefining the productivity with out quality compromise...", "href": "#" },
    ]
    return (
        <div className='snsHome'>
            <section className=' firstContainer' style={{ height: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* <img src={sns} alt="" className='snsLogo' /> */}


            </section>
            <br />
            <section className='infoCardContainer contentContainer'>

                {cardContent.map((data, i) => {
                    return (
                        <div className='infoCards' key={i}>
                            <h3>{data.header}</h3>
                            {data.href ? <p>{data.para} <a href={data.href}>Read More</a></p> : data.para}

                        </div>
                    )
                })}
            </section>
            <br />
            <br />

            <section className='visionAndMission contentContainer'>
                <header className='headerContainer'>
                    <h1 className='mainHeader'>Our Vision and Mission</h1>
                </header>
                <div className="visionAndMissionBody">
                    <div className='vision'>
                        <h4>Our Vision</h4>
                        <p>
                            Our Vision is to be a leader in design and manufacturing of electrical equipment achieving a technological breakthrough in power systems and industrial Integrity to simplify in view of making the most complex as most simplest to the sense of electrical industrial applications and process
                        </p>
                    </div>
                    <div className='mission'>
                        <h4>We want to achieve our vision by…</h4>
                        <ol>
                            <li>
                                Developing a strong infrastructure with a highly competent technical team through continuous training in all essential aspects to achieve the state of art technology.
                            </li>
                            <li>
                                Giving at most importance to environment preservation, human health and occupational safety along with maintaining the highest quality standard.
                            </li>
                            <li>
                                Provide and facilitate the most significant, competitive, feasible,adaptable, affordable and lucrative products to industries with least harm to environment and mankind and to ease the effort of measuring, understanding and applied sciences to their needs…. <b>
                                    and that is our Mission
                                </b></li>
                        </ol>
                    </div>
                </div>
            </section>
            <br /><br />
            <section className="plantAndInfra contentContainer" id='plantAndInfra'>
                <div className="headerContainer">
                    <h1 className='mainHeader'>Machinery and Infrastructure</h1>
                </div>
                <div className='custBg1 p-5'>
                    <div className='plantAndInfraContent'>
                        <div className='h-50 '>
                            <img className='img imgLeft' src={plantAndInfraImg1} style={{ 'width': '100%' }} alt="" />
                        </div>
                        <div className='custBg '>
                            <h4>
                                Plant and Infrastructure
                            </h4>
                            <br />
                            <ul>
                                <li>
                                    Dedicated Shop floor area of  app.10000 sq ft spread across 3 Floors with uninterrupted power supply.
                                </li>
                                <br />
                                <li>
                                    Neat and systematic store area with FIFO, Separate material loading and unloading area, heavy duty material lift to reach all 3 floors
                                </li>
                                <br />
                                <li>
                                    Anti-static area with dust proof environment .
                                </li>
                                <br />
                                <li>
                                    Special  jigs, fixtures, and testing units developed internally to ensure Productivity and consistent Quality.
                                </li>
                            </ul>
                        </div>

                    </div>
                    <br />
                    <div className='plantAndInfraContent'>
                        <div className='h-50'>
                            <img className='img' src={plantAndInfraImg2} style={{ 'width': '100%' }} alt="" />
                        </div>
                        <div className='custBg'>
                            <h4>
                                ShopFloor and Production
                            </h4>
                            <br />
                            <ul>
                                <li>
                                    Multi spindle Winding Machines
                                </li>
                                <br />
                                <li>
                                    Weller soldering stations RoHS and Non-RoHs
                                </li>
                                <br />
                                <li>
                                    Ultra Sonic Welding machines
                                </li>
                                <br />
                                <li>
                                    Pneumatic Toggle Press
                                </li>
                                <br />
                                <li>
                                    Wire Cutting and stripping machine
                                </li>
                                <br />
                                <li>
                                    Pick and Place Robotic machine
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

            </section>
            <br /><br />
            <section className='customer'>
                <div className="headerContainer">
                    <h1 className='mainHeader text-center'>Our Customers</h1>
                </div>
                <div className='text-center'>
                    <img src={cust1} alt="" style={{ width: '250px' }} />
                    <img src={cust2} alt="" style={{ width: '250px' }} />
                    <img src={cust3} alt="" style={{ width: '250px' }} />
                    <img src={cust4} alt="" style={{ width: '250px' }} />
                    <img src={cust5} alt="" style={{ width: '250px' }} />
                    <img src={cust6} alt="" style={{ width: '250px' }} />
                    {/* <div className='custBg1'>
                    </div>
                    <div className='custBg1'>
                    </div>
                    <div className='custBg1'>
                    </div>
                    <div className='custBg1'>
                    </div>
                    <div className='custBg1'>
                    </div> */}

                </div>
            </section>
            <br /><br />
            <section className='aboutUs contentContainer'>
                <div className="headerContainer">
                    <h1 className='mainHeader text-center'>About Us</h1>
                </div>
                <div className='custBg1 p-5'>
                    SNS Industries was established in the year 1992, located in Peenya – one of the largest industrial area in Asia, Bangalore, India.<br /><br />

                    We have fifteen plus years of experience in electrostatic relays, coils, winding and semaphore indicator switches, instrument transformers and relay sub-assembly  manufacturing process.<br /><br />

                    The company is well known for its vision, progressive management, lean production, quality and improvised production systems.We operate  with an exclusive ERP – designed and customized  for transparent and detailed stock maintenance – The various stores are identified according to customer material, subcontract material and excise materials purchased for production and trade.<br /><br />

                    Over the period we have constantly improved our systems in the areas of production and quality management and developed our skills in the areas of material planning, procurement, vendor development, inventory management and manpower handling so as to extend a better value chain to our customers by offering products to our customers on fully bought out basis.<br /><br />

                    We as an industry have a dedicated shop floor with state of the art technology as per the international standards for the production of static and numerical relays. This includes Anti-static flooring with a dust proof environment. Work tables are equipped with pneumatic lines, anti-static mats, wristbands  adhering to disciplined and safe work practices.<br /><br />

                    With our many years of experience in this industry, we have pioneered in the technology for manufacturing Relays, Contactors & Coils. Our production processes are assembly – which involves special processes like soldering & calibration and final testing of the product as per norms including HV testing up to 2kV<br /><br />

                    We also procure material from the approved sources and subject to inspection at the incoming level to maintain the quality norms. We support our vendors in improving their process which in turn improves the productivity, quality and maintains the competitive cost.<br /><br />

                    Our Infrastructure has more than 10 multi spindle winding machines, thermal treatment chamber, automated pre-planishing machines, automated wire stripping machines, high resolution optical calibration systems, pneumatic tools and fixtures, RoHS supported Soldering systems and Temperature controlled tinning systems and we support to produce RoHS products.
                </div>
            </section>
            <br /><br />
            <section className='contactUs contentContainer'>
                <div className="headerContainer">
                    <h1 className='mainHeader text-center'>Contact Us</h1>
                </div>
                <div className='custBg1 text-center'>
                    <div className='p-5'>
                        <div className="address">
                            <h4>Address</h4>
                            <address className=''>
                                Next to #26c, 10th main, Peenya 3rd Stage,
                                Peenya Industrial Area, <br />
                                Bangalore- 560058 <br />
                                Karnataka ,INDIA <br />
                            </address>
                        </div>
                        <div className="email">
                            <b> E-mail :</b>  snsindustry@gmail.com <br /> <br />
                            <b>Telephone :</b>  +91-80-41170810
                        </div>
                        <br />
                        <a href="https://goo.gl/maps/dFz4im8W7xNP5GLX7" target="_blank" rel='noreferrer'>Reach Us <MdLocationOn /></a>
                    </div>
                </div>
            </section>
            <br /><br />

        </div>
    )
}