import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap'
import { useGetUserDetails } from '../../../hooks/useGetUserDetails';
export default function WithPermission({ roleRequired, children }) {
    const { isUser, user } = useGetUserDetails();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        // console.log('yooo', show ? 'true' : 'false')
        setShow(false);
    };
    // const { user } = useSelector((state) => state.userInfo.value);

    // const access = { "Article Master": 2, "Stages Master": 2, "UOM Master": 2, "Customer Master": 2, "Supplier Master": 2, "Instrument Master": 2, "Test Plan Master": 2, "WO": 2, "Process Reports": 2, "Incoming Quality": 2, "Quality Reports": 2, "Plots": 2, "Roles": 2 }
    const access = user?.access
    // console.log(Object.entries(access))
    // console.log(access[roleRequired])
    // console.log(roleRequired)
    return (
        <>
            {
                Object.keys(access).includes(roleRequired)
                    ?
                    roleRequired === "Incoming Quality" ?
                        access[roleRequired] > 1
                            ?
                            children
                            :
                            null
                        :
                        access[roleRequired] > 0
                            ?
                            children
                            :
                            null
                    :
                    null
            }
            {/* {
                Object.keys(access).includes(roleRequired) ? children :
                    null
            } */}
        </>
    )
}
