import React from 'react'
import { Link } from 'react-router-dom'
import { Tabs } from 'antd'
import WithPermission from '../../../Auth/Permissions/WithPermission'

export const PlotsNav = ({ name }) => {
    const tabs = [
        {
            role: "WO In-Progress",
            link: "/mesHome/plots/inProgress",
            name: "WO in-progress",
            title: "WO in-progress",
        },
        {
            role: "WO Shortage/Rejection",
            link: "/mesHome/plots/short_rej",
            name: "WO shortage/rejection",
            title: "WO shortage/rejection",
        },
        {
            role: "YTD/MTD",
            link: "/mesHome/plots/ytd_mtd",
            name: "YTD/MTD",
            title: "YTD/MTD",
        },
    ]

    return (
        <div>
            {/* <Tabs centered defaultActiveKey={name} tabPosition="top">
                {
                    tabs.map((tab) => {
                        return (
                            <Tabs.TabPane tab={<WithPermission roleRequired={tab.role}><Link to={tab.link} style={name === tab.name ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>{tab.title}</Link></WithPermission>} key={tab.name} />
                            // <Tabs.TabPane tab={<Link to={tab.link} style={name === tab.name ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>{tab.title}</Link>} key={tab.name} />
                        )
                    })
                }
            </Tabs> */}
            <Tabs centered defaultActiveKey={name} tabPosition="top"
                items={
                    tabs.map((tab) => {
                        return {
                            label: <WithPermission roleRequired={tab.role}><Link to={tab.link} style={name === tab.name ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>{tab.title}</Link></WithPermission>,
                            key: tab.name,
                        }
                    })
                }
            />
        </div>
    )
}
