import React from 'react'
import { PlotsNav } from './helper/PlotsNav'
import { Plots } from './Plots'

export const PlotsHome = () => {
    return (
        <div className='p-1'>
            <PlotsNav />
        </div>
    )
}
