import React, { useEffect, useState } from 'react'
import axios from '../../../api/axios';
import { Breadcrumb, Button, Spin, Tabs } from 'antd'
import { FileOutlined } from '@ant-design/icons'
import './helper/style.scss'
export const S3bucket = () => {
    const sharKK = true


    const [path, setPath] = useState([])

    const [folders, setFolders] = useState([])
    const [subContents, setSubContents] = useState({})
    const [loading, setLoading] = useState(false)


    const downloadContent = async (folderName, Key) => {
        await axios.get(`/s3/download`, { params: { folderName, Key }, responseType: 'blob' }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', Key.replace(folderName, ''));
            document.body.appendChild(link);
            link.click();
        })


    }

    const getS3Data = async (parm = '') => {
        setLoading(true)
        await axios.get(`/s3/`, { params: { parm } }).then((res) => {
            if (parm === "") {
                return setFolders(res.data.s3Data.CommonPrefixes)
            }
            setSubContents((prev) => {
                let obj = Object.keys(subContents).find((data) => parm.substring(data))
                if (obj) {
                    parm = obj
                }
                if (res.data.s3Data.CommonPrefixes.length === 0) {
                    return { ...prev, [parm]: res.data.s3Data.Contents }
                }
                return { ...prev, [parm]: res.data.s3Data.CommonPrefixes }
            })
        }).catch((e) => {
            console.log(e)
        })
        setLoading(false)
    }

    useEffect(() => {
        getS3Data()
    }, [])

    console.log(window.innerWidth)

    return (
        <div className='pt-5 mainHeight container'>
            <Tabs
                defaultActiveKey="1"
                tabPosition={window.innerWidth < 650 ? "top" : "left"}
                items={folders.map((folder, i) => {
                    return {
                        label: <Button type='primary' style={{ width: '100%' }} onClick={() => { getS3Data(folder.Prefix); setPath([folder.Prefix]) }} disabled={loading}>
                            {String(folder.Prefix).replace('/', '')}
                        </Button>,
                        key: i,
                        children: <div className='d-flex flex-column gap-4' style={{ padding: '1rem' }}>
                            <Breadcrumb>
                                {
                                    path.map((item, i) => {
                                        return <Breadcrumb.Item>
                                            <a onClick={() => {
                                                if (path.length - 1 > i) {
                                                    setPath(path.splice(0, i + 1))
                                                }
                                                getS3Data(item)
                                            }}>{item.split('/').at(-2)}</a>
                                        </Breadcrumb.Item>

                                    })
                                }
                            </Breadcrumb>
                            {loading ? <Spin /> : null}
                            <div style={{ maxHeight: '65vh', minHeight: 'max-content', overflow: 'auto', width: "fit-content" }}>
                                <div className='repoContainer' hidden={loading}>
                                    {
                                        Object.entries(subContents).map(([key, values]) => {
                                            console.log([key, values])
                                            return values.map((value) => {
                                                // console.log([value])
                                                if (Object.keys(value).includes('Prefix')) {
                                                    return <>
                                                        <Button type='secondary' className='card repoFolders' onClick={() => { getS3Data(value.Prefix); setPath([...path, value.Prefix]) }} disabled={loading}>
                                                            {value.Prefix.trim().split('/').at(-2)}
                                                        </Button >
                                                    </>
                                                }
                                                else {
                                                    return <Button className='card repoFiles' onClick={() => downloadContent(key, value.Key)} disabled={loading}>
                                                        {value.Key.split('/').at(-1)}
                                                    </Button>
                                                }
                                            })
                                        })
                                    }
                                </div>
                            </div>
                        </div>,
                    }
                })}
            />

            {/* {
                folders.map((folder) => {
                    return <button onClick={() => { getS3Data(folder.Prefix); setPath([folder.Prefix]) }}>
                        {String(folder.Prefix).replace('/', '')}
                    </button>
                })
            }
            <br />
            <div>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {
                            path.map((item, i) => {
                                return <li className={`breadcrumb-item  ${item === path.at(-1) ? 'active' : ''}`} onClick={() => {
                                    if (path.length - 1 > i) {
                                        setPath(path.splice(0, i + 1))
                                    }
                                    getS3Data(item)
                                }}>{item.split('/').at(-2)}</li>
                            })
                        }
                    </ol>
                </nav>

                <div style={{ maxHeight: '70vh', minHeight: 'max-content', overflow: 'auto' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(8,1fr)', gap: '10px' }}>
                        {
                            Object.entries(subContents).map(([key, values]) => {
                                return values.map((value) => {
                                    if (Object.keys(value).includes('Prefix')) {
                                        return <>
                                            <div style={{ background: 'lightblue', width: 'max-content', padding: '10px' }} onClick={() => { getS3Data(value.Prefix); setPath([...path, value.Prefix]) }}>
                                                {value.Prefix.trim().split('/').at(-2)}
                                            </div >
                                        </>
                                    }
                                    else {
                                        return <div style={{ background: 'lightgreen', width: 'max-content', padding: '10px' }} onClick={() => downloadContent(key, value.Key)}>
                                            {value.Key.split('/').at(-1)}
                                        </div>
                                    }
                                })
                            })
                        }
                    </div>
                </div>
            </div> */}

        </div>
    )
}