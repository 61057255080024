import React, { useState } from 'react';
import { Button, Form, Input, Drawer, Spin, Alert } from 'antd';
import axios from '../../../../api/axios';
import { AlertMsg } from '../../../Common/AlertMsg';

export const Register = () => {
    const [isLoading, setIsLoading] = useState(false);
    const initailMsg = { msg: '', isMsg: false, variant: 'danger' };
    const [msg, setMsg] = useState(initailMsg);
    const msgClk = (data) => {
        setMsg(initailMsg);
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 16,
            },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        setIsLoading(true);
        axios.post('/user/register', values).then((res) => {
            console.log(res);
            setMsg({ ...msg, msg: res.data.message, isMsg: true });
            setIsLoading(false);
            console.log(values)
        })
            .catch((e) => {
                console.log(e);
                setMsg({ ...msg, msg: e.response.data.msg, isMsg: true });
                setIsLoading(false);
            });
    };

    const [visible, setVisible] = useState(false);

    const showDrawer = (e) => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <>
            <Button
                className="m-1 w-100"
                name="Login"
                type="primary"
                onClick={(e) => showDrawer(e)}
            >
                Register
            </Button>
            <Drawer
                title="Register"
                placement="right"
                size={window.innerWidth < 650 ? 'default' : 'large'}
                onClose={onClose}
                open={visible}
                style={{ zIndex: "1200" }}
            >
                {msg?.isMsg ? <Alert message={msg.msg} showIcon /> : null}
                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('password') === value
                                    ) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error(
                                            'The two passwords that you entered do not match!'
                                        )
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        label="Phone Number"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your phone number!',
                            },
                        ]}
                    >
                        <Input
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            Register
                        </Button>
                    </Form.Item>
                </Form>
                {isLoading ? (
                    <div className="spin_center">
                        <Spin></Spin>
                    </div>
                ) : (
                    ''
                )}
            </Drawer>
        </>
    );
};
