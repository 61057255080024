import React, { useState, useEffect } from 'react'
import { Button, Spin, Select } from 'antd';
import axios from '../../../../../../api/axios';
import DataTable from 'react-data-table-component';
import './style.scss'
import { Masternav } from '../../../helper/MasterNav';
import { socket } from '../../../../../../services/socket';
import { Backdrop, CircularProgress } from '@mui/material';
import moment from 'moment'
import { useGetUserDetails } from '../../../../../../hooks/useGetUserDetails';

export const ArticleStages = ({ hidden }) => {
    const { user } = useGetUserDetails()
    const [options, setOptions] = useState([]);
    const [articles, setArticles] = useState([]);
    const [selectedArticle, setSelectedArticle] = useState([])
    const [search, setSearch] = useState('');
    const [cust, setCust] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [sta, setSta] = useState([])
    const [filteredSta, setFilteredSta] = useState([])
    const [data, setData] = useState(true);
    const [loading, setLoading] = useState(false)

    const showDrawer = (e) => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const handleChange = (value) => {
        setSelectedArticle(value)
    };


    const [show, setShow] = useState(false);



    const [stageNames, setStageNames] = useState([{ "stageName": "" }]);
    const addStageName = () => {
        const rowInputs = {
            stageName: "",
        };
        setStageNames([...stageNames, rowInputs]);
    };
    const removeStageName = (index) => {
        const rows = [...stageNames];
        rows.splice(index, 1);
        // console.log(rows);
        setStageNames(rows);
    };
    const handleStageNameInput = (index, e) => {
        const { name, value } = e.target;
        const rowsInput = [...stageNames];
        rowsInput[index][name] = value;
        setStageNames(rowsInput);
    };

    const handleShow = () => setShow(true);

    const handleSubmit = async (e) => {
        e.preventDefault()

        await axios.post('/stage/stages/', { stageNames, selectedArticle })
        await axios.get('/article/')

    }

    const columns = [
        {
            name: 'Article Num',
            cell: row => <div title={row.art_no}>{row.art_no}</div>,
            width: "200px",
        },
        {
            name: 'Stages',
            cell: row => Object.keys(row.stages).map((stageName, i) => {
                return <div key={i}>
                    <span title={row.stages[stageName]}>{row.stages[stageName]}{i < (Object.keys(row.stages))?.length - 1 ? <>,&nbsp;</> : `.`}</span>
                </div>
            })
        },
        {
            name: 'Date',
            cell: row => moment(row.date).utc().format("DD-MM-YYYY"),
            width: "100px",
            right: true,
            omit: user?.access?.Date > 0 ? false : true,
        },
    ]

    useEffect(() => {
        (async function () {
            let find = {}
            await axios.get('/customer/select').then((res) => {
                setCust(res.data.custs)
                find["cust_proj"] = res.data.custs[0]
            })
            await axios.post('/article/custfind', find).then((res) => {
                if (res.status === 200) {
                    setArticles(res.data.articles)
                    let artOptions = res.data.articles.map((art) => {
                        if (art.enable) {
                            return <Select.Option key={art['art_no']}>{art['art_no']}</Select.Option>
                        }
                    })
                    setOptions(artOptions)
                }
            })
            axios.get('/stage/').then((res) => {
                setSta(res.data.stages)
                setFilteredSta(res.data.stages)
            })
        })()
    }, []);

    useEffect(() => {
        socket.on('stage', (stages) => {
            setSta(stages)
            setFilteredSta(stages)
            setLoading(false)
        })
        socket.on('customerSelect', (customers) => {
            setCust(customers)
            handleCustChange(customers)
        })
        socket.on('article', (articles) => {
            const temp = articles.filter((item) => {
                return String(item?.cust_proj).toLowerCase() === String(document.getElementById("floatingSelect2").value).toLocaleLowerCase()
            })
            setArticles(temp)
            let artOptions = temp.map((art) => {
                if (art.enable) {
                    return <Select.Option key={art['art_no']}>{art['art_no']}</Select.Option>
                }
            })
            setOptions(artOptions)
        })
    }, [socket])

    const handleCustChange = async (currCust) => {
        let find = {}
        let { value } = document.getElementById('floatingSelect2')
        find["cust_proj"] = currCust?.length > 0 ? currCust?.[0] : value
        await axios.post('/article/custfind', find).then((res) => {
            if (res.status === 200) {
                setArticles(res.data.articles)
                let artOptions = res.data.articles.map((art) => {
                    if (art.enable) {
                        return <Select.Option key={art['art_no']}>{art['art_no']}</Select.Option>
                    }
                })
                setOptions(artOptions)
            }
        })
    }

    useEffect(() => {
        let res = sta.filter((item) => {
            return String(item.art_no).toLowerCase().match(search.toLocaleLowerCase());
        })
        setFilteredSta(res)
    }, [search])


    return (
        <div className='w-100 mainHeight' >
            <Masternav name='stages' />
            <div className='p-1'>
                <div className="d-flex justify-content-between py-3 px-0 w-100">
                    <div className="form-floating filtercust">
                        <select className="form-select" id='floatingSelect2' name='cust_proj' onChange={(e) => handleCustChange(e)}>
                            {cust?.map((option, index) => {
                                return <option key={index} value={option} >{option}</option>
                            })}
                        </select>
                        <label htmlFor="floatingSelect2">Select Customer</label>
                    </div>
                    <div className="form-floating searchBox input-group-sm select_box">
                        <input
                            className="form-control"
                            placeholder="Search Article Num"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <label htmlFor="floatingTextarea">Search Article Num</label>
                    </div>
                </div>
            </div>
            <div className='main_body p-1 w-100 gap-2' >
                <div className='stage_body card p-3'>
                    <div hidden={hidden}>
                        {/* <div className="form-floating filtercust w-100">
                            <select className="form-select" id='floatingSelect2' name='cust_proj' onChange={(e) => handleCustChange(e)}>
                                {cust?.map((option, index) => {
                                    return <option key={index} value={option} >{option}</option>
                                })}
                            </select>
                            <label htmlFor="floatingSelect2">Select Customer</label>
                        </div> */}

                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Select Article Numbers.."
                            onChange={handleChange}
                        >
                            {options}
                        </Select>
                        <br />
                        <div>
                            {stageNames.map((stageName, index) => {
                                let alpha = String.fromCharCode(65 + index)
                                return (
                                    <div className="form-group d-flex justify-content-center my-3" key={index}>
                                        <label className="control-label m-2" htmlFor="pwd">{`Stage ${alpha}`}:</label>
                                        <div className="col-sm-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Stage Name"
                                                name="stageName"
                                                value={stageName.stageName}
                                                onChange={(e) => handleStageNameInput(index, e)}
                                            />
                                        </div>

                                        {stageNames.length > 1 ? (
                                            <Button className="" disabled={isLoading} type="primary" onClick={() => removeStageName(index)}>
                                                x
                                            </Button>
                                        ) : null}
                                    </div>
                                );
                            })}
                            <div className='d-flex justify-content-center gap-2'>
                                {stageNames.length < 10 ?
                                    <Button className="" disabled={isLoading} type="primary" onClick={() => addStageName()}>
                                        ADD
                                    </Button>
                                    : null}
                                <Button className="" disabled={isLoading} type="primary" onClick={(e) => { setLoading(true); handleSubmit(e); }} >
                                    SUBMIT
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className='table_body card'>
                    <DataTable
                        columns={columns}
                        data={filteredSta}
                        pagination
                        fixedHeader
                        paginationDefaultPage={1}
                        fixedHeaderScrollHeight="50vh"
                        highlightOnHover
                        noDataComponent={data ? <div className='p-3'><Spin /></div> : <div className='p-3'>No Data</div>}
                    />
                </div>
            </div>
        </div>
    )
}
