import './App.scss';
import { Footer } from './Components/Common/Footer';
import { Header } from './Components/Common/Header';
import { Router } from './Router';

function App() {

  return (
    <div className="App">
      <Header />
      <Router />
      <Footer />
    </div>
  );
}

export default App;