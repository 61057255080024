import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Masternav } from '../../helper/MasterNav';
import moment from 'moment';
import axios from '../../../../../api/axios';
import { FloatingLabel, Form } from 'react-bootstrap';
import { formValidator } from '../../../../../../src/services/formValidator';
import { Spin, Button, message } from 'antd';
import { socket } from '../../../../../services/socket';
import { Backdrop, CircularProgress } from '@mui/material';
import { useGetUserDetails } from '../../../../../hooks/useGetUserDetails';
message.config({ top: '40%' })

export const Article = ({ hidden }) => {
    const { user } = useGetUserDetails()
    const [artForm, setArtForm] = useState({
        art_no: '',
        art_desc: '',
        uom: '',
        cust_proj: '',
        catName: '',
        catSubType: ''
    });
    const [art, setArt] = useState([]);
    const [filteredart, setFilteredArt] = useState([]);
    const [search, setSearch] = useState('');
    const [uom, setUom] = useState([]);
    const [cust, setCust] = useState([])
    const [currCust, setCurrCust] = useState("")
    const [categories, setCategories] = useState([])
    const [formValidationError, setFormValidationError] = useState({});
    const [data, setData] = useState(true);
    const [loading, setLoading] = useState({
        uploading: false,
        enabling: false,
        disabled: false
    })
    // console.log(artForm)
    const handleInput = (e) => {
        setArtForm({ ...artForm, [e.target.name]: e.target.value });
    };

    const handleEdit = (row) => {
        console.log(row)
        setArtForm(row)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formError = formValidator(artForm, {
            art_no: 'Article Num Required',
            art_desc: 'Description Required',
            uom: 'UOM Required',
            cust_proj: 'Customer Required',
        });
        setFormValidationError(formError);
        if (Object.keys(formError).length === 0) {
            setLoading({ ...loading, uploading: true })
            await axios
                .post('/article/add', artForm)
                .then((res) => {
                    console.log(res.data)
                    if (res.status === 200) {
                        message.success("Added Successfully")
                        setArtForm({
                            art_no: '', art_desc: '', uom: '', cust_proj: '', catName: '', catSubType: ''
                        });
                    } else {
                        message.error("Data not saved!")
                        setLoading({
                            uploading: false,
                            enabling: false
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                    message.error("Data not saved!")
                    setLoading({
                        uploading: false,
                        enabling: false
                    })
                });
        }
        setLoading({ ...loading, disabled: false })
    };

    const handleEnable = async (e, _id) => {
        setLoading({ ...loading, enabling: true })
        await axios
            .post('/article/enable', { _id, enable: e.target.checked })
            .then((res) => {
                if (res.status === 200) {
                    return message.success("Toggled")
                }
                return message.error("Error")
            })
            .catch((e) => {
                console.log(e);
                return message.error("Error")
            });
    };

    const handleCancel = () => {
        setArtForm({ art_no: '', art_desc: '', uom: '', cust_proj: '', catName: '', catSubType: '' });
        setFormValidationError({});
        setLoading({ ...loading, disabled: false })
    };

    const disp = async () => {
        // setLoading({ ...loading, uploading: true })
        let find = {}
        await axios.get('/customer/select').then((res) => {
            setCust(res.data.custs)
            find["cust_proj"] = res.data.custs[0]
            setCurrCust(res.data.custs[0])
        })
        await axios.get('/uom/select').then((res) => {
            setUom(res.data.uoms)
        })
        await axios.post('/article/custfind', find).then((res) => {
            setArt(res.data.articles);
            setFilteredArt(res.data.articles);
            setLoading({ ...loading, uploading: false })
            if (!res.data.articles?.length > 0) {
                setData(false)
            }
        });
        await axios.get('/category/').then((res) => {
            if (res.status === 200) {
                setCategories(res.data.categories)
            }
        }).catch((e) => {
            console.log(e)
        })
    };

    const handleCustChange = async (newCust) => {
        let find = {}
        let { value } = document.getElementById('floatingSelect2')
        find["cust_proj"] = newCust?.length > 0 ? newCust?.[0] : value
        await axios.post('/article/custfind', find).then((res) => {
            setArt(res.data.articles);
            setFilteredArt(res.data.articles);
            if (!res.data.articles?.length > 0) {
                setData(false)
            }
        });
    }

    const handleCatChange = (e) => {
        let { value } = document.getElementById('floatingSelect3')
        if (value !== 'all') {
            let data = art?.filter((item) => {
                return String(item.catName).toLowerCase() === String(value).toLocaleLowerCase()
            })
            setFilteredArt(data);
            if (!data?.length > 0) {
                setData(false)
            }
        } else {
            setFilteredArt(art);
        }
    }

    const handleCatSubTypeChange = (e) => {
        let { value } = document.getElementById('floatingSelect4')
        if (value !== 'all') {
            let find = {}
            find['catName'] = document.getElementById('floatingSelect3')?.value
            let data = art?.filter((item1) => {
                return String(item1.catName).toLowerCase() === String(find.catName).toLocaleLowerCase()
            }).filter((item2) => {
                return String(item2.catSubType).toLowerCase() === String(value).toLocaleLowerCase()
            })
            setFilteredArt(data);
            if (!data?.length > 0) {
                setData(false)
            }
        } else {
            handleCatChange()
        }
    }

    const columns = [
        {
            name: 'Article Num',
            selector: (row) => row.art_no,
            sortable: true,
        },
        {
            name: 'Description',
            cell: (row) => row.art_desc,
            hide: 'md',
            center: true,
        },
        {
            name: 'Uom',
            cell: (row) => row.uom,
            hide: 'md',
            center: true,
        },
        {
            name: 'Customer/Project',
            cell: row => row.cust_proj,
            hide: 'md',
            center: true,
        },
        {
            name: 'Product Group',
            cell: row => row.catName,
            hide: 'md',
            center: true,
        },
        {
            name: 'Type',
            cell: row => row.catSubType,
            hide: 'md',
            center: true,
        },
        {
            name: 'enable',
            cell: (row) => (
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={row.enable}
                        onChange={(e) => handleEnable(e, row._id)}
                        disabled={loading.enabling || loading.disabled}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            ),
            width: '100px',
            omit: hidden,
        },
        {
            name: 'Date',
            cell: (row) => (
                <div>{moment(row.date).utc().format('DD-MM-YYYY')}</div>
            ),
            width: '110px',
            right: true,
            omit: user?.access?.Date > 0 ? false : true,
        },
        {
            name: 'Edit',
            cell: (row) => (
                <Button disabled={loading.disabled} type='primary' onClick={() => { setLoading({ ...loading, disabled: true }); handleEdit(row) }}>Edit</Button>
            ),
            width: '110px',
            right: true,
        },
    ];

    useEffect(() => {
        disp();
    }, []);

    useEffect(() => {
        socket.on('article', (articles) => {
            setArt(articles)
            const temp = articles.filter((item) => {
                return String(item?.cust_proj).toLowerCase() === String(document.getElementById("floatingSelect2")?.value).toLocaleLowerCase()
            })
            setFilteredArt(temp)
            setLoading({
                uploading: false,
                enabling: false
            })
        })
        socket.on('customerSelect', (customers) => {
            setFilteredArt([])
            setCust(customers)
            handleCustChange(customers)
        })
        socket.on('uomSelect', (Uoms) => {
            setUom(Uoms)
        })
        socket.on('category', (cat) => {
            setCategories(cat)
        })
    }, [socket])

    useEffect(() => {
        const temp = art.filter((item) => {
            return String(item["cust_proj"]).toLowerCase().match(String(currCust).toLocaleLowerCase())
        })
        const res = temp.filter((item) => {
            // console.log(item.art_no)
            return item.art_no.toLowerCase().match(search.toLocaleLowerCase());
        });
        setFilteredArt(res);
    }, [search]);

    return (
        <div className="mainHeight">
            <Masternav name="article" />
            <div className="p-1">
                {/* <input className='form-control' placeholder='Search Article Num' value={search} onChange={(e) => setSearch(e.target.value)} /> */}
                <div className="d-flex justify-content-between py-3 px-0 w-100">
                    <div className="form-floating filtercust">
                        <select className="form-select" id='floatingSelect2' name='cust_proj' onChange={(e) => handleCustChange(e)}>
                            {cust?.map((option, index) => {
                                return <option key={index} value={option} >{option}</option>
                            })}
                        </select>
                        <label htmlFor="floatingSelect2">Select Customer</label>
                    </div>
                    <div className="form-floating filtercust">
                        <select className="form-select" id='floatingSelect3' name='catName' defaultValue='all' onChange={(e) => handleCatChange(e)}>
                            {/* <select className="form-select" id='floatingSelect3' name='catName' defaultValue='all'> */}
                            <option value='all'>All</option>
                            {
                                [...new Set(categories?.reduce((arr, item) => {
                                    if (item?.enable) {
                                        arr[0].push(item.catName)
                                    } else {
                                        arr[1].push(item.catName)
                                    }
                                    return arr
                                }, [[], []])?.[0])]?.map((option, index) => {
                                    return <option key={index} value={option}>{option}</option>
                                })
                            }
                        </select>
                        <label htmlFor="floatingSelect3">Select Product Group</label>
                    </div>
                    <div className="form-floating filtercust">
                        <select className="form-select" id='floatingSelect4' name='catSubType' defaultValue='all' onChange={(e) => handleCatSubTypeChange(e)}>
                            {/* <select className="form-select" id='floatingSelect4' name='catSubType' defaultValue='all'> */}
                            <option value='all'>All</option>
                            {
                                categories?.filter((item1) => {
                                    if (item1?.enable) {
                                        return String(item1["catName"]).toLowerCase() === (String(document.getElementById("floatingSelect3").value).toLocaleLowerCase())
                                    } else {
                                        return false
                                    }
                                }).map((item2) => {
                                    if (item2?.enable) {
                                        return item2.catType
                                    }
                                })?.map((option, index) => {
                                    return <option key={index} value={option}>{option}</option>
                                })
                            }
                        </select>
                        <label htmlFor="floatingSelect4">Select Type</label>
                    </div>
                    <div className="form-floating searchBox input-group-sm select_box">
                        <input
                            className="form-control"
                            placeholder="Search Article Num"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <label htmlFor="floatingTextarea">Search Article Num</label>
                    </div>
                </div>
                <div className='card'>
                    <DataTable
                        columns={columns}
                        data={filteredart}
                        pagination
                        fixedHeader
                        paginationDefaultPage={1}
                        fixedHeaderScrollHeight="410px"
                        highlightOnHover
                        // actions={actionsMemo}
                        // actions
                        noDataComponent={data ? <div className='p-3'><Spin /></div> : <div className='p-3'>No Data</div>}
                    />
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading.enabling || loading.uploading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <hr />
                <div className='card'>
                    <section className="masterbody" hidden={hidden}>
                        <FloatingLabel label="Article Num">
                            <Form.Control
                                disabled={loading.disabled}
                                name="art_no"
                                type="text"
                                value={artForm.art_no}
                                onChange={handleInput}
                                placeholder="none"
                                autoComplete="off"
                            />
                            <p className="text-danger">
                                {formValidationError['art_no']}
                            </p>
                        </FloatingLabel>
                        <FloatingLabel label="Description">
                            <Form.Control
                                name="art_desc"
                                type="text"
                                value={artForm.art_desc}
                                onChange={handleInput}
                                placeholder="none"
                                autoComplete="off"
                            />
                            <p className="text-danger">
                                {formValidationError['art_desc']}
                            </p>
                        </FloatingLabel>
                        <form className="form-floating input-group-sm select_box">
                            <input className="form-control" id="floatingTextarea" list="datalistOptions1" value={artForm.uom} placeholder=" " name='uom' onChange={handleInput} autoComplete="off" />
                            <datalist id="datalistOptions1" >
                                {uom?.map((option, index) => {
                                    return <option key={index} value={option} />
                                })}
                            </datalist>
                            <label htmlFor="floatingTextarea">Select Uom</label>
                            <p className="text-danger">
                                {formValidationError['uom']}
                            </p>
                        </form>
                        <form className="form-floating input-group-sm select_box" >
                            <input disabled={loading.disabled} className="form-control" id="floatingTextarea" list="datalistOptions2" placeholder=" " value={artForm.cust_proj} name='cust_proj' onChange={handleInput} autoComplete="off" />
                            <datalist id="datalistOptions2" >
                                {cust?.map((option, index) => {
                                    return <option key={index} value={option} />
                                })}
                            </datalist>
                            <label htmlFor="floatingTextarea">Select Customer</label>
                            <p className="text-danger">
                                {formValidationError['cust_proj']}
                            </p>
                        </form>
                        <form className="form-floating input-group-sm select_box" >
                            <input className="form-control" id="floatingTextarea3" list="datalistOptions3" placeholder=" " value={artForm.catName} name='catName' onChange={handleInput} autoComplete="off" />
                            <datalist id="datalistOptions3" >
                                {
                                    [...new Set(categories?.reduce((arr, item) => {
                                        if (item?.enable) {
                                            arr[0].push(item.catName)
                                        } else {
                                            arr[1].push(item.catName)
                                        }
                                        return arr
                                    }, [[], []])?.[0])]?.map((option, index) => {
                                        return <option key={index} value={option} />
                                    })
                                }
                            </datalist>
                            <label htmlFor="floatingTextarea">Select Product Group</label>
                        </form>
                        <form className="form-floating input-group-sm select_box" >
                            <input className="form-control" id="floatingTextarea" list="datalistOptions4" placeholder=" " value={artForm.catSubType} name='catSubType' onChange={handleInput} autoComplete="off" />
                            <datalist id="datalistOptions4" >
                                {
                                    categories?.filter((item1) => {
                                        if (item1?.enable) {
                                            return String(item1["catName"]).toLowerCase() === (String(document.getElementById("floatingTextarea3").value).toLocaleLowerCase())
                                        } else {
                                            return false
                                        }
                                    }).map((item2) => {
                                        return item2.catType
                                    })?.map((option, index) => {
                                        return <option key={index} value={option} />
                                    })
                                }
                            </datalist>
                            <label htmlFor="floatingTextarea">Select Type</label>
                        </form>
                    </section>
                    <section className="masterbtn" hidden={hidden}>
                        <div>
                            <Button type="primary" onClick={handleSubmit} loading={loading.uploading}>
                                {loading.uploading ? 'Adding' : 'Add'}
                            </Button>
                            <Button type="primary" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};
