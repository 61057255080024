import React, { useEffect, useRef, useState } from 'react'
import { PlotsNav } from '../helper/PlotsNav'
import { Button, DatePicker, message } from 'antd'
import Chart from "chart.js/auto";
import { Bar, Line, Radar } from 'react-chartjs-2';
import axios from '../../../../api/axios';
import DataTable from 'react-data-table-component';

const { RangePicker } = DatePicker;
const charts = {
    line: Line,
    bar: Bar,
    radar: Radar
}

export const WoShortRejPlot = () => {
    const [ChartData, setChartData] = useState(Line)
    const [chartName, setChartName] = useState('line')
    const chartRef = useRef(null)
    const d = new Date()
    let yearMonth = {
        years: [],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    }
    for (let i = d.getFullYear(); i >= 2018; i--) {
        yearMonth.years.push(`${i}`)
    }
    const [isLoading, setIsLoading] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [plotForm, setPlotForm] = useState({ 'article#': "", 'wo#': "", 'ShortRejType': 'all', 'yearVal': `${d.getFullYear()}`, 'monthVal': `${d.getMonth() + 1}` })
    const changeChart = (e) => {
        setChartName(e.target.value)
    }
    const [dataOptions, setDataOptions] = useState({})
    const [shortRejData, setShortRejData] = useState({
        actual: [],
        filtered: [],
    })
    const [search, setSearch] = useState("")
    const getPlots = async () => {
        setIsLoading(true)
        await axios.post('/plot/short', plotForm).then((res) => {
            console.log(res.data[0])
            let temp = Object.entries(res.data[0])?.map((item) => {
                return {
                    'article#': item?.[0],
                    'wo#': item?.[1]?.[2]?.['woNum'],
                }
            })
            console.log(temp)
            setShortRejData({
                actual: temp,
                filtered: temp,
            })
            if ((Object.keys(res.data[0])).length > 0) {
                setVariableData({
                    mainLabel1: Object.keys(res.data[0]),
                    dataSetLabel1: 'Shortage',
                    datSetData1: Object.keys(res.data[0]).map((key) => res.data[0][key][0]),
                    dataSetLabel2: 'Rejection',
                    datSetData2: Object.keys(res.data[0]).map((key) => res.data[0][key][1]),
                })
            } else {
                message.warning("Data not found")
                setVariableData({
                    mainLabel1: [],
                    dataSetLabel1: 'Shortage',
                    datSetData1: [],
                    dataSetLabel2: 'Rejection',
                    datSetData2: [],
                })
            }
        }).catch((e) => {
            console.log(e)
        })
        if (chartName !== 'radar') {
            setDataOptions({
                scales: {
                    yAxis: {
                        stacked: true,
                        title: {
                            display: true,
                            text: 'Quantity in numbers',
                            font: {
                                size: 16,
                            },
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                    xAxis: {
                        stacked: true,
                        title: {
                            display: true,
                            text: 'Article#',
                            font: {
                                size: 16,
                            },
                        },
                    }
                },
                plugins: {
                    legend: {
                        labels: {
                            font: {
                                size: 16,
                            },
                        },
                    },
                    title: {
                        display: true,
                        text: `Shortage/Rejection Plot`,
                        font: {
                            size: 25,
                        },
                    },
                    tooltip: {
                        callbacks: {
                            footer: footer,
                            title: title,
                        }
                    }
                },
                responsive: true,
            })
        } else {
            setDataOptions({
                plugins: {
                    legend: {
                        labels: {
                            font: {
                                size: 16,
                            },
                        },
                    },
                    title: {
                        display: true,
                        text: `Shortage/Rejection Plot`,
                        font: {
                            size: 25,
                        },
                    },
                },
                responsive: true,
            })
        }
        setChartData(charts[chartName])
        setIsLoading(false)
    }

    const [variableData, setVariableData] = useState({
        mainLabel1: [],
        dataSetLabel1: 'WO Quantity',
        datSetData1: [],
        dataSetLabel2: 'Shortage',
        datSetData2: [],
        dataSetLabel3: 'Rejection',
        datSetData3: [],
    })

    let aaa = {}
    if (variableData) {
        aaa = {
            labels: variableData.mainLabel1,
            datasets: [
                {
                    label: variableData.dataSetLabel1,
                    data: variableData.datSetData1,
                    backgroundColor: [
                        'rgba(255,165,0,0.5)',
                    ],
                    borderColor: [
                        'rgba(255,69,0,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
                {
                    label: variableData.dataSetLabel2,
                    data: variableData.datSetData2,
                    backgroundColor: [
                        'rgba(242, 38, 19,0.5)',
                    ],
                    borderColor: [
                        'rgba(242, 38, 19,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
            ]
        }
    }

    const footer = (tooltipItems) => {
        let sum = 0;
        tooltipItems.forEach(function (tooltipItem) {
            sum = tooltipItem.parsed._stacks.y[0] + tooltipItem.parsed._stacks.y[1]
        });
        return ('Total: ' + sum);
    };

    const title = (tooltipItems) => {
        console.log(tooltipItems)
        let temp = shortRejData?.actual?.filter((item) => {
            return item?.['article#'] === tooltipItems?.[0]?.label
        })
        let woTemp = ''
        temp?.forEach((item) => {
            woTemp = ''
            item?.['wo#']?.forEach((wo, i) => {
                woTemp = i === item?.['wo#']?.length - 1 ? woTemp + `${wo}.` : woTemp + `${wo}, `
            })
        })
        // return (`Article#: ${tooltipItems?.[0]?.label}, WO#: ${woTemp}`)
        return ([`Article#: ${tooltipItems?.[0]?.label}`, `WO#: ${woTemp}`])
    }

    const label = (tooltipItems) => {
        const data = tooltipItems.chart.data;
        const datasetIndex = tooltipItems.datasetIndex;
        const index = tooltipItems.dataIndex;
        const datasetLabel = data.datasets[datasetIndex].label || "";
        // You can use two type values.
        // `data.originalData` is raw values,
        // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)
        const originalValue = data.originalData[datasetIndex][index];
        const rateValue = data.calculatedData[datasetIndex][index];
        return `${datasetLabel}: ${originalValue} (${rateValue}%)`;
    }

    const [userData, setUserData] = useState(aaa)

    const handleInput = (e) => {
        let { name, value } = e.target
        setPlotForm({ ...plotForm, [name]: value })
    }

    const handleDate = (date, dateString) => {
        var fromDate = dateString[0]
        var toDate = dateString[1]
        setPlotForm({ ...plotForm, ["fromDate"]: fromDate, ["toDate"]: toDate })
    }

    const handleDownload = () => {
        setIsDownloading(true)
        const data = chartRef.current.toBase64Image()
        let filename = "";
        const keys = Object.keys(plotForm)
        var time = d.getHours() + "-" + d.getMinutes() + "-" + d.getSeconds();

        if (keys.length > 0) {
            keys.forEach((key, i) => {
                if (i !== keys.length - 1) {
                    filename += `${plotForm[key]}_`
                } else {
                    filename += `${plotForm[key]}`
                    filename += `_${time}_${d.toDateString()}_Shortage/Rejection Plot`
                }
            })
        }
        filename += `${time}_${d.toDateString()}_Shortage/Rejection Plot`

        filename = filename + '.png'
        const link = document.createElement('a');
        link.download = filename;
        link.href = chartRef.current.toBase64Image();
        link.click();
        setPlotForm({ "wo#": "", "article#": "" })
        setPlotForm({})
        setIsDownloading(false)
    }

    const columns = [
        {
            name: 'Article#',
            selector: (row, i) => row?.['article#'],
        },
        {
            name: 'WO#(s)',
            selector: (row, i) => row?.['wo#']?.map((item, i) => {
                return i !== row?.['wo#']?.length - 1 ? `${item}, ` : `${item}.`
            }),
        },
    ]

    useEffect(() => {
        setUserData(aaa)
    }, [variableData, ChartData])

    console.log(plotForm)

    return (
        <div className='mainHeight'>
            <div>
                <PlotsNav name='WO shortage/rejection' />
            </div>
            <div className="m-auto p-3">
                <div className='woInProgressInputs'>
                    <div className="form-floating" style={{ width: '100%' }} >
                        <select className="form-select" id='floatingSelect3' defaultValue="line" name="chartType" onChange={(e) => changeChart(e)}>
                            <option value="line">Line</option>
                            <option value="bar">Bar</option>
                            <option value="radar">Radar</option>
                        </select>
                        <label htmlFor="floatingSelect3">Select chart type</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }} >
                        <select className="form-select" id='floatingSelect4' defaultValue="all" name="ShortRejType" onChange={(e) => { handleInput(e) }}>
                            <option value="all">All</option>
                            <option value="shortage">Shortage</option>
                            <option value="rejection">Rejection</option>
                        </select>
                        <label htmlFor="floatingSelect3">Select Shortage / Rejection</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }} >
                        <select className="form-select" id='floatingSelect5' defaultValue={`${d.getFullYear()}`} name="yearVal" onChange={(e) => { handleInput(e) }}>
                            {
                                yearMonth.years.map((year) => {
                                    return (
                                        <option key={year} value={year}>{year}</option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="floatingSelect3">Select Year</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }} >
                        <select className="form-select" id='floatingSelect5' defaultValue={`${d.getMonth() + 1}`} name="monthVal" onChange={(e) => { handleInput(e) }}>
                            {
                                yearMonth.months.map((month, i) => {
                                    return (
                                        <option key={i + 1} value={i + 1}>{month}</option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="floatingSelect3">Select Month</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }} >
                        <input type="text" name='wo#' className="form-control" id="floatingInput1" value={plotForm["wo#"]} onChange={(e) => handleInput(e)} placeholder=" " autoComplete='off' disabled={!plotForm['article#'] ? false : true} />
                        <label htmlFor="floatingInput1">Enter Wo#</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }} >
                        <input type="text" name='article#' className="form-control" id="floatingInput2" value={plotForm["article#"]} onChange={(e) => handleInput(e)} placeholder=" " autoComplete='off' disabled={!plotForm['wo#'] ? false : true} />
                        <label htmlFor="floatingInput2">Enter Article#</label>
                    </div>
                    {/* <div className='w-100 d-grid'>
                        <RangePicker
                            format={'DD/MM/YYYY'}
                            onChange={(date, dateString) => handleDate(date, dateString)}
                            disabled={!plotForm['wo#'] ? false : true}
                            placement="bottomRight"
                            inputReadOnly={true}
                        />
                    </div> */}
                </div>
                <div className='d-flex justify-content-center gap-3 pt-3'>
                    <Button type='primary' onClick={() => getPlots()} loading={isLoading}>Plot</Button>
                    <Button type='primary' onClick={() => handleDownload()} loading={isDownloading}>Download Chart</Button>
                </div>
                {/* {JSON.stringify(shortRejData)} */}
                <div className='m-auto p-1 d-flex flex-column align-items-center chartWidth' >
                    <ChartData id='myChart' data={userData} options={dataOptions} ref={chartRef} />
                </div>
                <hr />
                <div className="form-floating pb-3" style={{ width: '50%' }} >
                    <input type="text" name='search' className="form-control" id="floatingInput1" value={search} onChange={(e) => {
                        setSearch(e.target.value);
                        let temp = shortRejData?.actual.filter((item) => {
                            return String(item?.['article#']).toLowerCase().match(String(e.target.value).toLocaleLowerCase())
                        });
                        setShortRejData({ ...shortRejData, filtered: temp })
                    }} placeholder=" " autoComplete='off' />
                    <label htmlFor="floatingInput1">Search Article#</label>
                </div>
                <DataTable
                    // title="List of shortage/rejection Article# with respect to the WO# they belong to"
                    columns={columns}
                    data={shortRejData?.filtered}
                    fixedHeader
                    pagination
                />
            </div>
        </div >
    )
}

