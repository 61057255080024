import React, { useEffect, useState } from 'react';
import { Button, message, Spin, Tooltip } from 'antd';
import { FloatingLabel, Form } from 'react-bootstrap';
import { ReportsNav } from '../helper/ReportsNav';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import axios from '../../../../api/axios';
import { writeXLSX, utils, writeFileXLSX } from "xlsx";
import { useGetUserDetails } from '../../../../hooks/useGetUserDetails';
import { socket } from '../../../../services/socket';

export const ProcessReports = () => {
    const { user } = useGetUserDetails()
    const [filteredwos, setFilteredWos] = useState([]);
    const [exportList, setExportList] = useState([])
    const [wos, setWos] = useState([]);
    const [wosAll, setWosAll] = useState(wos);
    const [proForm, setProForm] = useState({})
    const [cust, setCust] = useState([])
    const [artOptions, setArtOptions] = useState([])
    const [search, setSearch] = useState("")
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState({
        isLoading: false,
        isDisabled: true,
        isDownloading: false,
        data: false,
    })

    const handleInput = (e) => {
        setProForm({ ...proForm, [e.target.name]: e.target.value })
        if (e.target.name === "article#" || e.target.name === "fromDate" || e.target.name === "toDate" && e.target.value !== "") {
            setLoading({ ...loading, isDisabled: false })
        }
        if (e.target.name === "article#" && e.target.value === "" && !document.getElementById('fromDate')?.value && !document.getElementById('toDate')?.value) {
            setLoading({ ...loading, isDisabled: true })
        }
    }

    const handleGet = (proForm) => {
        let { value } = document.getElementById('floatingSelect2')
        if (proForm['fromDate'] || proForm['toDate']) {
            if (proForm['toDate'] && proForm['fromDate']) {
                disp(proForm, value)
            } else {
                message.warning("Enter from date and to date")
            }
        } else {
            disp(proForm, value)
        }
    };

    const handleClear = async () => {
        setProForm({})
        setSearch('')
        setLoading({ ...loading, isDisabled: true, isLoading: true, data: true })
        handleCustChange()
        document.getElementById('searchForm').reset()
    };

    const disp = async (proForm, value) => {
        setLoading({ ...loading, isLoading: true, data: true })
        let find = { ...proForm }
        await axios.get('/customer/select').then((res) => {
            setCust(res.data.custs)
            if (value) {
                find["cust_proj"] = value
            } else {
                find["cust_proj"] = res.data.custs[0]
            }
        })
        await axios.post('/wo/report', find).then((res) => {
            setWos([])
            setFilteredWos([])
            setWosAll([])
            // console.log(res.status)
            if (res.status === 200) {
                setWosAll(res.data.wos)
                setFilteredWos(res.data.wos)
            }
            if (!res.data.wos?.length > 0) {
                setLoading({ ...loading, data: false })
            }
        })
        setLoading({ ...loading, isLoading: false })
    }

    useEffect(() => {
        socket.on('customerSelect', async (customers) => {
            setCust(customers)
            document.getElementById('floatingSelect2').value = customers?.[0]
            handleCustChange(customers)
        })
        socket.on('woArtList', (artList) => {
            setArtOptions(artList)
        })
        socket.on('wo', (wos) => {
            let temp = wos?.filter((item) => {
                return item?.["cust_proj"] === document?.getElementById('floatingSelect2')?.value
            })
            setWosAll(temp)
        })
        socket.on('category', (cat) => {
            setCategories(cat)
        })
    }, [socket])

    const handleCustChange = async (currCust) => {
        setProForm({})
        setWos([])
        setFilteredWos([])
        setWosAll([])
        document.getElementById('searchForm')?.reset()
        let { value } = document.getElementById('floatingSelect2')
        setLoading({ ...loading, isLoading: true, data: true })
        let find = {}
        find["cust_proj"] = currCust?.length > 0 ? currCust[0] : value
        await axios.post('/wo/report', find).then((res) => {
            if (res.status === 200) {
                setWosAll(res.data.wos)
                setFilteredWos(res.data.wos)
            }
            if (!res.data.wos?.length > 0) {
                setLoading({ ...loading, data: false })
            }
        })
        setLoading({ ...loading, isDisabled: true, isLoading: false })
    }

    const handleCatChange = async (e) => {
        let { value } = document.getElementById('floatingSelect3')
        if (value !== 'all') {
            let filteredWoDataTemp = [...wos]
            let data = filteredWoDataTemp.filter((item) => {
                return String(item.catName).toLowerCase() === String(value).toLocaleLowerCase()
            })
            setFilteredWos(data)
            if (!data?.length > 0) {
                setLoading({ ...loading, data: false })
            }
        } else {
            setFilteredWos(wos)
        }
    }

    const handleCatSubTypeChange = async (e) => {
        let { value } = document.getElementById('floatingSelect4')
        if (value !== 'all') {
            let filteredWoDataTemp = [...wos]
            let find = {}
            find["cust_proj"] = document.getElementById('floatingSelect2')?.value
            find["catName"] = document.getElementById('floatingSelect3')?.value
            let data = filteredWoDataTemp.filter((item) => {
                return String(item.catName).toLowerCase() === String(find.catName).toLocaleLowerCase()
            }).filter((item) => {
                return String(item.catSubType).toLowerCase() === String(value).toLocaleLowerCase()
            })
            setFilteredWos(data)
            if (!data?.length > 0) {
                setLoading({ ...loading, data: false })
            }
        } else {
            handleCatChange()
        }
    }

    const convertArrayOfObjectsToXLSX = (array) => {
        let excelResult = []
        let row = { 'ppoItem': '', 'wo#': '', 'article#': '', 'woDate': '', 'wo_qty': '', 'Rej article#': '', 'Rej Qty': '', 'Rej Desc': '', 'Short article#': '', 'Short Qty': '', 'Short Desc': '' }
        const keys = ['ppoItem', 'wo', 'article', 'woDate', 'wo_qty']
        const keys2 = ['Rej art', 'Rej Qty', 'Rej Desc']
        const keys3 = ['Short art', 'Short Qty', 'Short Desc']

        array.forEach(item => {
            item.stages.map((item2) => {
                item2.data.map((item3) => {
                    item3.rejection?.map(ele => {
                        keys.forEach(key => {
                            if (key === 'wo') {
                                row['wo#'] = item['wo#']
                            } else if (key === 'article') {
                                row['article#'] = item['article#']
                            } else if (key === 'woDate') {
                                row[key] = moment(item[key]).utc().format("DD-MM-YYYY")
                            } else {
                                row[key] = item[key]
                            }
                        })
                        keys2.forEach(key => {
                            if (key === 'Rej art') {
                                row['Rej article#'] = ele['article#']
                            } else if (key === 'Rej Qty') {
                                row['Rej Qty'] = ele['qty']
                            } else if (key === 'Rej Desc') {
                                row['Rej Desc'] = ele['desc']
                            }
                        });
                        excelResult.push(row)
                    })
                    row = { 'ppoItem': '', 'wo#': '', 'article#': '', 'woDate': '', 'wo_qty': '', 'Rej article#': '', 'Rej Qty': '', 'Rej Desc': '', 'Short article#': '', 'Short Qty': '', 'Short Desc': '' }
                    item3.shortage?.map(ele => {
                        keys.forEach(key => {
                            if (key === 'wo') {
                                row['wo#'] = item['wo#']
                            } else if (key === 'article') {
                                row['article#'] = item['article#']
                            } else if (key === 'woDate') {
                                row[key] = moment(item[key]).utc().format("DD-MM-YYYY")
                            } else {
                                row[key] = item[key]
                            }
                        })
                        keys3.forEach(key => {
                            if (key === 'Short art') {
                                row['Short article#'] = ele['article#']
                            } else if (key === 'Short Qty') {
                                row['Short Qty'] = ele['qty']
                            } else if (key === 'Short Desc') {
                                row['Short Desc'] = ele['desc']
                            }
                        });
                        excelResult.push(row)
                        row = { 'ppoItem': '', 'wo#': '', 'article#': '', 'woDate': '', 'wo_qty': '', 'Rej article#': '', 'Rej Qty': '', 'Rej Desc': '', 'Short article#': '', 'Short Qty': '', 'Short Desc': '' }
                    })
                })
            })
        });
        return excelResult;
    }

    const downloadXLSX = () => {
        setLoading({ ...loading, isDownloading: true })
        let data = convertArrayOfObjectsToXLSX(exportList)
        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        let filename = "";
        const keys = Object.keys(proForm)
        if (keys.length !== 0) {
            keys.forEach((key, i) => {
                if (i !== keys.length - 1) {
                    filename += `${proForm[key]}_`
                } else {
                    filename += `${proForm[key]}`
                    filename += `_Shortage/Rejection List`
                }
            })
        }
        else {
            filename += `Shortage/Rejection List`
        }
        filename = filename + '.xlsx'
        writeFileXLSX(wb, filename);
        setLoading({ ...loading, isDownloading: false })
    }

    const columns = [
        {
            name: 'ppo_item',
            cell: (row) => row.ppoItem,
        },
        {
            name: 'WO#',
            cell: (row) => row['wo#'],
            hide: 'md',
        },
        {
            name: 'Art#',
            cell: (row) => row['article#'],
        },
        {
            name: 'Product Group',
            cell: row => row.catName,
        },
        {
            name: 'Type',
            cell: row => row.catSubType,
        },
        {
            name: 'Date',
            cell: (row) => moment(row.woDate).utc().format('DD-MM-YYYY'),
            omit: user?.access?.Date > 0 ? false : true,
        },
        {
            name: 'wo_qty',
            cell: (row) => row.wo_qty,
            hide: 'md',
        },
        {
            name: 'Customer',
            cell: (row) => row.cust_proj,
            hide: 'md',
        },
        {
            name: 'Rej(Art#)',
            selector: (row) =>
                row.stages.map((item) => {
                    let qtyVal = [];
                    item.data.map((data) => {
                        data?.rejection?.map((short) => {
                            {
                                qtyVal.push(short['article#']);
                            }
                        });
                    });
                    return (qtyVal.map((val, i) => {
                        return <Tooltip title={val} key={i}>
                            <div className="p-3 px-0">{val}</div>
                        </Tooltip>
                    })
                    );
                }),
            hide: 'md',
        },
        {
            name: 'Rej Qty',
            selector: (row) =>
                row.stages.map((item) => {
                    let qtyVal = [];
                    item.data.map((data) => {
                        data?.rejection?.map((short) => {
                            {
                                qtyVal.push(short.qty);
                            }
                        });
                    });
                    return (qtyVal.map((val, i) => {
                        return <div key={i} className="p-3 px-0">{val}</div>
                    })
                    );
                }),
            hide: 'md',
        },
        {
            name: 'Rej Desc',
            selector: (row) =>
                row.stages.map((item) => {
                    let qtyVal = [];
                    item.data.map((data) => {
                        data?.rejection?.map((short) => {
                            {
                                qtyVal.push(short.desc);
                            }
                        });
                    });
                    return (qtyVal.map((val, i) => {
                        return <Tooltip title={val} key={i}>
                            <div className="p-3 px-0">{val}</div>
                        </Tooltip>
                    })
                    );
                }),
            hide: 'md',
        },
        {
            name: 'Short(Art#)',
            selector: (row) =>
                row.stages.map((item) => {
                    let qtyVal = [];
                    item.data.map((data) => {
                        data?.shortage?.map((short) => {
                            {
                                qtyVal.push(short['article#']);
                            }
                        });
                    });
                    return (qtyVal.map((val, i) => {
                        return <Tooltip title={val} key={i}>
                            <div className="p-3 px-0">{val}</div>
                        </Tooltip>
                    })
                    );
                }),
            hide: 'md',
        },
        {
            name: 'Short Qty',
            selector: (row) =>
                row.stages.map((item) => {
                    let qtyVal = [];
                    item.data.map((data) => {
                        data?.shortage?.map((short) => {
                            {
                                qtyVal.push(short.qty);
                            }
                        });
                    });
                    return (qtyVal.map((val, i) => {
                        return <div key={i} className="p-3 px-0">{val}</div>
                    })
                    );
                }),
            hide: 'md',
        },
        {
            name: 'Short Desc',
            selector: (row) =>
                row.stages.map((item) => {
                    let qtyVal = []
                    item.data.map((data) => {
                        data?.shortage?.map((short) => {
                            {
                                qtyVal.push(short.desc);
                            }
                        });
                    });
                    return (qtyVal.map((val, i) => {
                        return <Tooltip title={val} key={i}>
                            <div className="p-3 px-0">{val}</div>
                        </Tooltip>
                    })
                    );
                }),
            hide: 'md',
        },
    ];

    useEffect(() => {
        disp()
        axios.get('/wo/getArt').then((res) => {
            // console.log(res.data)
            setArtOptions(res.data)
        }).catch((e) => {
            console.log(e)
        })
        axios.get('/category/').then((res) => {
            if (res.status === 200) {
                setCategories(res.data.categories)
            }
        }).catch((e) => {
            console.log(e)
        })
    }, [])

    useEffect(() => {
        let result = wosAll?.filter((item) => {
            let val = false
            item.stages.map((ele) => {
                ele.data.map((data) => {
                    data?.shortage?.map((short) => {
                        if (short.qty !== '') {
                            return val = true
                        }
                    })
                    data?.rejection?.map((reject) => {
                        if (reject.qty !== '') {
                            return val = true
                        }
                    })
                    return val
                })
            })
            return val
        })
        setWos(result)
        setFilteredWos(result)
        setExportList(result)
    }, [wosAll])

    useEffect(() => {
        const data = wos.filter((item) => {
            return (String(item.ppoItem).toLowerCase().match(String(search).toLocaleLowerCase())
                || String(item["wo#"]).toLowerCase().match(String(search).toLocaleLowerCase())
                || String(item["article#"]).toLowerCase().match(String(search).toLocaleLowerCase())
            )
        })
        setFilteredWos(data)
        setExportList(data)
    }, [search])
    return <div className='mainHeight'>
        <div>
            <ReportsNav name='process' />
            <h3 className='text-center'>Process Report</h3>
            {/* {JSON.stringify(loading)} */}
        </div>
        <div className='d-flex justify-content-center p-2'>
            <div className="form-floating filtercust">
                <select className="form-select" id='floatingSelect2' name='cust_proj' onChange={(e) => handleCustChange(e)}>
                    {cust?.map((option, index) => {
                        return <option key={index} value={option} >{option}</option>
                    })}
                </select>
                <label htmlFor="floatingSelect2">Select Customer</label>
            </div>
            <div className="form-floating filtercust">
                <select className="form-select" id='floatingSelect3' name='catName' defaultValue='all' onChange={(e) => handleCatChange(e)}>
                    <option value='all'>All</option>
                    {
                        [...new Set(categories?.reduce((arr, item) => {
                            if (item?.enable) {
                                arr[0].push(item.catName)
                            } else {
                                arr[1].push(item.catName)
                            }
                            return arr
                        }, [[], []])?.[0])]?.map((option, index) => {
                            return <option key={index} value={option}>{option}</option>
                        })
                    }
                </select>
                <label htmlFor="floatingSelect3">Select Product Group</label>
            </div>
            <div className="form-floating filtercust">
                <select className="form-select" id='floatingSelect4' name='catSubType' defaultValue='all' onChange={(e) => handleCatSubTypeChange(e)}>
                    <option value='all'>All</option>
                    {
                        categories?.filter((item1) => {
                            if (item1?.enable) {
                                return String(item1["catName"]).toLowerCase() === (String(document.getElementById("floatingSelect3").value).toLocaleLowerCase())
                            } else {
                                return false
                            }
                        }).map((item2) => {
                            if (item2?.enable) {
                                return item2.catType
                            }
                        })?.map((option, index) => {
                            return <option key={index} value={option}>{option}</option>
                        })
                    }
                </select>
                <label htmlFor="floatingSelect4">Select Type</label>
            </div>
        </div>
        <div className='card'>
            <section>
                <form id='searchForm' className='masterbody proRep'   >
                    <FloatingLabel label="Search ppo_item / WO# / Art#">
                        <Form.Control name='ppoItem' type="text" value={search} onChange={(e) => setSearch(e.target.value)} onInput={(e) => handleInput(e)} placeholder="none" autoComplete="off" />
                    </FloatingLabel>
                    <div className="form-floating input-group-sm select_box">
                        <input className="form-control" id="floatingTextarea" list="datalistOptions4" placeholder="none" name='article#' value={proForm['article#'] ? proForm['article#'] : ""} onInput={(e) => handleInput(e)} autoComplete="off" />
                        <datalist id="datalistOptions4" >
                            {artOptions.map((option, i) => {
                                return <option key={i} value={option} />
                            })}
                        </datalist>
                        <label htmlFor="floatingTextarea">Select Short(Art#) / Rej(Art#)</label>
                    </div>
                    {/* <FloatingLabel label="Search wo#">
                    <Form.Control name='wo#' type="text" value={search} onInput={(e) => handleInput(e)} placeholder="none" autoComplete="off" />
                </FloatingLabel> */}
                    <FloatingLabel label="Select from date">
                        <Form.Control id='fromDate' name='fromDate' type="date" value={proForm.fromDate} onInput={(e) => handleInput(e)} placeholder="none" autoComplete="off" />
                    </FloatingLabel>
                    <FloatingLabel label="Select to date">
                        <Form.Control id='toDate' name='toDate' type="date" value={proForm.toDate} onInput={(e) => handleInput(e)} placeholder="none" autoComplete="off" />
                    </FloatingLabel>
                </form>
            </section>
            <section className='masterbtn'>
                <div >
                    <Button type="primary" onClick={() => handleGet(proForm)} loading={loading.isLoading} disabled={loading.isDisabled}>Get Report</Button>
                    <Button type="primary" onClick={() => handleClear()}>Clear</Button>
                </div>
            </section>
        </div>
        <div className='card'>
            <DataTable
                columns={columns}
                data={filteredwos}
                pagination
                fixedHeader
                paginationDefaultPage={1}
                fixedHeaderScrollHeight="380px"
                highlightOnHover
                selectableRows
                selectableRowsHighlight
                onSelectedRowsChange={(row) => setExportList(row.selectedRows)}
                // selectableRowSelected={(row) => row.isSelected}
                // selectableRowSelected={filteredData}
                noDataComponent={loading.data ? <div className='p-3'><Spin /></div> : <div className='p-3'>No Data</div>}
                actions
                style={{ zIndex: '499' }}
            />
        </div>
        {/* {JSON.stringify(filteredwos)} */}
        <Button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => downloadXLSX()} loading={loading.isDownloading}>Export</Button>
    </div>
};
