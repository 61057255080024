
const formValidator = (formData, checkObj) => {
  console.log(formData);
  const errors = {};
  Object.keys(checkObj).forEach((val) => {
    if (!formData[val]) {
      errors[val] = checkObj[val];
    }
  });

  return errors;
};

export { formValidator };
