import React, { useState, useEffect } from 'react'
import { Tab, Tabs, FloatingLabel, Form } from 'react-bootstrap'
import axios from '../../../../../api/axios';
import { Button, Spin, message } from 'antd'
import TableRows from './TableRows';
import { Backdrop, CircularProgress } from '@mui/material';
import { socket } from '../../../../../services/socket';
const rowInputs = { "article#": "", qty: "", desc: "", };

export const StageTab = ({ _id, stageData, stageName, reset, StageName, hidden, setVisible, loading, setLoading }) => {
  const [info, setInfo] = useState({ processQty: '', manPower: '', desc: '' });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };
  const [shortage, setShortage] = useState([])
  const [rejection, setRejection] = useState([])
  // console.log(stageName)
  const addRow = (isShortage = true) => {
    let rows = shortage
    let setRows = setShortage
    if (!isShortage) {
      rows = rejection
      setRows = setRejection
    }
    setRows([...rows, structuredClone(rowInputs)])
  }
  const deleteRow = (index, isShortage = true) => {
    let rows = [...shortage]
    let setRows = setShortage
    if (!isShortage) {
      rows = [...rejection]
      setRows = setRejection
    }
    rows.splice(index, 1)

    setRows(rows)
  }

  const handleChange = (e, i, isShortage = true) => {
    let rows = [...shortage]
    let setRows = setShortage
    if (!isShortage) {
      rows = [...rejection]
      setRows = setRejection
    }

    const { name, value } = e.target
    rows[i][name] = value
    setRows(rows)
  }

  const resetForm = (e) => {
    document.getElementById('form1').reset()
    setRejection([])
    setShortage([])
    setInfo({ processQty: "", manPower: "", desc: "" });
  }

  const [valid, setValid] = useState(stageData?.valid ? stageData?.valid : false)
  const handleValidInput = async (e) => {
    await axios.get(`/wo/valid/${_id}/${valid}/${StageName}`).then((res) => {
      if (res.status === 200) {
        setValid(e.target.checked)
        alert('Success')
      }
    }).catch(err => {
      alert('error')
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.patch('/wo/stages', { StageName, data: { info, shortage, rejection }, _id }).then((res) => {
      if (res.status === 200) {
        resetForm()
        message.success(`${StageName} Data Saved!!`)
      } else {
        message.error(`Error Saving ${StageName} Data!!`)
        setLoading({ ...loading, isLoading: false })
      }
    }).catch((e) => {
      message.error(`Error Saving ${StageName} Data!!`)
      setLoading({ ...loading, isLoading: false })
    })
  }

  return (
    <div className="">
      <div className="woOptionSection1">
        <section className="">
          <header className="d-flex justify-content-between align-items-center gap-2 bg-secondary p-2">
            <div className="d-flex align-items-center gap-2">
              <h3 className="text-white">{stageName}</h3>
            </div>
            {/* <div>
              <label htmlFor="valid">Valid</label> &nbsp;
              <input
                type="checkbox"
                name="valid"
                id="valid"
                onChange={handleValidInput}
                checked={valid}
              />
            </div> */}
          </header>

          <div className="p-2">
            <Tabs defaultActiveKey="current" className="justify-content-center">
              {
                !hidden
                  ?
                  <Tab eventKey="current" title="Update">
                    <form id="form1">
                      <div
                        style={{ background: stageData?.data[0] ? "forestgreen" : "red" }}
                        disabled={true}
                      >
                        <div className="d-flex gap-2 pb-3">
                          <FloatingLabel className="my-2 " label="Process Qty">
                            <Form.Control
                              type="text"
                              placeholder="none"
                              name="processQty"
                              value={info.processQty}
                              onChange={handleInput}
                              autoComplete="off"
                            />
                          </FloatingLabel>
                          <FloatingLabel className="my-2 " label="Man Power">
                            <Form.Control
                              type="text"
                              placeholder="none"
                              name="manPower"
                              value={info.manPower}
                              onChange={handleInput}
                              autoComplete="off"
                            />
                          </FloatingLabel>
                          <FloatingLabel className="my-2 w-100" label="Description">
                            <Form.Control
                              type="text"
                              placeholder="none"
                              name="desc"
                              value={info.desc}
                              onChange={handleInput}
                              autoComplete="off"
                            />
                          </FloatingLabel>
                        </div>
                        <div className="woOptions">
                          <div className="d-flex gap-2 p-2 shortrejrow">
                            <div className="table-responsive w-100 tableFixHead">
                              <table className="table Shortage-body">
                                <thead>
                                  <tr className="text-center ">
                                    <th colSpan="4">Shortage</th>
                                  </tr>
                                  <tr>
                                    <th>Article No</th>
                                    <th>Qty</th>
                                    <th colSpan="2">Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <TableRows
                                    rowsData={shortage}
                                    handleChange={handleChange}
                                    deleteTableRows={deleteRow}
                                    isShortage={true}
                                  />
                                  <tr>
                                    <td
                                      colSpan="100%"
                                      style={{ textAlign: "right" }}
                                    >
                                      <input
                                        type="button"
                                        className="btn btn-dark"
                                        value="+"
                                        disabled={
                                          Object.keys(info).length > 0 &&
                                            info["processQty"] != ""
                                            ? false
                                            : true
                                        }
                                        // disabled={shortage[shortage.length - 1]['article#'] === "" && shortage[shortage.length - 1]['qty'] ==="" ?true : false  }
                                        onClick={addRow}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="table-responsive w-100 tableFixHead">
                              <table className="table Rejection-body">
                                <thead>
                                  <tr className="text-center">
                                    <th colSpan="4">Rejection</th>
                                  </tr>
                                  <tr>
                                    <th>Article No</th>
                                    <th>Qty</th>
                                    <th colSpan="2">Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <TableRows
                                    rowsData={rejection}
                                    handleChange={handleChange}
                                    deleteTableRows={deleteRow}
                                    isShortage={false}
                                  />
                                  <tr>
                                    <td
                                      colSpan="100%"
                                      style={{ textAlign: "right" }}
                                    >
                                      <input
                                        type="button"
                                        className="btn btn-dark"
                                        value="+"
                                        disabled={
                                          Object.keys(info).length > 0 &&
                                            info["processQty"] != ""
                                            ? false
                                            : true
                                        }
                                        onClick={() => addRow(false)}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Tab>
                  :
                  null
              }
              <Tab eventKey="prev" title="Entry Logs">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr className="table-primary">
                        <th>SubStage</th>
                        <th>Process Qty</th>
                        <th>Man Power</th>
                        <th>Desc</th>
                        <th className="text-center">Shortage</th>
                        <th className="text-center">Rejection</th>
                      </tr>
                    </thead>
                    <tbody>

                      {stageData?.data.map((data, i) => {
                        return (
                          <tr className="mb-2" key={i}>
                            <td>{i + 1}</td>
                            <td>{data?.info?.processQty}</td>
                            <td>{data?.info?.manPower}</td>
                            <td>{data?.info?.desc}</td>
                            <td>
                              {
                                (data?.shortage) ?

                                  <table className="table table-sm table-striped table-bordered " >
                                    <thead>
                                      <tr className="table-danger">
                                        <td><b>Article</b></td>
                                        <td><b>Qty</b></td>
                                        <td><b>Desc</b></td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data.shortage.map((data, i) => {

                                        return (
                                          <tr key={i}>
                                            <td>{data?.['article#']}</td>
                                            <td>{data?.['qty']}</td>
                                            <td>{data?.['desc']}</td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                  :
                                  ""
                              }
                            </td>
                            <td>
                              {
                                (data?.rejection) ?
                                  <table className="table table-sm table-striped table-bordered">
                                    <thead>
                                      <tr className="table-danger">
                                        <td><b>Article</b></td>
                                        <td><b>Qty</b></td>
                                        <td><b>Desc</b></td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data.rejection.map((data, i) => {

                                        return (
                                          <tr key={i}>
                                            <td>{data?.['article#']}</td>
                                            <td>{data?.['qty']}</td>
                                            <td>{data?.['desc']}</td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>

                                  : ""
                              }
                            </td>

                          </tr>

                        )

                      })}
                    </tbody>
                    <tbody>
                      {/* <td><input type="button" className="" value="Add Data" disabled={subStageKeys[subStageKeys.length - 1] == 'valid' ? subStageKeys[subStageKeys.length - 2] == key ? false : true : true} /></td> */}
                      {/* <tr>
                          <td colSpan='100%' style={{textAlign:'right'}}>
                            <input type="button" value="Add to Last Stage " />
                          </td>
                        </tr> */}
                    </tbody>
                  </table>
                </div>
              </Tab>
            </Tabs>
          </div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading.isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </section>
      </div>
      <div
        className="fixed-bottom px-5 py-2 w-100"
        style={{ textAlign: "right" }}
      >
        <Button
          type="primary"
          onClick={(e) => { setLoading({ ...loading, isLoading: true }); (handleSubmit(e)); }}
          disabled={
            Object.keys(info).length > 0 && info["processQty"] != ""
              ? false
              : true
          }
        >
          Save {stageName}
        </Button>
      </div>
    </div>
  );
}
