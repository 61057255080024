import { createSlice } from "@reduxjs/toolkit";

const initialValue = { ppos: [] };

export const pposSlice = createSlice({
  name: "ppoInfo",
  initialState: { value: initialValue },
  reducers: {
    setPPOS: (state, action) => {
      console.log(action.payload)
      // let ppos = action.payload;
      console.log({ ppos: action.payload });
      state.value = { ppos: action.payload };
    },
    removePPOS: (state, action) => {
      state.value = initialValue;
    },
  },
});

export const { setPPOS, removePPOS } = pposSlice.actions;

export default pposSlice.reducer;
