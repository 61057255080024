import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MesNav } from '../helper/MesNav';
import { ReportsNav } from './helper/ReportsNav';

export const ReportsHome = () => {
    const navigate = useNavigate();

    return (
        <div className="p-1">
            {/* <MesNav name="reports" /> */}
            <ReportsNav />
            {/* <div className="contentContainer">
                <div className="apps">
                    <div
                        className="appContainer"
                        onClick={() => navigate('/mesHome/reports/process')}
                    >
                        <h3>Process Reports</h3>
                    </div>
                    <div
                        className="appContainer"
                        onClick={() => navigate('/mesHome/reports/quality')}
                    >
                        <h3>Quality Reports</h3>
                    </div>
                </div>
            </div> */}
        </div>
    );
};
