import React, { useEffect, useState } from 'react'
import axios from '../../../api/axios'
import DataTable from 'react-data-table-component'
import { Badge, Button, Col, Collapse, message, Radio, Row, Slider, Space, Switch, Tag } from 'antd'
import { SettingOutlined, StopOutlined } from '@ant-design/icons';
import { socket } from '../../../services/socket'
import { useGetUserDetails } from '../../../hooks/useGetUserDetails';
import { useDispatch } from 'react-redux';
import { userLogin } from '../../../features/users';
const { Panel } = Collapse;

export const UserRoles = () => {
    const dispatcher = useDispatch()
    const { user, token } = useGetUserDetails()
    const [allUser, setAllUser] = useState([])
    const [filteredAllUser, setFilteredAllUser] = useState([])
    const [unverifiedAllUser, setUnverifiedAllUser] = useState([])
    const [deniedAllUser, setDeniedAllUser] = useState([])
    const [access, setAccess] = useState({})
    const [verify, setVerify] = useState({})
    const [keys, setKeys] = useState({})
    const [search, setSearch] = useState({});
    const [updating, setUpdating] = useState(false);
    const [accessVal, setAccessVal] = useState({})

    // const handleEnable = (e, row, value, num, key) => {
    //     let { name, checked } = e.target
    //     if (Object.keys(keys).includes(key)) {
    //         if (!keys[key].includes(row.email))
    //             setKeys({ ...keys, [key]: [...keys[key], row.email] })
    //     } else {
    //         setKeys({ ...keys, [key]: [row.email] })
    //     }
    //     // console.log(row._id, name, value, checked, num)
    //     if (num === 1 && checked === false) {
    //         num = 0
    //     }
    //     if (num === 2 && checked === false) {
    //         num = 1
    //     }
    //     setAccess({ ...access, [row._id]: { ...access[row._id], [value]: num } })
    // };

    const handleUpdate = async () => {
        socket.emit('send-msg', { 'to': Object.keys(access), "msg": "message" })
        setUpdating(true)
        await axios.post('/user/updateAccess', access).then((res) => {
            if (res.status === 200) {
                message.success('Data saved successfully!')
                setAllUser(res.data.allUser)
            } else {
                message.warning('Data not saved!')
            }
            if (res.status === 200) {

            }
        }).catch((e) => {
            console.log(e)
        })
        setUpdating(false)
        setAccess({})
        setKeys({})
    }

    const disp = async () => {
        await axios.get('/user/all').then((res) => {
            if (res.status === 200) {
                setAllUser(res.data.allUser)
                let verified = res.data.allUser.filter((item) => {
                    return item.verified === 1
                })
                setFilteredAllUser(verified)
                let unverified = res.data.allUser.filter((item) => {
                    return item.verified === 0
                })
                setUnverifiedAllUser(unverified)
                let denied = res.data.allUser.filter((item) => {
                    return item.verified === -1
                })
                setDeniedAllUser(denied)
            } else {
                message.warning('No data found!')
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    const tableObj = {
        "New Users": ['email', 'acknowledge'],
        "Quarantined Users": ['email', 'acknowledge'],
        Master: ['email', 'Article Master', 'Category Master', 'Stages Master', 'UOM Master', 'Customer Master', 'Supplier Master', 'Instrument Master', 'Test Plan Master', 'Bom Master'],
        Process: ['email', 'PPO', 'WO', 'Process Reports'],
        Quality: ['email', 'Incoming Quality', 'Quality Reports'],
        Plots: ['email', 'WO In-Progress', 'WO Shortage/Rejection', 'YTD/MTD'],
        Repository: ['email', 'Repository'],
        Date: ['email', 'Date'],
        Acm: ['email', 'Acm'],
    }

    const marks = {
        0: <StopOutlined style={{ display: 'inline-flex' }} />,
        1: 'R',
        2: 'RW',
    }

    const handleChange = (row, value, num, key) => {
        if (Object.keys(keys).includes(key)) {
            if (!keys[key].includes(row.email))
                setKeys({ ...keys, [key]: [...keys[key], row.email] })
        } else {
            setKeys({ ...keys, [key]: [row.email] })
        }
        setAccess({ ...access, [row._id]: { ...access[row._id], [`access.${value}`]: num } })
    }

    const handleVerify = async (row, value, e, key) => {
        let send = {}
        send[row._id] = { verified: e.target.value }
        setUpdating(true)
        await axios.post('/user/updateAccess', send).then((res) => {
            if (res.status === 200) {
                message.success('Data saved successfully!')
                setAllUser(res.data.allUser)
            } else {
                message.warning('Data not saved!')
            }
        }).catch((e) => {
            console.log(e)
        })
        setUpdating(false)
        setAccess({})
        setKeys({})
    }

    const aaaa = Object.entries(tableObj).map(([key, values]) => {
        if (key === 'New Users' || key === "Quarantined Users") {
            return values.map(value => {
                let cell = value === 'email' ? (row) => row?.[value] : (row) => <div className='d-flex flex-column gap-1'>
                    <div className='d-flex gap-2' style={key === 'New Users' ? { width: '160px' } : { width: '100px' }}>
                        <button type="button" className="btn btn-danger" value={-1} onClick={(e) => handleVerify(row, value, e, key)} disabled={updating ? true : false} hidden={key === "Quarantined Users" ? true : false}>Deny</button>
                        <button type="button" className="btn btn-success" value={1} onClick={(e) => handleVerify(row, value, e, key)} disabled={updating ? true : false}>Acknowledge</button>
                    </div>
                </div>
                return {
                    name: String(value).toUpperCase(),
                    cell: cell,
                    width: value === 'email' ? '250px' : value === "" ? '100px' : "",
                    center: value === 'email' ? false : true
                }
            })
        } else {
            return values.map(value => {
                let cell = value === 'email' ? (row) => row?.[value] : (row) => <div className='d-flex flex-column gap-1'>

                    <div className='border' style={{ width: '150px' }}>
                        <div className='px-3'>
                            {
                                Object.keys(access).length > 0
                                    ?
                                    <Slider
                                        trackStyle={access[row._id] ? access[row._id][`access.${value}`] || access[row._id][`access.${value}`] === 0 ? access[row._id][`access.${value}`] > 1 ? { backgroundColor: "#F9D923" } : { backgroundColor: "#97D2EC" } : row?.access?.[value] > 1 ? { backgroundColor: "#F9D923" } : { backgroundColor: "#97D2EC" } : row?.access?.[value] > 1 ? { backgroundColor: "#F9D923" } : { backgroundColor: "#97D2EC" }}
                                        activeDotStyle={access[row._id] ? access[row._id][`access.${value}`] || access[row._id][`access.${value}`] === 0 ? access[row._id][`access.${value}`] > 1 ? { borderColor: "#F9D923" } : { borderColor: "#97D2EC" } : row?.access?.[value] > 1 ? { borderColor: "#F9D923" } : { borderColor: "#97D2EC" } : row?.access?.[value] > 1 ? { borderColor: "#F9D923" } : { borderColor: "#97D2EC" }}
                                        handleStyle={access[row._id] ? access[row._id][`access.${value}`] || access[row._id][`access.${value}`] === 0 ? access[row._id][`access.${value}`] > 1 ? { borderColor: "#FFB319" } : { borderColor: "#47B5FF" } : row?.access?.[value] > 1 ? { borderColor: "#FFB319" } : { borderColor: "#47B5FF" } : row?.access?.[value] > 1 ? { borderColor: "#FFB319" } : { borderColor: "#47B5FF" }}
                                        marks={marks}
                                        value={access[row._id] ? access[row._id][`access.${value}`] ? access[row._id][`access.${value}`] : access[row._id][`access.${value}`] === 0 ? 0 : row?.access?.[value] : row?.access?.[value]}
                                        min={0}
                                        max={2}
                                        tooltip={{ open: false }}
                                        onChange={(num) => handleChange(row, value, num, key)} />
                                    :
                                    <Slider
                                        trackStyle={row?.access?.[value] > 1 ? { backgroundColor: "#F9D923" } : { backgroundColor: "#97D2EC" }}
                                        activeDotStyle={row?.access?.[value] > 1 ? { borderColor: "#F9D923" } : { borderColor: "#97D2EC" }}
                                        handleStyle={row?.access?.[value] > 1 ? { borderColor: "#FFB319" } : { borderColor: "#47B5FF" }}
                                        marks={marks}
                                        value={row?.access?.[value]}
                                        min={0}
                                        max={2}
                                        tooltip={{ open: false }}
                                        onChange={(num) => handleChange(row, value, num, key)} />
                            }
                        </div>
                    </div>
                </div>
                return {
                    name: String(value).toUpperCase(),
                    cell: cell,
                    width: value === 'email' ? '250px' : value === "" ? '100px' : "",
                    center: value === 'email' ? false : true
                }
            })
        }
    })

    const extraComponent = (value, i) => {
        return (
            <div className='d-flex align-items-center gap-3'>
                <Badge count={Object.keys(keys).includes(value) ? keys[value].length : 0}>
                    <Button type="primary" size='small' onClick={(e) => { handleUpdate(); e.stopPropagation() }} disabled={Object.keys(keys).length <= 1 ? !Object.keys(keys).includes(value) ? true : false : true} loading={Object.keys(keys).includes(value) ? updating : false}>{updating ? Object.keys(keys).includes(value) ? "Updating" : "Update" : "Update"}</Button>
                </Badge>
                <SettingOutlined />
            </div>
        )

    }

    const searchComponent = <div className='d-flex align-items-center'>
        <div className="form-floating searchBox input-group-sm select_box">
            <input
                className="form-control"
                placeholder="Search Email"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            <label htmlFor="floatingTextarea">Search Email</label>
        </div>
        <Button type="primary" onClick={() => setSearch('')}>
            Clear
        </Button>
    </div>

    useEffect(() => {
        disp()
    }, [])
    // useEffect(() => {
    // }, [accessVal])

    useEffect(() => {
        if (search.name === "New Users") {
            let res = allUser.filter((item) => {
                return item.verified === 0
            })
            let unverified = res.filter((item) => {
                return String(item.email).toLowerCase().match(search?.value?.toLocaleLowerCase())
            })
            setUnverifiedAllUser(unverified)
        } else if (search.name === "Quarantined Users") {
            let res = allUser.filter((item) => {
                return item.verified === -1
            })
            let denied = res.filter((item) => {
                return String(item.email).toLowerCase().match(search?.value?.toLocaleLowerCase())
            })
            setDeniedAllUser(denied)
        } else {
            let res = allUser.filter((item) => {
                return item.verified === 1
            })
            let verified = res.filter((item) => {
                return String(item.email).toLowerCase().match(search?.value?.toLocaleLowerCase())
            })
            setFilteredAllUser(verified)
        }
    }, [search])

    useEffect(() => {
        socket.on('aclUpdate', (allUsers) => {
            let currUser = allUsers.filter((item) => {
                return user?._id === item?._id
            })
            if (currUser) {
                let temp = { token: token }
                temp.user = currUser?.[0]
                dispatcher(userLogin({ ...temp }));
            }
            setAllUser(allUsers)
            let verified = allUsers.filter((item) => {
                return item.verified === 1
            })
            setFilteredAllUser(verified)
            let unverified = allUsers.filter((item) => {
                return item.verified === 0
            })
            setUnverifiedAllUser(unverified)
            let denied = allUsers.filter((item) => {
                return item.verified === -1
            })
            setDeniedAllUser(denied)
        })
    }, [socket])

    return (
        <div className='m-auto marginErr d-flex flex-column'>
            <h1 className='text-center'>Access Control Management</h1>
            {/* {JSON.stringify(access)} */}
            {/* <br /> */}
            {/* {JSON.stringify(keys)} */}
            <Collapse expandIconPosition='end' accordion>

                {
                    Object.entries(tableObj).map(([key], i) => {
                        return (
                            <Panel header={key} key={i} extra={key === 'New Users' || key === 'Quarantined Users' ? null : extraComponent(key, i)}>
                                <div>
                                    <div className='pb-3 d-flex align-items-center gap-3' >
                                        <div className="form-floating searchBox input-group-sm select_box">
                                            <input
                                                className="form-control"
                                                placeholder="Search Email"
                                                name={key}
                                                value={search.value}
                                                onChange={(e) => setSearch({ name: e.target.name, value: e.target.value })}
                                            />
                                            <label htmlFor="floatingTextarea">Search Email</label>
                                        </div>
                                        <Button type="primary" onClick={() => setSearch({ name: '', value: '' })}>
                                            Clear search
                                        </Button>
                                    </div>
                                    <DataTable
                                        columns={aaaa[i]}
                                        data={key === 'New Users' ? unverifiedAllUser : key === 'Quarantined Users' ? deniedAllUser : filteredAllUser}
                                        pagination
                                        fixedHeader
                                        paginationDefaultPage={1}
                                        fixedHeaderScrollHeight="450px"
                                    // actions={searchComponent}
                                    />
                                </div>
                            </Panel>
                        )
                    })
                }
            </Collapse>
            <div className='d-flex p-3 justify-content-center'>
                <Button type="primary" onClick={() => handleUpdate()} disabled={Object.keys(keys).length > 1 ? false : true} loading={updating}>{updating ? "Updating all" : "Update all"}</Button>
            </div>
        </div>
    )
}
