import React from 'react'
import { useNavigate } from 'react-router-dom';
import { MesNav } from '../helper/MesNav';
import { Masternav } from './helper/MasterNav';

export const Master = () => {
    const navigate = useNavigate();
    return (
        <div className='p-1'>
            <Masternav />
            {/* <div className="contentContainer">
                <div className="apps">
                    <div className="appContainer" onClick={() => navigate("/mesHome/master/article")}>
                        <h3>Article</h3>
                    </div>
                    <div className="appContainer" onClick={() => navigate("/mesHome/master/uom")}>
                        <h3>UOM</h3>
                    </div>
                    <div className="appContainer" onClick={() => navigate("/mesHome/master/customer")}>
                        <h3>Customer</h3>
                    </div>
                    <div className="appContainer" onClick={() => navigate("/mesHome/master/stages")}>
                        <h3>Stages</h3>
                    </div>
                    <div className="appContainer" onClick={() => navigate("/mesHome/master/supplier")}>
                        <h3>Supplier</h3>
                    </div>
                    <div className="appContainer" onClick={() => navigate("/mesHome/master/instrument")}>
                        <h3>Instrument</h3>
                    </div>
                    <div className="appContainer" onClick={() => navigate("/mesHome/master/testplan")}>
                        <h3>Test Plan</h3>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
