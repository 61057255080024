import { createSlice } from "@reduxjs/toolkit";

const initialValue = { wos: [] };

export const woSlice = createSlice({
  name: "woInfo",
  initialState: { value: initialValue },
  reducers: {
    setWO: (state, action) => {
      // console.log('from redux wo')
      // console.log(action.payload);
      let wos = action.payload;

      state.value = { wos };
    },
    removeWO: (state, action) => {
      state.value = initialValue;
    },
  },
});

export const { setWO, removeWO } = woSlice.actions;

export default woSlice.reducer;
