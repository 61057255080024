import React from 'react'

export const Test = ({disVal}) => {
    const sharKK=true
    return (
        <div style={{}}>
            <button className='btn btn-primary ' onClick={()=>alert('hello')} disabled={sharKK ?  true ? false :                   false : true}>Click</button>
            {/* <button>cl</button> */}
        </div>
    )
}
