import React from 'react'
import { Link } from "react-router-dom";
import { Nav, Navbar, Container } from 'react-bootstrap'
import WithPermission from '../../../Auth/Permissions/WithPermission';
import { Tabs } from 'antd'

export const Masternav = ({ name }) => {
  const tabs = [
    {
      role: "Article Master",
      link: "/mesHome/master/article",
      name: 'article',
    },
    {
      role: "Category Master",
      link: "/mesHome/master/category",
      name: 'product group',
    },
    {
      role: "Stages Master",
      link: "/mesHome/master/stages",
      name: 'stages',
    },
    {
      role: "UOM Master",
      link: "/mesHome/master/uom",
      name: 'uom',
    },
    {
      role: "Customer Master",
      link: "/mesHome/master/customer",
      name: 'customer',
    },
    {
      role: "Supplier Master",
      link: "/mesHome/master/supplier",
      name: 'supplier',
    },
    {
      role: "Instrument Master",
      link: "/mesHome/master/instrument",
      name: 'instrument',
    },
    {
      role: "Test Plan Master",
      link: "/mesHome/master/testplan",
      name: 'Qualityplan',
    },
    {
      role: "Bom Master",
      link: "/mesHome/master/bom",
      name: 'bom',
    },
  ]

  return (
    <div>
      {/* <Navbar bg="light" variant="light" >
        <Container fluid style={{ overflow: 'auto' }}>
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0 d-flex justify-content-center"
              style={{ maxHeight: '90vh', width: "100%" }}
            >
              <WithPermission roleRequired="Article Master">
                <Nav.Link ><Link to="/mesHome/master/article" style={name === 'article' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>Article</Link></Nav.Link>
              </WithPermission>
              <WithPermission roleRequired="Stages Master">
                <Nav.Link ><Link to="/mesHome/master/stages" style={name === 'stages' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>Stages</Link></Nav.Link>
              </WithPermission>
              <WithPermission roleRequired="UOM Master">
                <Nav.Link ><Link to="/mesHome/master/uom" style={name === 'uom' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>UOM</Link></Nav.Link>
              </WithPermission>
              <WithPermission roleRequired="Customer Master">
                <Nav.Link ><Link to="/mesHome/master/customer" style={name === 'customer' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>Customer</Link></Nav.Link>
              </WithPermission>
              <WithPermission roleRequired="Supplier Master">
                <Nav.Link ><Link to="/mesHome/master/supplier" style={name === 'supplier' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>Supplier</Link></Nav.Link>
              </WithPermission>
              <WithPermission roleRequired="Instrument Master">
                <Nav.Link ><Link to="/mesHome/master/instrument" style={name === 'instrument' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>Instrument</Link></Nav.Link>
              </WithPermission>
              <WithPermission roleRequired="Test Plan Master">
                <Nav.Link ><Link to="/mesHome/master/testplan" id="testplan" style={name === 'testplan' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light navMaxWidth'>Test Plan</Link></Nav.Link>
              </WithPermission>
              <WithPermission roleRequired="Bom Master">
                <Nav.Link ><Link to="/mesHome/master/bom" style={name === 'bom' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>Bom</Link></Nav.Link>
              </WithPermission>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
      {/* <Tabs centered defaultActiveKey={name} tabPosition="top">
        {
          tabs.map((tab) => {
            // console.log(tab?.access)
            return (
              <Tabs.TabPane tab={<WithPermission roleRequired={tab.role}><Link to={tab.link} style={name === tab.name ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>{tab.name}</Link></WithPermission>} key={tab.name} />
            )
          })
        }
      </Tabs> */}
      <Tabs centered defaultActiveKey={name} tabPosition="top"
        items={
          tabs?.map((tab) => {
            return {
              label: <WithPermission roleRequired={tab.role}><Link to={tab.link} style={name === tab.name ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>{tab.name}</Link></WithPermission>,
              key: tab.name,
            }
          })
        }
      />
    </div>
  )
}
