import React, { useEffect, useState } from 'react';
import { BellFilled, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Badge, notification, Modal, message, Spin } from 'antd';
import moment from 'moment';
import { Form, FloatingLabel } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from '../../../../../api/axios';
import { formValidator } from '../../../../../services/formValidator';
import { Masternav } from '../../helper/MasterNav';
import { useNavigate } from 'react-router-dom';
import { ImageUpload } from './helper/ImageUpload';
import { socket } from '../../../../../services/socket';
import { Backdrop, CircularProgress } from '@mui/material';

export const Instrument = ({ hidden }) => {
    const [instForm, setInstForm] = useState({});
    const [inst, setInst] = useState([]);
    const [filteredinst, setFilteredInst] = useState([]);
    const [search, setSearch] = useState('');
    const [formValidationError, setFormValidationError] = useState({});
    const [dueItems, setDueItems] = useState({});
    const [dueCount, setDueCount] = useState(0);
    const [pastCount, setPastCount] = useState(0);
    const [isDisabled, setDisabled] = useState(false);
    const [isHidden, setHidden] = useState(true);
    const [loading, setLoading] = useState({
        uploading: false,
        enabling: false,
        data: true,
    })

    const handleInput = (e) => {
        setInstForm({ ...instForm, [e.target.name]: e.target.value });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        await axios
            .post('/instrument/update', instForm)
            .then((res) => {
                if (res.status === 200) {
                    message.success(res.data.msg)
                } else if (res.status === 202) {
                    message.warning(res.data.msg)
                    setLoading({ ...loading, uploading: false })
                } else {
                    message.error('Data not updated')
                    setLoading({ ...loading, uploading: false })
                }
            })
            .catch((e) => {
                setLoading({ ...loading, uploading: false })
                console.log(e);
            });
        setDisabled(false);
        setHidden(true);
        setInstForm({
            instr_name: '',
            instr_model: '',
            asset_id: '',
            cal_due_date: '',
        });
    };

    const handleEdit = (e, rowId, colId) => {
        // console.log(rowId);
        // console.log(colId);
        let instAll = inst.find((a) => {
            return a._id === rowId;
        });
        let instInd = instAll.instrs.find((a) => {
            return a._id === colId;
        });
        setInstForm({
            rowId: rowId,
            colId: colId,
            instr_name: instAll.instr_name,
            instr_model: instInd.instr_model,
            asset_id: instInd.asset_id,
            cal_due_date: moment(instInd.cal_due_date)
                .utc()
                .format('YYYY-MM-DD'),
        });
        setDisabled(true);
        setHidden(false);
    };

    const handleCancel = () => {
        setInstForm({
            instr_name: '',
            instr_model: '',
            asset_id: '',
            cal_due_date: '',
        });
        setFormValidationError({});
        setDisabled(false);
        setHidden(true);
        setSearch('');
    };

    const handleEnable = async (e, rowId, colId) => {
        if (rowId !== '' && colId !== '') {
            await axios
                .post('/instrument/enable', {
                    rowId,
                    colId,
                    enable: e.target.checked,
                })
                .then((res) => {
                    if (res.status !== 200) {
                        setLoading({ ...loading, enabling: false })
                    }
                })
                .catch((e) => {
                    setLoading({ ...loading, enabling: false })
                    console.log(e);
                });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formError = formValidator(instForm, {
            instr_name: 'Instrument Name Required',
            instr_model: 'Instrument Model Required',
            asset_id: 'Asset Id Required',
            cal_due_date: 'Date Required',
        });
        setFormValidationError(formError);
        console.log(formError);
        if (Object.keys(formError).length === 0) {
            await axios
                .post('/instrument/add', instForm)
                .then((res) => {
                    if (res.status === 200) {
                        message.success(res.data.msg)
                        setInstForm({
                            instr_name: '',
                            instr_model: '',
                            asset_id: '',
                            cal_due_date: '',
                        });
                    } else if (res.status === 202) {
                        message.warning(res.data.msg)
                        setLoading({ ...loading, uploading: false })
                        setInstForm({
                            instr_name: '',
                            instr_model: '',
                            asset_id: '',
                            cal_due_date: '',
                        });
                    } else {
                        message.error('Data not saved')
                        setLoading({ ...loading, uploading: false })
                    }
                })
                .catch((e) => {
                    setLoading({ ...loading, uploading: false })
                    console.log(e);
                });
        }
    };

    const conditionalRowStyles = [
        // {
        //     when: row => row.enable,
        //     style: {
        //         backgroundColor: "orange",
        //         color: 'black',
        //     },
        // },
        // {
        //     when: row => findPath(row.stages, "article#"),
        //     style: row => ({
        //         backgroundColor: "orangered",
        //         color: 'black',
        //     }),
        // },
        // {
        //     when: row => row.toggleSelected,
        //     style: {
        //         backgroundColor: "limegreen",
        //         color: 'black',
        //     },
        // },
    ];

    const columns = [
        {
            name: 'Instrument Name',
            cell: (row) => row.instr_name,
        },

        {
            name: 'Instrument Model',
            selector: (row) =>
                row.instrs.map((inst) => {
                    return (
                        <tr >
                            <td className="p-3 px-0" >{inst.instr_model}</td>
                        </tr>
                    );
                }),
            center: true,
        },
        {
            name: 'Asset Id',
            selector: (row) =>
                row.instrs.map((inst) => {
                    return (
                        <tr>
                            <td className="p-3 px-0">{inst.asset_id}</td>
                        </tr>
                    );
                }),
            center: true,
        },
        {
            name: 'Calibration Due Date',
            selector: (row) =>
                row.instrs.map((inst) => {
                    return (
                        <tr>
                            <td className="p-3 px-0">
                                {moment(inst.cal_due_date)
                                    .utc()
                                    .format('DD-MM-YYYY')}
                            </td>
                            {/* <td>{inst.cal_due_date}</td> */}
                        </tr>
                    );
                }),
            center: true,
        },
        {
            name: 'In-Use',
            selector: (row) =>
                row.instrs.map((inst) => {
                    return (
                        <tr>
                            <td
                                className="m-0 px-0"
                                style={{ padding: '12px' }}
                            >
                                <div className="form-check form-switch px-5">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        checked={inst.enable}
                                        onChange={(e) => { setLoading({ ...loading, enabling: true }); handleEnable(e, row._id, inst._id); }}
                                        disabled={loading.enabling}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </td>
                        </tr>
                    );
                }),
            width: '100px',
            omit: hidden,
            center: true,
        },
        {
            name: 'Edit',
            selector: (row) =>
                row.instrs.map((inst) => {
                    return (
                        <td className="d-flex p-2 px-0">
                            <Button
                                type="primary"
                                disabled={isDisabled}
                                onClick={(e) =>
                                    handleEdit(e, row._id, inst._id)
                                }
                            >
                                Edit
                            </Button>
                        </td>
                    );
                }),
            width: '110px',
            omit: hidden,
            center: true,
        },
        {
            name: 'Cal Cert',
            selector: (row) =>
                row.instrs.map((inst) => {
                    return (
                        <td className="d-flex p-2 px-0">
                            <ImageUpload
                                item2={inst}
                                item1={row}
                                rowId={row._id}
                                colId={inst._id}
                                hidden={hidden}
                            />
                        </td>
                    );
                }),
            width: '120px',
            right: true,
        },
        // {
        //     name: 'enable',
        //     selector: row => <div className="form-check form-switch">
        //         <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={row.enable} onChange={(e) => handleEnable(e, row._id)} />
        //     </div>

        // },
        // {
        //     name: 'Date',
        //     cell: row => <td>{moment(row.date).utc().format("DD-MM-YYYY")}</td>
        // },
    ];

    const openNotificationWithIcon = (type) => {
        if (type === 'warning') {
            notification[type]({
                message: (
                    <h5>
                        <b>Upcoming</b>
                    </h5>
                ),
                description: dueItems['due']?.map((item) => {
                    return (
                        <div>
                            <span
                                style={{
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                }}
                                onClick={(e) => setSearch(e.target.innerText)}
                            >
                                {item.instr_name}
                            </span>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >{` -> ${item.asset_id}`}</span>
                            <span>{`: is due in ${item.days} days`}</span>
                        </div>
                    );
                }),
                style: {
                    color: 'rgba(0, 0, 0, 0.65)',
                    border: '1px solid #ffe58f',
                    backgroundColor: '#fffbe6',
                    width: 'max-content',
                    fontSize: '1rem',
                },
            });
        } else if (type === 'error') {
            notification[type]({
                message: (
                    <h5>
                        <b>Past Due</b>
                    </h5>
                ),
                description: dueItems['past']?.map((item) => {
                    return (
                        <div>
                            <span
                                style={{
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                }}
                                onClick={(e) => setSearch(e.target.innerText)}
                            >
                                {item.instr_name}
                            </span>
                            <span>{` -> ${item.asset_id}: was due on ${moment(
                                item.cal_due_date
                            )
                                .utc()
                                .format('DD-MM-YYYY')}`}</span>
                            <br />
                        </div>
                    );
                }),
                style: {
                    color: 'rgba(0, 0, 0, 0.65)',
                    border: '1px solid #ffa39e',
                    backgroundColor: '#fff1f0',
                    width: 'max-content',
                    fontSize: '1rem',
                },
                duration: 0,
            });
        }
    };

    useEffect(() => {
        (async function () {
            await axios.get('/instrument/all').then((res) => {
                setInst(res.data.instruments);
                setFilteredInst(res.data.instruments);
                if (!res.data.instruments?.length > 0) {
                    setLoading({ ...loading, data: false })
                }
            }).catch((e) => {
                console.log(e);
            });
        })()
    }, []);

    useEffect(() => {
        socket.on('instrument', (instruments) => {
            setInst(instruments);
            setFilteredInst(instruments);
            setLoading({
                uploading: false,
                enabling: false
            });
        })
    }, [socket])

    useEffect(() => {
        const res = inst.filter((item) => {
            // console.log(item)
            return String(item.instr_name)
                .toLowerCase()
                .match(String(search).toLocaleLowerCase());
        });
        setFilteredInst(res);
    }, [search]);

    useEffect(() => {
        let d = new Date();
        let dueNum = 0;
        let pastNum = 0;
        let due = [];
        let past = [];
        setDueCount(0);
        setPastCount(0);
        setDueItems({});
        inst?.map((item) => {
            item.instrs.map((instrsItem) => {
                if (instrsItem.enable === true) {
                    let d1 = new Date(instrsItem.cal_due_date);
                    let d2 = d1;
                    let diff = d2.getTime() - d.getTime();
                    var dayDiff = Math.ceil(diff / (1000 * 60 * 60 * 24));
                    if (Math.sign(dayDiff) === -1) {
                        pastNum = pastNum + 1;
                        instrsItem.instr_name = item.instr_name;
                        instrsItem.days = -1;
                        past.push(instrsItem);
                    }
                    if (Math.sign(dayDiff) === 1 || Math.sign(dayDiff) === 0) {
                        if (dayDiff <= 30) {
                            dueNum = dueNum + 1;
                            instrsItem.instr_name = item.instr_name;
                            instrsItem.days = dayDiff;
                            due.push(instrsItem);
                            // console.log(num)
                        }
                    }
                }
            });
        });
        setDueCount(dueNum);
        setPastCount(pastNum);
        setDueItems({ due: [...due], past: [...past] });
    }, [inst]);

    return (
        <div className="mainHeight w-100">
            <Masternav name="instrument" />
            <div>
                <div className="d-flex justify-content-between pt-3 pb-1 px-0 w-100">
                    <div className="searchBox">
                        <input
                            className="form-control"
                            placeholder="Search Instrument Name"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <div className="d-flex gap-3 px-3">
                        <Badge count={dueCount}>
                            <Button
                                shape="circle"
                                type="secondary"
                                style={{ color: 'orange' }}
                                icon={<BellFilled />}
                                size="large"
                                onClick={() =>
                                    openNotificationWithIcon('warning')
                                }
                                disabled={
                                    dueItems['due']?.length === 0 ? true : false
                                }
                            ></Button>
                        </Badge>
                        <Badge count={pastCount}>
                            <Button
                                shape="circle"
                                type="secondary"
                                style={{ color: 'red' }}
                                icon={<BellFilled />}
                                size="large"
                                onClick={() =>
                                    openNotificationWithIcon('error')
                                }
                                disabled={
                                    dueItems['past']?.length === 0
                                        ? true
                                        : false
                                }
                            ></Button>
                        </Badge>
                    </div>
                </div>
                <div className='card'>
                    <DataTable
                        columns={columns}
                        data={filteredinst}
                        pagination
                        fixedHeader
                        paginationDefaultPage={1}
                        fixedHeaderScrollHeight="410px"
                        highlightOnHover
                        conditionalRowStyles={conditionalRowStyles}
                        // actions={<input className='form-control' placeholder='Search Instrument Name' value={search} onChange={(e) => setSearch(e.target.value)} />}
                        noDataComponent={loading.data ? <div className='p-3'><Spin /></div> : <div className='p-3'>No Data</div>}
                    />
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading.uploading || loading.enabling}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>                <hr />
                <div className='card'>
                    <section className="masterbody" hidden={hidden}>
                        <FloatingLabel label="Instrument Name">
                            <Form.Control
                                name="instr_name"
                                type="text"
                                onInput={handleInput}
                                value={instForm?.instr_name}
                                placeholder="none"
                                autoComplete="off"
                                disabled={isDisabled}
                            />
                            <p className="text-danger">
                                {formValidationError['instr_name']}
                            </p>
                        </FloatingLabel>
                        <FloatingLabel label="Instrument Model">
                            <Form.Control
                                name="instr_model"
                                type="text"
                                onInput={handleInput}
                                value={instForm?.instr_model}
                                placeholder="none"
                                autoComplete="off"
                                disabled={isDisabled}
                            />
                            <p className="text-danger">
                                {formValidationError['instr_model']}
                            </p>
                        </FloatingLabel>
                        <FloatingLabel label="Asset Id">
                            <Form.Control
                                name="asset_id"
                                type="text"
                                onInput={handleInput}
                                value={instForm?.asset_id}
                                placeholder="none"
                                autoComplete="off"
                                disabled={isDisabled}
                            />
                            <p className="text-danger">
                                {formValidationError['asset_id']}
                            </p>
                        </FloatingLabel>
                        <FloatingLabel label="Calibration Due Date">
                            <Form.Control
                                name="cal_due_date"
                                type="date"
                                onInput={handleInput}
                                value={instForm?.cal_due_date}
                                placeholder="none"
                                autoComplete="off"
                            />
                            <p className="text-danger">
                                {formValidationError['cal_due_date']}
                            </p>
                        </FloatingLabel>
                    </section>
                    <section className="masterbtn" hidden={hidden}>
                        <div>
                            <Button
                                type="primary"
                                hidden={!isHidden}
                                onClick={(e) => { setLoading({ ...loading, uploading: true }); handleSubmit(e) }}
                                loading={loading.uploading}
                            >
                                {loading.uploading ? 'Adding' : 'Add'}
                            </Button>
                            <Button
                                variant="outline-primary"
                                style={{
                                    backgroundColor: '#1FB556',
                                    color: 'white',
                                }}
                                hidden={isHidden}
                                onClick={(e) => { setLoading({ ...loading, uploading: true }); handleUpdate(e); }}
                                loading={loading.uploading}
                            >
                                {loading.uploading ? 'Updating' : 'Update'}
                            </Button>
                            <Button type="primary" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};
