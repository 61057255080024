import { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import snsLogo from '../../img/sns-logo.png'
import { useGetUserDetails } from "../../hooks/useGetUserDetails";
import { userLogout } from '../../features/users'
import { useDispatch } from 'react-redux'

import Login from "../Auth/LoginAndRegister/Login/Login";
import { Register } from "../Auth/LoginAndRegister/Register/Register";
import WithPermission from "../Auth/Permissions/WithPermission";

import { AppBar, Toolbar, Typography, Box, Menu, MenuItem, Button, Tooltip, IconButton, Avatar, Container } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { socket } from "../../services/socket";

export const Header = ({ name }) => {
    const { isUser, user } = useGetUserDetails();
    const navigate = useNavigate();
    let location = useLocation();
    const dispatcher = useDispatch();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const pages = [
        {
            role: "Home",
            link: "/",
            name: 'Home',
        },
        {
            role: "PPO",
            link: "/mesHome/ppoDashboard",
            name: 'PPO',
        },
        {
            role: "WO",
            link: "/mesHome/woDashboard",
            name: 'WO',
        },
        {
            role: "Master",
            link: "/mesHome/master",
            name: 'Master',
        },
        {
            role: "Incoming Quality",
            link: "/mesHome/quality",
            name: 'Quality',
        },
        {
            role: "Reports",
            link: "/mesHome/reports",
            name: 'Reports',
        },
        {
            role: "Plots",
            link: "/mesHome/plots",
            name: 'Plots',
        },
        {
            role: "Repository",
            link: "/mesHome/bucket",
            name: 'Repository',
        },
        {
            role: "Acm",
            link: "/mesHome/roles",
            name: 'Acl',
        },
    ]

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    // console.log(user.access)
    let masterStyle
    let reportStyle
    let plotsStyle
    if (window.location.pathname === '/mesHome/master'
        || window.location.pathname === '/mesHome/master/article'
        || window.location.pathname === '/mesHome/master/stages'
        || window.location.pathname === '/mesHome/master/uom'
        || window.location.pathname === '/mesHome/master/customer'
        || window.location.pathname === '/mesHome/master/supplier'
        || window.location.pathname === '/mesHome/master/instrument'
        || window.location.pathname === '/mesHome/master/testplan'
        || window.location.pathname === '/mesHome/master/bom'
    ) {
        masterStyle = { 'background': '#0C2D48', 'color': 'white' }
    } else {
        masterStyle = { 'color': 'white' }
    }
    if (window.location.pathname === '/mesHome/reports'
        || window.location.pathname === '/mesHome/reports/process'
        || window.location.pathname === '/mesHome/reports/quality'
    ) {
        reportStyle = { 'background': '#0C2D48', 'color': 'white' }
    } else {
        reportStyle = { 'color': 'white' }
    }
    if (window.location.pathname === '/mesHome/plots'
        || window.location.pathname === '/mesHome/plots/inProgress'
        || window.location.pathname === '/mesHome/plots/short_rej'
        || window.location.pathname === '/mesHome/plots/ytd_mtd'
    ) {
        plotsStyle = { 'background': '#0C2D48', 'color': 'white' }
    } else {
        plotsStyle = { 'color': 'white' }
    }

    const stringToColor = (string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i++) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const stringAvatar = (name) => {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0].toUpperCase()}`,
        };
    }

    useEffect(() => {
        socket.on('msg-recieve', (msg) => { dispatcher(userLogout({})); navigate('/'); console.log('logout') })
    }, [socket])

    return (
        // <Navbar
        //     expand="lg"
        //     bg="secondary"
        //     variant="dark"
        //     className="px-5 sticky-top"
        //     style={{ zIndex: "1000" }}
        //     collapseOnSelect
        // >
        //     <Navbar.Brand>
        //         {/* <Link to={(location.pathname === '/' && isUser) ? "/mesHome" : '/'} className="text-white text-decoration-none"> */}
        //         <Link to={'/'} className="text-white text-decoration-none">
        //             <img src={snsLogo} alt="" srcSet="" />
        //         </Link>
        //     </Navbar.Brand>
        //     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        //     <Navbar.Collapse id="responsive-navbar-nav" collapseOnSelect>

        //         {isUser ? (
        //             <Nav className="me-auto my-2 my-lg-0 responsive-nav d-flex justify-content-center"
        //                 style={{ maxHeight: '90vh', width: "100%" }}
        //                 navbarScroll
        //             >
        //                 <Nav.Link ><Link to="/" style={window.location.pathname === '/' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '#6c757d' }} className='btn btn-secondary '>Home</Link></Nav.Link>
        //                 <WithPermission roleRequired="PPO">
        //                     <Nav.Link ><Link to="/mesHome/ppoDashboard" style={window.location.pathname === '/mesHome/ppoDashboard' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '#6c757d' }} className='btn btn-secondary'>PPO</Link></Nav.Link>
        //                 </WithPermission>
        //                 <WithPermission roleRequired="WO">
        //                     <Nav.Link ><Link to="/mesHome/woDashboard" style={window.location.pathname === '/mesHome/woDashboard' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '#6c757d' }} className='btn btn-secondary'>WO</Link></Nav.Link>
        //                 </WithPermission>
        //                 {
        //                     Object.keys(user?.access)?.includes("Article Master" || "Stages Master" || "UOM Master" || "Customer Master" || "Supplier Master" || "Instrument Master" || "Test Plan Master" || "Bom Master") ?
        //                         user.access["Article Master"] > 0 || user.access["Stages Master"] > 0 || user.access["UOM Master"] > 0 || user.access["Customer Master"] > 0 || user.access["Supplier Master"] > 0 || user.access["Instrument Master"] > 0 || user.access["Test Plan Master"] > 0 || user.access["Bom Master"] > 0 ?
        //                             <Nav.Link ><Link to="/mesHome/master" style={masterStyle} className='btn btn-secondary'>Master</Link></Nav.Link> :
        //                             null
        //                         :
        //                         null
        //                 }
        //                 <WithPermission roleRequired="Incoming Quality">
        //                     <Nav.Link ><Link to="/mesHome/quality" style={window.location.pathname === '/mesHome/quality' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '#6c757d' }} className='btn btn-secondary'>Quality</Link></Nav.Link>
        //                 </WithPermission>
        //                 {Object.keys(user?.access)?.includes("Process Reports" || "Quality Reports") ?
        //                     user.access["Process Reports"] > 0 || user.access["Quality Reports"] > 0 ?
        //                         <Nav.Link ><Link to="/mesHome/reports" style={reportStyle} className='btn btn-secondary'>Reports</Link></Nav.Link> :
        //                         null :
        //                     null
        //                 }
        //                 <WithPermission roleRequired="Plots">
        //                     <Nav.Link ><Link to="/mesHome/plots" style={window.location.pathname === '/mesHome/plots' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '#6c757d' }} className='btn btn-secondary'>Plots</Link></Nav.Link>
        //                 </WithPermission>
        //                 <Nav.Link ><Link to="/mesHome/bucket" style={window.location.pathname === '/mesHome/bucket' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '#6c757d' }} className='btn btn-secondary'>Repository</Link></Nav.Link>
        //                 <WithPermission roleRequired="Acm">
        //                     <Nav.Link ><Link to="/mesHome/roles" style={window.location.pathname === '/mesHome/roles' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '#6c757d' }} className='btn btn-secondary'>ACM</Link></Nav.Link>
        //                 </WithPermission>
        //                 {/* <Nav.Link ><Link to="/mesHome/verification" style={name === 'verification' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '#6c757d' }} className='btn btn-secondary'>Verification</Link></Nav.Link> */}
        //             </Nav>

        //         ) : null}

        //         {isUser ? (
        //             <Nav className="ms-auto">
        //                 <span className="text-light">{user?.email}</span> &nbsp;&nbsp;
        //                 <input type="button" value="Logout" onClick={() => { navigate("/"); dispatcher(userLogout({})) }} />
        //             </Nav>
        //         ) : (
        //             <Nav className="ms-auto d-flex gap-2">
        //                 <Login />
        //                 <Register />
        //             </Nav>
        //         )}
        //     </Navbar.Collapse>
        // </Navbar>
        <AppBar position="sticky" sx={{ backgroundColor: '#6c757d', zIndex: '1000' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="span"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'none', lg: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <Link to={'/'} className="text-white text-decoration-none navbar-brand">
                            <img src={snsLogo} alt="" srcSet="" />
                        </Link>
                    </Typography>
                    {
                        user ?
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'block', lg: 'none' },
                                    }}
                                >
                                    {pages.map((page) => (
                                        page?.role === "Master"
                                            ?
                                            Object.keys(user?.access)?.includes("Article Master" || "Stages Master" || "UOM Master" || "Customer Master" || "Supplier Master" || "Instrument Master" || "Test Plan Master" || "Bom Master")
                                                ?
                                                user.access["Article Master"] > 0 || user.access["Stages Master"] > 0 || user.access["UOM Master"] > 0 || user.access["Customer Master"] > 0 || user.access["Supplier Master"] > 0 || user.access["Instrument Master"] > 0 || user.access["Test Plan Master"] > 0 || user.access["Bom Master"] > 0
                                                    ?
                                                    <MenuItem sx={masterStyle.background ? masterStyle : null} key={page.name} onClick={() => { handleCloseNavMenu(); navigate(page.link) }}>
                                                        <Typography textAlign="center">
                                                            {page.name}
                                                        </Typography>
                                                    </MenuItem>
                                                    :
                                                    null
                                                :
                                                null
                                            :
                                            page?.role === "Reports"
                                                ?
                                                Object.keys(user?.access)?.includes("Process Reports" || "Quality Reports")
                                                    ?
                                                    user.access["Process Reports"] > 0 || user.access["Quality Reports"] > 0
                                                        ?
                                                        <MenuItem sx={reportStyle.background ? reportStyle : null} key={page.name} onClick={() => { handleCloseNavMenu(); navigate(page.link) }}>
                                                            <Typography textAlign="center">
                                                                {page.name}
                                                            </Typography>
                                                        </MenuItem>
                                                        :
                                                        null
                                                    :
                                                    null
                                                :
                                                page?.role === "Plots"
                                                    ?
                                                    Object.keys(user?.access)?.includes("WO In-Progress" || "WO Shortage/Rejection" || "YTD/MTD")
                                                        ?
                                                        user.access["WO In-Progress"] > 0 || user.access["WO Shortage/Rejection"] > 0 || user.access["YTD/MTD"] > 0
                                                            ?
                                                            <MenuItem sx={plotsStyle.background ? plotsStyle : null} key={page.name} onClick={() => { handleCloseNavMenu(); navigate(page.link) }}>
                                                                <Typography textAlign="center">
                                                                    {page.name}
                                                                </Typography>
                                                            </MenuItem>
                                                            :
                                                            null
                                                        :
                                                        null
                                                    :
                                                    page?.role === "Home"
                                                        ?
                                                        <MenuItem sx={window.location.pathname === page.link ? { 'background': '#0C2D48', 'color': 'white' } : null} key={page.name} onClick={() => { handleCloseNavMenu(); navigate(page.link) }}>
                                                            <Typography textAlign="center">
                                                                {page.name}
                                                            </Typography>
                                                        </MenuItem>
                                                        :
                                                        <WithPermission roleRequired={page.role} key={page.name}>
                                                            <MenuItem sx={window.location.pathname === page.link ? { 'background': '#0C2D48', 'color': 'white' } : null} key={page.name} onClick={() => { handleCloseNavMenu(); navigate(page.link) }}>
                                                                <Typography textAlign="center">
                                                                    {page.name}
                                                                </Typography>
                                                            </MenuItem>
                                                        </WithPermission>
                                    ))}
                                </Menu>
                            </Box>
                            :
                            null
                    }
                    <Typography
                        variant="h5"
                        noWrap
                        component="span"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'flex', lg: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <Link to={'/'} className="text-white text-decoration-none navbar-brand">
                            <img src={snsLogo} alt="" srcSet="" />
                        </Link>
                    </Typography>
                    {
                        user ?
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none', lg: 'flex' } }}>
                                {pages.map((page) => (
                                    page?.role === "Master"
                                        ?
                                        Object.keys(user?.access)?.includes("Article Master" || "Stages Master" || "UOM Master" || "Customer Master" || "Supplier Master" || "Instrument Master" || "Test Plan Master" || "Bom Master")
                                            ?
                                            user.access["Article Master"] > 0 || user.access["Stages Master"] > 0 || user.access["UOM Master"] > 0 || user.access["Customer Master"] > 0 || user.access["Supplier Master"] > 0 || user.access["Instrument Master"] > 0 || user.access["Test Plan Master"] > 0 || user.access["Bom Master"] > 0
                                                ?
                                                <Button
                                                    className="HeaderNavBtn"
                                                    key={page.name}
                                                    onClick={() => navigate(page.link)}
                                                    // sx={{ my: 2, color: 'white', display: 'block' }}
                                                    sx={masterStyle}
                                                >
                                                    {page.name}
                                                </Button>
                                                :
                                                null
                                            :
                                            null
                                        :
                                        page?.role === "Reports"
                                            ?
                                            Object.keys(user?.access)?.includes("Process Reports" || "Quality Reports")
                                                ?
                                                user.access["Process Reports"] > 0 || user.access["Quality Reports"] > 0
                                                    ?
                                                    <Button
                                                        className="HeaderNavBtn"
                                                        key={page.name}
                                                        onClick={() => navigate(page.link)}
                                                        // sx={{ my: 2, color: 'white', display: 'block' }}
                                                        sx={reportStyle}
                                                    >
                                                        {page.name}
                                                    </Button>
                                                    :
                                                    null
                                                :
                                                null
                                            :
                                            page?.role === "Plots"
                                                ?
                                                Object.keys(user?.access)?.includes("WO In-Progress" || "WO Shortage/Rejection" || "YTD/MTD")
                                                    ?
                                                    user.access["WO In-Progress"] > 0 || user.access["WO Shortage/Rejection"] > 0 || user.access["YTD/MTD"] > 0
                                                        ?
                                                        <Button
                                                            className="HeaderNavBtn"
                                                            key={page.name}
                                                            onClick={() => navigate(page.link)}
                                                            // sx={{ my: 2, color: 'white', display: 'block' }}
                                                            sx={plotsStyle}
                                                        >
                                                            {page.name}
                                                        </Button>
                                                        :
                                                        null
                                                    :
                                                    null
                                                :
                                                page?.role === "Home"
                                                    ?
                                                    <Button
                                                        className="HeaderNavBtn"
                                                        key={page.name}
                                                        onClick={() => navigate(page.link)}
                                                        // sx={{ my: 2, color: 'white', display: 'block' }}
                                                        sx={window.location.pathname === page.link ? { 'background': '#0C2D48', 'color': 'white' } : { 'color': 'white' }}
                                                    >
                                                        {page.name}
                                                    </Button>
                                                    :
                                                    <WithPermission roleRequired={page.role} key={page.name}>
                                                        <Button
                                                            className="HeaderNavBtn"
                                                            key={page.name}
                                                            onClick={() => navigate(page.link)}
                                                            // sx={{ my: 2, color: 'white', display: 'block' }}
                                                            sx={window.location.pathname === page.link ? { 'background': '#0C2D48', 'color': 'white' } : { 'color': 'white' }}
                                                        >
                                                            {page.name}
                                                        </Button>
                                                    </WithPermission>
                                ))}
                            </Box>
                            :
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                            </Box>
                    }

                    {user ?
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    {/* <Avatar alt="Remy Sharp" src="" /> */}
                                    <Avatar {...stringAvatar(user?.email)} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={() => handleCloseUserMenu()}
                                disableScrollLock={true}
                            >
                                <MenuItem key="1" onClick={() => handleCloseUserMenu()}>
                                    <Typography textAlign="center" >
                                        <span className="text-dark">{user?.email}</span>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => { navigate("/"); dispatcher(userLogout({})) }}
                                            sx={{ ml: 2 }}
                                        >
                                            Log Out
                                        </Button>
                                        {/* <Button value="Logout" onClick={() => { navigate("/"); dispatcher(userLogout({})) }} /> */}
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                        :
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Login / Register">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="Remy Sharp" src="" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={() => handleCloseUserMenu()}
                                disableScrollLock={true}
                            >

                                <MenuItem key="1" onClick={() => handleCloseUserMenu()}>
                                    <Typography textAlign="center" sx={{ width: '100%' }}>
                                        <Login />
                                    </Typography>
                                </MenuItem>
                                <MenuItem key="2" onClick={() => handleCloseUserMenu()}>
                                    <Typography textAlign="center">
                                        <Register />
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    }
                </Toolbar>
            </Container>
        </AppBar>
    );
};
