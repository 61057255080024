import React, { useEffect, useState } from 'react'
import { Masternav } from '../../helper/MasterNav'
import axios from '../../../../../api/axios';
import DataTable from 'react-data-table-component';
import { Button, message, Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { FloatingLabel, Form } from 'react-bootstrap';
import { Backdrop, CircularProgress } from '@mui/material';
import { socket } from '../../../../../services/socket';

message.config({ top: '40%' })

export const Bom = ({ hidden }) => {
    const [boms, setBoms] = useState({
        boms: [],
        filteredBoms: []
    })
    const [search, setSearch] = useState('')
    const [formValidationError, setFormValidationError] = useState({});
    const [bomForm, setBomForm] = useState({
        fgData: { fgArtNum: '', fgDescription: '', fgQty: '', fgUOM: '' },
        bomData: []
    })
    const [artConfirm, setArtConfirm] = useState({})
    const [loading, setLoading] = useState({
        uploading: false
    })

    const disp = () => {
        axios.get("/bom/all").then((res) => {
            setBoms({
                boms: res.data.boms,
                filteredBoms: res.data.boms
            })
        }).catch((e) => {
            console.log(e)
        })
    }

    // const treeData = boms.map((item, i1) => {
    //     console.log(item.bom)
    //     return (
    //         {
    //             title: `FG - ${item.fgArtNum}`,
    //             children: [
    //                 {
    //                     title: "Info",
    //                     children: [
    //                         {
    //                             title: `Description : ${item.fgDescription}`,
    //                         },
    //                         {
    //                             title: `Uom : ${item.fgUOM}`,
    //                         },
    //                         {
    //                             title: `Uom : ${item.fgQty}`,
    //                         },
    //                     ]
    //                 },
    //                 {
    //                     title: 'Sub Bom',
    //                     children: Object.keys(item.bom).map((key, i2) => {
    //                         return (
    //                             {
    //                                 title: `SFG - ${key}`,
    //                                 children: [
    //                                     {
    //                                         title: 'Info',
    //                                         children: Object.keys(item.bom[key].bom).map((rmkey) => {
    //                                             let name = rmkey
    //                                             if (rmkey === "rmdescription") {
    //                                                 name = "Description"
    //                                             } else if (rmkey === "rmuom") {
    //                                                 name = "Uom"
    //                                             } else {
    //                                                 name = "Qty"
    //                                             }
    //                                             if (rmkey === "rmQty") {
    //                                                 return (
    //                                                     {
    //                                                         title: `${name} : ${(item.bom[key].bom[rmkey])}`,
    //                                                     }
    //                                                 )
    //                                             } else {
    //                                                 return (
    //                                                     {
    //                                                         title: `${name} : ${item.bom[key].bom[rmkey]}`,
    //                                                     }
    //                                                 )
    //                                             }
    //                                         }),
    //                                     },
    //                                     {
    //                                         title: 'Sub Bom',
    //                                         children: Object.keys(item.bom).map((key, i3) => {
    //                                             return (
    //                                                 {
    //                                                     title: `${key}`,
    //                                                     children: [
    //                                                         {
    //                                                             title: 'Info',
    //                                                             children: Object.keys(item.bom[key].bom).map((rmkey) => {
    //                                                                 let name = rmkey
    //                                                                 if (rmkey === "rmdescription") {
    //                                                                     name = "Description"
    //                                                                 } else if (rmkey === "rmuom") {
    //                                                                     name = "Uom"
    //                                                                 } else {
    //                                                                     name = "Qty"
    //                                                                 }
    //                                                                 if (rmkey === "rmQty") {
    //                                                                     return (
    //                                                                         {
    //                                                                             title: `${name} : ${(item.bom[key].bom[rmkey])}`,
    //                                                                         }
    //                                                                     )
    //                                                                 } else {
    //                                                                     return (
    //                                                                         {
    //                                                                             title: `${name} : ${item.bom[key].bom[rmkey]} `,
    //                                                                         }
    //                                                                     )
    //                                                                 }
    //                                                             }),
    //                                                         },
    //                                                         {
    //                                                             title: 'Sub Bom',
    //                                                             children: Object.keys(item.bom).map((key, i4) => {
    //                                                                 return (
    //                                                                     {
    //                                                                         title: key,
    //                                                                         children: [
    //                                                                             {
    //                                                                                 title: 'Info',
    //                                                                                 children: Object.keys(item.bom[key].bom).map((rkey) => {
    //                                                                                     let name = rkey
    //                                                                                     if (rkey === "rmdescription") {
    //                                                                                         name = "Description"
    //                                                                                     } else if (rkey === "rmuom") {
    //                                                                                         name = "Uom"
    //                                                                                     } else {
    //                                                                                         name = "Qty"
    //                                                                                     }
    //                                                                                     if (rkey === "rmQty") {
    //                                                                                         return (
    //                                                                                             {
    //                                                                                                 title: `${name} : ${(item.bom[key].bom[rkey])} `,
    //                                                                                             }
    //                                                                                         )
    //                                                                                     } else {
    //                                                                                         return (
    //                                                                                             {
    //                                                                                                 title: `${name} : ${item.bom[key].bom[rkey]} `,
    //                                                                                             }
    //                                                                                         )
    //                                                                                     }
    //                                                                                 }),
    //                                                                             },
    //                                                                         ]
    //                                                                     }
    //                                                                 )
    //                                                             })
    //                                                         },
    //                                                     ]
    //                                                 }
    //                                             )
    //                                         })
    //                                     },
    //                                 ]
    //                             }
    //                         )
    //                     })
    //                 },
    //             ]
    //         }
    //     )
    // })

    const treeData = (row) => {
        if (Object.keys(row?.bom)?.length > 0) {
            return (
                Object.keys(row?.bom)?.map((key, i) => {
                    let data = {}
                    data.title = (
                        // <table className='table' style={{ backgroundColor: '' }}>
                        //     <tbody>
                        //         {/* <tr>
                        //             <th>{key}</th>
                        //             <td>Description</td>
                        //             <td>Qty</td>
                        //             <td>Uom</td>
                        //         </tr> */}
                        //         <tr>
                        //             <th>{key}</th>
                        //             <td>{row.bom[key].bom.rmdescription}</td>
                        //             <td>{row.bom[key].bom.rmQty}</td>
                        //             <td>{row.bom[key].bom.rmuom}</td>
                        //         </tr>
                        //     </tbody>
                        // </table>
                        <div>
                            <label className='me-3'><b>{key}:</b></label>
                            <label className='me-3'> {row?.bom[key]?.bom?.rmdescription}</label>
                            <label className='me-3'> {row?.bom[key]?.bom?.rmQty}</label>
                            <label className='me-3'> {row?.bom[key]?.bom?.rmuom}.</label>
                        </div>
                    )
                    let isBom = []
                    isBom = boms.boms.filter((item) => {
                        // console.log(key, `=====>${item.fgArtNum}`)
                        let val = false
                        if (key === item.fgArtNum) {
                            return val = true
                        }
                        return val
                    })
                    if (isBom?.length > 0) {
                        // console.log(row, isBom[0])
                        data.children = treeData(isBom?.[0])
                    }
                    // let isKey = boms.filter((item) => {
                    //     return String(key.toLowerCase()).match(String(item.fgArtNum.toLocaleLowerCase()))
                    // })
                    // console.log(isKey)
                    // {
                    //     title: (
                    //         <table className='table' style={{ backgroundColor: '' }}>
                    //             <tbody>
                    //                 <tr>
                    //                     <th>{key}</th>
                    //                     <td>Description</td>
                    //                     <td>Qty</td>
                    //                     <td>Uom</td>
                    //                 </tr>
                    //                 <tr>
                    //                     <th></th>
                    //                     <td>{row.bom[key].bom.rmdescription}</td>
                    //                     <td>{row.bom[key].bom.rmQty}</td>
                    //                     <td>{row.bom[key].bom.rmuom}</td>
                    //                 </tr>
                    //             </tbody>
                    //         </table>
                    //     ),
                    //     key: i,

                    // }
                    return data
                })
                // [
                //     {
                //         title: row.fgArtNum,
                //     },
                //     {
                //         title: row.fgArtNum,
                //     },
                // ]
            )
        }
    }

    const columns = [
        {
            name: 'Artnum',
            cell: (row) => row.fgArtNum,
        },
        {
            name: 'Description',
            cell: (row) => row.fgDescription,
        },
        {
            name: 'Quantity',
            cell: (row) => row.fgQty,
        },
        {
            name: 'Uom',
            cell: (row) => row.fgUOM,
        },
        {
            name: 'Edit',
            cell: (row) => (
                <Button type='primary' onClick={() => handleEdit(row)}>Edit</Button>
            ),
        },
        // {
        //     name: 'Artnum',
        //     cell: (row) => <Tree
        //         showLine={
        //             {
        //                 showLeafIcon: false
        //             }
        //         }
        //         switcherIcon={<DownOutlined />}
        //         treeData={[treeData(row)[0]]}
        //     />
        // },
    ]

    const expandedComponent = ({ data }) => {
        if (data.bom) {
            return (
                <Tree
                    showLine={
                        {
                            showLeafIcon: false
                        }
                    }
                    switcherIcon={<DownOutlined />}
                    treeData={[...treeData(data)]}
                // height="300px"
                />
            )
        }
    }

    const handleRemove = (i) => {
        let types = [...bomForm.bomData]
        types.splice(i, 1)
        setBomForm({ ...bomForm, bomData: types })
    }

    const handleFgInput = (e) => {
        const { name, value } = e.target
        setBomForm({ ...bomForm, fgData: { ...bomForm['fgData'], [name]: value } })
    }

    const handleSpInput = (e, i) => {
        const { name, value } = e.target
        let bomDataTemp = [...bomForm.bomData]
        bomDataTemp[i][document.getElementById(`spArtNum${i}`).value]['bom'] = { ...bomDataTemp[i][document.getElementById(`spArtNum${i}`).value]['bom'], [name]: value }
        setBomForm({ ...bomForm, bomData: bomDataTemp })
    }

    const handleAddSubBom = () => {
        let bomDataTemp = [...bomForm.bomData, {}]
        let artConfirmTemp = { ...artConfirm }
        bomDataTemp.forEach((item, i) => {
            if (!artConfirmTemp[i]) {
                artConfirmTemp[i] = false
            }
        })
        setArtConfirm(artConfirmTemp)
        setBomForm({ ...bomForm, bomData: bomDataTemp })
    }

    const handleArtConfirm = (i) => {
        setArtConfirm({ ...artConfirm, [i]: true })
        let bomDataTemp = [...bomForm.bomData]
        bomDataTemp[i] = { [document.getElementById(`spArtNum${i}`).value]: { bom: {} } }
        setBomForm({ ...bomForm, bomData: bomDataTemp })
    }

    const handleArtChange = (i) => {
        setArtConfirm({ ...artConfirm, [i]: false })
    }

    const handleEdit = (row) => {
        let rowTemp = { ...row }
        let bomDataTemp = []
        let artConfirmTemp = {}
        Object.entries(rowTemp?.bom)?.forEach((entry) => {
            let temp = {}
            temp[entry[0]] = entry[1]
            bomDataTemp.push(temp)
        })
        bomDataTemp.forEach((item, i) => {
            artConfirmTemp[i] = true
        })
        delete rowTemp.bom
        setArtConfirm(artConfirmTemp)
        setBomForm({ fgData: { ...rowTemp }, bomData: bomDataTemp })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let finalData = { ...bomForm.fgData, bom: {} }
        bomForm?.bomData?.forEach((bom) => {
            finalData.bom = { ...finalData.bom, ...bom }
        })
        console.log(finalData)
        await axios.post("/bom/add", finalData).then((res) => {
            if (res.status === 200) {
                message.success(res.data.msg)
                setBomForm({
                    fgData: { fgArtNum: '', fgDescription: '', fgQty: '', fgUOM: '' },
                    bomData: []
                })
                setArtConfirm({})
            } else {
                message.error("Data not saved!")
            }
        }).catch((e) => {
            console.log(e)
        })
        setLoading({ ...loading, uploading: false })
    }

    const handleCancel = () => {
        setBomForm({
            fgData: { fgArtNum: '', fgDescription: '', fgQty: '', fgUOM: '' },
            bomData: []
        })
        setArtConfirm({})
    }

    useEffect(() => {
        disp()
    }, [])

    useEffect(() => {
        socket.on('bom', (Boms) => {
            let { value } = document.getElementById('searchInput')
            console.log("========>", value)
            const res = Boms.filter((item) => {
                return item.fgArtNum.toLowerCase().match(value.toLocaleLowerCase())
            })
            setBoms({
                boms: Boms,
                filteredBoms: res
            })
        })
    }, [socket])

    useEffect(() => {
        const res = boms.boms.filter((item) => {
            return item.fgArtNum.toLowerCase().match(search.toLocaleLowerCase())
        })
        setBoms({ ...boms, filteredBoms: res })
    }, [search])

    return (
        <div className='mainHeight'>
            <Masternav name='bom' />
            <div className='p-1'>
                <div className='card p-3'>
                    {/* <Tree
                        showLine={
                            {
                                showLeafIcon: false
                            }
                        }
                        switcherIcon={<DownOutlined />}
                        treeData={treeData}
                        height="70vh"
                    /> */}
                    <DataTable
                        columns={columns}
                        data={boms.filteredBoms}
                        fixedHeader
                        fixedHeaderScrollHeight='580px'
                        pagination
                        paginationDefaultPage={1}
                        expandableRows
                        expandableRowsComponent={expandedComponent}
                        actions={<input className='form-control' id='searchInput' placeholder='Search Artnum' value={search} onChange={(e) => setSearch(e.target.value)} />}
                    />
                </div>
                <div className='card'>
                    <section className='masterbody' hidden={hidden}>
                        <FloatingLabel label="FG Article#">
                            <Form.Control name='fgArtNum' type="text" value={bomForm.fgData.fgArtNum} onChange={(e) => handleFgInput(e)} placeholder="none" autoComplete="off" />
                            <p className="text-danger">{formValidationError['fgArtNum']}</p>
                        </FloatingLabel>
                        <FloatingLabel label="FG Description">
                            <Form.Control name='fgDescription' type="text" value={bomForm.fgData.fgDescription} onChange={(e) => handleFgInput(e)} placeholder="none" autoComplete="off" />
                            <p className="text-danger">{formValidationError['fgDescription']}</p>
                        </FloatingLabel>
                        <FloatingLabel label="FG Quantity">
                            <Form.Control name='fgQty' type="number" value={bomForm.fgData.fgQty} onChange={(e) => handleFgInput(e)} placeholder="none" autoComplete="off" />
                            <p className="text-danger">{formValidationError['fgQty']}</p>
                        </FloatingLabel>
                        <FloatingLabel label="FG Uom">
                            <Form.Control name='fgUOM' type="text" value={bomForm.fgData.fgUOM} onChange={(e) => handleFgInput(e)} placeholder="none" autoComplete="off" />
                            <p className="text-danger">{formValidationError['fgUOM']}</p>
                        </FloatingLabel>
                    </section>
                    <hr />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading.uploading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <div className="d-flex flex-column gap-3 px-3">
                        <Button className='align-self-center mb-3' type="primary" onClick={() => handleAddSubBom()}>
                            Add subBom
                        </Button>
                        {
                            bomForm.bomData.map((bom, i) => {
                                return (
                                    <div key={i}>
                                        <section className='subBomGrp' hidden={hidden}>
                                            <Button className="" type="primary" onClick={() => { !artConfirm[i] ? handleArtConfirm(i) : handleArtChange(i) }}>
                                                {artConfirm[i] ? "Change Article#" : "Confirm Article#"}
                                            </Button>
                                            <FloatingLabel label="FG Article#">
                                                <Form.Control id={`spArtNum${i}`} name='spArtNum' type="text" value={Object.keys(bom)?.[0]} placeholder="none" autoComplete="off" disabled={artConfirm[i]} />
                                            </FloatingLabel>
                                            <div className='d-flex gap-3' hidden={!artConfirm[i]}>
                                                <FloatingLabel label="FG Description">
                                                    <Form.Control name='rmdescription' type="text" value={bom[Object.keys(bom)?.[0]]?.['bom']?.['rmdescription']} onChange={(e) => handleSpInput(e, i)} placeholder="none" autoComplete="off" />
                                                </FloatingLabel>
                                                <FloatingLabel label="FG Quantity">
                                                    <Form.Control name='rmQty' type="text" value={bom[Object.keys(bom)?.[0]]?.['bom']?.['rmQty']} onChange={(e) => handleSpInput(e, i)} placeholder="none" autoComplete="off" />
                                                </FloatingLabel>
                                                <FloatingLabel label="FG Uom">
                                                    <Form.Control name='rmuom' type="text" value={bom[Object.keys(bom)?.[0]]?.['bom']?.['rmuom']} onChange={(e) => handleSpInput(e, i)} placeholder="none" autoComplete="off" />
                                                </FloatingLabel>
                                            </div>
                                            <Button className="" type="primary" onClick={() => handleRemove(i)}>
                                                X
                                            </Button>
                                        </section>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <section className='masterbtn' hidden={hidden}>
                        <div >
                            <Button type="primary" onClick={(e) => { setLoading({ ...loading, uploading: true }); handleSubmit(e); }}>
                                {/* {loading.uploading ? 'Adding' : 'Add'} */}
                                Add
                            </Button>
                            <Button type="primary" onClick={() => handleCancel()}>Cancel</Button>
                        </div>
                    </section>
                </div>
            </div >
        </div >
    )
}
