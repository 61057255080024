import React, { useEffect, useState } from 'react'
import { Backdrop, CircularProgress } from '@mui/material';
import { Masternav } from '../../helper/MasterNav'
import { Button, message } from 'antd';
import DataTable from 'react-data-table-component';
import { FloatingLabel, Form } from 'react-bootstrap';
import axios from '../../../../../api/axios'
import { socket } from '../../../../../services/socket';
import moment from 'moment'
import { useGetUserDetails } from '../../../../../hooks/useGetUserDetails';

export const Category = ({ hidden }) => {
    const { user } = useGetUserDetails()
    const [loading, setLoading] = useState({
        uploading: false,
        enabling: false
    })
    const [categories, setCategories] = useState({
        actual: [],
        filtered: []
    })
    const [catForm, setCatForm] = useState({ catName: '', subTypes: [] })
    const [search, setSearch] = useState('')

    const columns = [
        {
            name: 'Product Group',
            selector: (row) => row.catName,
            width: '200px',
        },
        {
            name: 'Types',
            selector: (row) => row.catType,
        },
        {
            name: 'Enable',
            selector: (row) => (
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={row.enable}
                        onChange={(e) => handleEnable(e, row._id)}
                        disabled={loading.enabling}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            ),
        },
        {
            name: 'Date',
            selector: (row) => moment(row.date).utc().format("DD-MM-YYYY"),
        },
        // {
        //     name: 'Edit',
        //     selector: (row) => <Button type='primary' onClick={() => handleEdit(row)}>Edit</Button>,
        //     width: '110px',
        //     right: true,
        // },
    ]

    const handleRemove = (i) => {
        let types = [...catForm.subTypes]
        types.splice(i, 1)
        setCatForm({ ...catForm, subTypes: types })
    }

    const handleInput = (e) => {
        let { name, value } = e.target
        setCatForm({ ...catForm, [name]: value })
    }

    const handleSubTypesInput = (e, i) => {
        const { name, value } = e.target
        let typesData = [...catForm.subTypes]
        typesData[i] = value
        setCatForm({ ...catForm, subTypes: typesData })
    }

    const handleEdit = (row) => {
        setCatForm(row)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let catFormTemp = { ...catForm }
        catFormTemp.createdBy = user.email
        if (catFormTemp.catName !== '' && catFormTemp.subTypes.length > 0) {
            await axios.post("/category/add", catFormTemp).then((res) => {
                if (res.status === 200) {
                    message.success(res.data.msg)
                    setCatForm({ catName: '', subTypes: [] })
                } else {
                    message.error(res.data.msg)
                }
            }).catch((e) => {
                console.log(e)
                message.error("Data not Saved!")
            })
        } else {
            message.warning("Please enter product group and types and try again")
        }
        setLoading({ ...loading, uploading: false })
    }

    const handleEnable = async (e, _id) => {
        let { checked } = e.target
        setLoading({ ...loading, enabling: true })
        await axios.post(`/category/enable/${_id}/${checked}`).then((res) => {
            if (res.status === 200) {
                message.success(res.data.msg)
            } else {
                message.error(res.data.msg)
            }
        }).catch((e) => {
            console.log(e)
            message.error("Data not Saved!")
        })
    };

    const handleCancel = () => {
        setCatForm({ catName: '', subTypes: [] })
    }

    const handleCatChange = (e) => {
        let { value } = document.getElementById('floatingSelect3')
        if (value !== 'all') {
            let categoriesTemp = [...categories.actual]
            let data = categoriesTemp?.filter((item) => {
                return String(item.catName).toLowerCase() === String(value).toLocaleLowerCase()
            })
            setCategories({ ...categories, filtered: data })
        } else {
            setCategories({ ...categories, filtered: categories.actual })
        }
    }

    const handleCatSubTypeChange = (e) => {
        let { value } = document.getElementById('floatingSelect4')
        if (value !== 'all') {
            let categoriesTemp = [...categories.actual]
            let find = {}
            find['catName'] = document.getElementById('floatingSelect3').value
            let data = categoriesTemp?.filter((item1) => {
                return String(item1.catName).toLowerCase() === String(find.catName).toLocaleLowerCase()
            }).filter((item2) => {
                return String(item2.catType).toLowerCase() === String(value).toLocaleLowerCase()
            })
            setCategories({ ...categories, filtered: data })
        } else {
            handleCatChange()
        }
    }

    useEffect(() => {
        (async function () {
            await axios.get('/category/').then((res) => {
                if (res.status === 200) {
                    setCategories({
                        actual: res.data.categories,
                        filtered: res.data.categories,
                    })
                }
            }).catch((e) => {
                console.log(e)
            })
        })()
    }, [])

    useEffect(() => {
        socket.on('category', (cat) => {
            let find = {}
            find.catName = document.getElementById('floatingSelect3').value
            find.catType = document.getElementById('floatingSelect4').value
            let data = cat?.filter((item1) => {
                if (find.catName === 'all') {
                    return String(item1.catName !== 'all' ? item1.catName : '').toLowerCase().match(String('').toLocaleLowerCase())
                } else {
                    return String(item1.catName !== 'all' ? item1.catName : '').toLowerCase() === String(find.catName).toLocaleLowerCase()
                }
            }).filter((item2) => {
                if (find.catType === 'all') {
                    return String(item2.catType !== 'all' ? item2.catType : '').toLowerCase().match(String('').toLocaleLowerCase())
                } else {
                    return String(item2.catType !== 'all' ? item2.catType : '').toLowerCase() === String(find.catType).toLocaleLowerCase()
                }
            }).filter((item3) => {
                return String(item3.catType).toLowerCase().match(String(search).toLocaleLowerCase())
            })
            setCategories({
                actual: cat,
                filtered: data
            })
            setLoading({ ...loading, enabling: false })
        })
    }, [socket])

    useEffect(() => {
        let find = {}
        find.catName = document.getElementById('floatingSelect3').value
        find.catType = document.getElementById('floatingSelect4').value
        let data = categories?.actual?.filter((item1) => {
            if (find.catName === 'all') {
                return String(item1.catName !== 'all' ? item1.catName : '').toLowerCase().match(String('').toLocaleLowerCase())
            } else {
                return String(item1.catName !== 'all' ? item1.catName : '').toLowerCase() === String(find.catName).toLocaleLowerCase()
            }
        }).filter((item2) => {
            if (find.catType === 'all') {
                return String(item2.catType !== 'all' ? item2.catType : '').toLowerCase().match(String('').toLocaleLowerCase())
            } else {
                return String(item2.catType !== 'all' ? item2.catType : '').toLowerCase() === String(find.catType).toLocaleLowerCase()
            }
        }).filter((item3) => {
            return String(item3.catType).toLowerCase().match(String(search).toLocaleLowerCase())
        })
        setCategories({ ...categories, filtered: data })
    }, [search])

    console.log()

    return (
        <div className="mainHeight">
            <Masternav name="product group" />
            <div className="d-flex justify-content-between py-3 px-0 w-100">
                <div className="form-floating filtercust">
                    <select className="form-select" id='floatingSelect3' name='catName' defaultValue='all' onChange={(e) => handleCatChange(e)}>
                        {/* <select className="form-select" id='floatingSelect3' name='catName' defaultValue='all'> */}
                        <option value='all'>All</option>
                        {
                            [...new Set(categories?.actual?.reduce((arr, item) => {
                                if (item?.enable) {
                                    arr[0].push(item.catName)
                                } else {
                                    arr[1].push(item.catName)
                                }
                                return arr
                            }, [[], []])?.[0])]?.map((option, index) => {
                                return <option key={index} value={option}>{option}</option>
                            })
                        }
                    </select>
                    <label htmlFor="floatingSelect3">Select Product Group</label>
                </div>
                <div className="form-floating filtercust">
                    <select className="form-select" id='floatingSelect4' name='catSubType' defaultValue='all' onChange={(e) => handleCatSubTypeChange(e)}>
                        {/* <select className="form-select" id='floatingSelect4' name='catSubType' defaultValue='all'> */}
                        <option value='all'>All</option>
                        {
                            categories?.actual?.filter((item1) => {
                                if (item1?.enable) {
                                    return String(item1["catName"]).toLowerCase() === (String(document.getElementById("floatingSelect3").value).toLocaleLowerCase())
                                } else {
                                    return false
                                }
                            }).map((item2) => {
                                if (item2?.enable) {
                                    return item2.catType
                                }
                            })?.map((option, index) => {
                                return <option key={index} value={option}>{option}</option>
                            })
                        }
                    </select>
                    <label htmlFor="floatingSelect4">Select Type</label>
                </div>
                <div className="form-floating searchBox input-group-sm select_box">
                    <input
                        className="form-control"
                        placeholder="Search Article Num"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <label htmlFor="floatingTextarea">Search Types</label>
                </div>
            </div>
            <div className='main_body p-1 w-100 gap-2' >
                <div className='stage_body card p-1'>
                    <div hidden={hidden}>
                        {/* <FloatingLabel label="Enter New Product Group">
                            <Form.Control
                                name="catName"
                                type="text"
                                placeholder="none"
                                autoComplete="off"
                                value={catForm.catName}
                                onChange={(e) => handleInput(e)}
                            />
                        </FloatingLabel> */}
                        <form className="form-floating input-group-sm select_box">
                            <input className="form-control" id="floatingTextarea" list="datalistOptions1" value={catForm.catName} placeholder=" " name='catName' onChange={(e) => handleInput(e)} autoComplete="off" />
                            <datalist id="datalistOptions1" >
                                {/* {uom?.map((option, index) => {
                                    return <option key={index} value={option} />
                                })} */}
                                {[...new Set(categories?.actual?.map((item) => { return item.catName }))]?.map((option, index) => {
                                    return <option key={index} value={option}>{option}</option>
                                })}
                            </datalist>
                            <label htmlFor="floatingTextarea">Enter New / Existing Product Group</label>
                        </form>
                        <br />
                        <div>
                            <div style={{ overflowY: 'auto', height: '450px' }} className='d-flex flex-column align-items-center'>
                                {
                                    catForm?.subTypes?.map((type, i) => {
                                        return (
                                            <div className="form-group d-flex justify-content-evenly gap-1 my-3" key={i + 1}>
                                                <label className="control-label my-2" >{`Type ${i + 1}`}:</label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Type"
                                                        name="stageName"
                                                        value={catForm?.subTypes?.[i]?.type}
                                                        onChange={(e) => handleSubTypesInput(e, i)}
                                                    />
                                                </div>
                                                <Button className="" type="primary" onClick={() => handleRemove(i)}>
                                                    x
                                                </Button>
                                            </div>
                                        );
                                    })
                                }
                                <Button className="m-1" type="primary" onClick={() => setCatForm({ ...catForm, subTypes: [...catForm.subTypes, ""] })}>
                                    ADD NEW TYPE
                                </Button>
                            </div>
                            <div className='d-flex justify-content-center gap-2'>

                                <Button className="" type="primary" onClick={(e) => { setLoading({ ...loading, uploading: true }); handleSubmit(e) }}>
                                    SUBMIT
                                </Button>
                                <Button className="" type="primary" onClick={(e) => handleCancel(e)}>
                                    CANCEL
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading.uploading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className='table_body card'>
                    <DataTable
                        columns={columns}
                        data={categories.filtered}
                        pagination
                        fixedHeader
                        paginationDefaultPage={1}
                        highlightOnHover
                    />
                </div>
            </div>
        </div>
    )
}
