import { configureStore } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import { persistStore } from "redux-persist";

import users from "./users";
import ppos from "./ppos";
import article from "./article";
import wo from "./wo";

const reducers = combineReducers({
  userInfo: users,
  pposInfo: ppos,
  woInfo: wo,
  articleInfo: article,
});

const persistConfig = {
  key: "mse-root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  // devTools:false,
  middleware: [thunk],
});

let Persistor = persistStore(store);

export { store, Persistor };
