import React from 'react'

export const Footer = () => {
    return (
        <div className="footer fixed-bottom contentContainer bg-dark" style={{zIndex:"1000"}} >
            <div
                className="w-100 h-100 px-2"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <p className="p-0 m-0 text-light">
                    All Rights Reserved - <b>SNS Industries</b>{" "}
                </p>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="text-light">
                        Powered by <b>F8 Products LLP</b>
                    </span>
                    {/* <img src={f8} alt="" className='logo' /> */}
                </div>
            </div>
        </div>
    )
}
