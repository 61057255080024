import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Nav, Navbar, Container } from 'react-bootstrap'


export const MesNav = ({ name }) => {

  return (
    <div>
      {console.log(window.location.pathname)}
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0 responsive-nav"
              style={{ maxHeight: '90vh', width: "100%" }}
              navbarScroll
            >
              <Nav.Link ><Link to="/mesHome/" className='btn btn-secondary '>Home</Link></Nav.Link>
              <Nav.Link ><Link to="/mesHome/ppoDashboard" style={window.location.pathname === '/mesHome/ppoDashboard' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-secondary'>PPO</Link></Nav.Link>
              <Nav.Link ><Link to="/mesHome/woDashboard" style={window.location.pathname === '/mesHome/woDashboard' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-secondary'>WO</Link></Nav.Link>
              {/* <Nav.Link ><Link to="/master/bom" style={window.location.pathname === 'bom' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-secondary'>BOM</Link></Nav.Link> */}
              <Nav.Link ><Link to="/mesHome/master" style={window.location.pathname === '/mesHome/master' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-secondary'>Master</Link></Nav.Link>
              <Nav.Link ><Link to="/mesHome/quality" style={window.location.pathname === '/mesHome/quality' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-secondary'>Quality</Link></Nav.Link>
              <Nav.Link ><Link to="/mesHome/reports" style={window.location.pathname === '/mesHome/reports' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-secondary'>Reports</Link></Nav.Link>
              <Nav.Link ><Link to="/mesHome/plots" style={window.location.pathname === '/mesHome/plots' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-secondary'>Plots</Link></Nav.Link>
              {/* <Nav.Link ><Link to="/mesHome/verification" style={window.location.pathname === 'verification' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-secondary'>Verification</Link></Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}