import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { socket } from '../../services/socket';
import { setPPOS } from '../../features/ppos';
import { setWO } from '../../features/wo'
import axios from '../../api/axios';

export const MesHome = () => {
    const dispatcher = useDispatch()
    const navigate = useNavigate();
    const [isAlert, setIsAlert] = useState(false)

    useEffect(() => {

        (async function () {
            await axios.get('/wo/').then((res) => {
                if (res.status === 200) {
                    dispatcher(setWO(res.data.wos))
                }
            })
            await axios.get('/ppo/').then((res) => {
                // console.log(JSON.stringify(res.data.ppos))
                if (res.status === 200) {
                    dispatcher(setPPOS(res.data.ppos))
                }
            })
            // await axios.get('/wo/').then((res) => {
            //     if(res.status===200){
            //         dispatcher(setWO(res.data.wos))
            //     }
            // })

        })()

        socket.on('wo', (wo) => {
            dispatcher(setWO(wo))
        })
        socket.on('ppo', (ppo) => {
            dispatcher(setPPOS(ppo))
        })
    }, [socket])

    return (
        <div className="contentContainer">
            <h1 style={{ 'textAlign': 'center' }}>Dashboard</h1>
            <div className="apps">
                <div className="appContainer" onClick={() => navigate("/mesHome/ppoDashboard/")}>
                    <h3>PPO</h3>
                </div>
                <div className="appContainer" onClick={() => navigate("/mesHome/woDashboard")}>
                    <h3>WO</h3>
                </div>
                <div className="appContainer" onClick={() => navigate("/mesHome/master")}>
                    <h3>Master</h3>
                </div>
                <div className="appContainer" onClick={() => navigate("/mesHome/quality")}>
                    <h3>Quality</h3>
                </div>
                <div className="appContainer" onClick={() => navigate("/mesHome/reports")}>
                    <h3>Reports</h3>
                </div>
                <div className="appContainer" onClick={() => navigate("/mesHome/plots")}>
                    <h3>Plots</h3>
                </div>
                {/* <div className="appContainer" onClick={() => navigate("/mesHome/plot")}>
                    <h3>Plots</h3>
                </div> */}
                {/* <div className="appContainer" onClick={() => navigate("/mesHome/sfg")}>
                    <h3>S(F.G)</h3>
                </div> */}
                {/* <div className="appContainer" onClick={() => navigate("/mesHome/verification")}>
                    <h3>Verification</h3>
                </div> */}
                {/* <div className="appContainer" onClick={() => navigate("/mesHome/stores")}>
                    <h3>Stores</h3>
                </div> */}
            </div>
        </div>
    )
}
