import React, { useState } from "react";
import { Button, Form, Input, Drawer, Spin, message } from "antd";

import axios from "../../../../api/axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../../../features/users";
import "./style.scss";
import { socket } from '../../../../services/socket';

export default function Login() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const dispatcher = useDispatch();
    const showDrawer = (e) => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const handleSubmit = async (values) => {
        setIsLoading(true);
        await axios
            .post("/user/login", values)
            .then((res) => {
                if (res.status === 200) {
                    socket.emit('add-user', res.data.data.user._id)
                    console.log(res.data.data)
                    dispatcher(userLogin({ ...res.data.data }));
                    window.localStorage.setItem('userId', res.data.data.user._id)
                    setIsLoading(false);
                    setVisible(false);
                    navigate("/", { state: { isLogin: true } });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                message.error("Unable to Login");
                console.log(e);
            });
    };

    return (
        <>
            <Button
                className="m-1 w-100"
                disabled={isLoading}
                name="Login"
                type="primary"
                onClick={(e) => showDrawer(e)}
            >
                Login
            </Button>
            <Drawer
                title="Login"
                placement="right"
                size="default"
                onClose={onClose}
                open={visible}
                maskClosable={false}
                style={{ zIndex: "1200" }}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={handleSubmit}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please input your email!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={isLoading}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                {isLoading ? (
                    <div className="spin_center">
                        <Spin></Spin>
                    </div>
                ) : (
                    ""
                )}
            </Drawer>
        </>
    );
}
