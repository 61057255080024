import React from 'react'
import { useSelector } from 'react-redux';
import axios from '../../../../../api/axios'
import { ArticleStages } from './helper/ArticleStages'

export const Stages = ({ hidden }) => {

  return (
    <div className='d-flex justify-content-between'>
      <ArticleStages hidden={hidden} />
    </div>
  )
}
