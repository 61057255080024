import React, { useEffect, useRef, useState } from 'react'
import { PlotsNav } from '../helper/PlotsNav'
import { Button, message } from 'antd'
import Chart from "chart.js/auto";
import { Bar, Line, Radar } from 'react-chartjs-2';
import axios from '../../../../api/axios';
import moment from 'moment';
import { utils, writeFileXLSX } from "xlsx";

const charts = {
    line: Line,
    bar: Bar,
    radar: Radar
}

const month_year = {
    "0": "January",
    "1": "February",
    "2": "March",
    "3": "April",
    "4": "May",
    "5": "June",
    "6": "July",
    "7": "August",
    "8": "September",
    "9": "October",
    "10": "November",
    "11": "December"
}

export const YtdMtdPlot = () => {
    const [ChartData, setChartData] = useState(Line)
    const [chartName, setChartName] = useState('line')
    const chartRef = useRef(null)
    const d = new Date()
    const [categories, setCategories] = useState([])
    const [ppoData, setPpoData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const [plotForm, setPlotForm] = useState({ firstYear: (d.getFullYear() - 1).toString(), secondYear: (d.getFullYear()).toString(), 'article#': "" })
    const [monthYear, setMonthYear] = useState('Yearly')
    const [month, setMonth] = useState(0)
    const [dataOptions, setDataOptions] = useState({})
    const [xlsxRawData, setXlsxRawData] = useState({ 'yearly': {}, 'monthly': {}, 'weeks': [] })
    let years = {
        years: []
    }
    for (let i = d.getFullYear(); i >= 2018; i--) {
        years.years.push(`${i}`)
    }
    const changeChart = (e) => {
        setChartName(e.target.value)
    }

    const getPlots = async () => {
        setIsLoading(true)
        await axios.post('/plot/ytd', plotForm).then((res) => {
            let currYearData = []
            let prevYearData = []
            Object.keys(res.data[0].currMonthsData).map((key) => {
                currYearData.push(res.data[0].currMonthsData[key])
                prevYearData.push(res.data[0].prevMonthsData[key])
            })
            let weeks = []
            let currWeeksData = []
            let prevWeeksData = []
            if (res.data[1]?.currYearWeek_list?.length > 0) {
                let firstWeekNum = (parseInt(res.data[1]?.currYearWeek_list?.[month]?.['firstWeekNum'])) - 1
                Object?.keys(res.data[1]?.currYearWeek_list?.[month])?.forEach((key) => {
                    if (key != 'firstWeekNum') {
                        if (month != 0) {
                            weeks.push(`Week ${parseInt(key) - firstWeekNum}`)
                        } else {
                            weeks.push(`Week ${key}`)
                        }
                        currWeeksData.push(parseInt(res.data[1]?.currYearWeek_list?.[month][key][0]))
                        prevWeeksData.push(parseInt(res.data[1]?.currYearWeek_list?.[month][key][1]))
                    }
                })
            }
            setXlsxRawData({
                ...xlsxRawData,
                'yearly': {
                    [document.getElementById('floatingSelect01')?.value]: currYearData,
                    [document.getElementById('floatingSelect0')?.value]: prevYearData
                },
                'monthly': {
                    [document.getElementById('floatingSelect01')?.value]: currWeeksData,
                    [document.getElementById('floatingSelect0')?.value]: prevWeeksData
                },
                'weeks': weeks
            })
            setVariableData({
                mainLabel1: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                mainLabel2: weeks,
                dataSetLabel1: monthYear === 'Yearly' ? plotForm?.firstYear : `${document.getElementById('select month').options[month].text} ${plotForm?.firstYear}`,
                datSetData1: monthYear === 'Yearly' ? prevYearData : prevWeeksData,
                dataSetLabel2: monthYear === 'Yearly' ? plotForm?.secondYear : `${document.getElementById('select month').options[month].text} ${plotForm?.secondYear}`,
                datSetData2: monthYear === 'Yearly' ? currYearData : currWeeksData,
            })
        }).catch((e) => {
            console.log(e)
        })
        if (chartName !== 'radar') {
            setDataOptions({
                scales: {
                    yAxis: {
                        title: {
                            display: true,
                            text: 'Quantity in numbers',
                            font: {
                                size: 16,
                            },
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                    xAxis: {
                        title: {
                            display: true,
                            text: monthYear === 'Yearly' ? 'Months' : 'Weeks',
                            font: {
                                size: 16,
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        labels: {
                            font: {
                                size: 16,
                            },
                        },
                    },
                    title: {
                        display: true,
                        text: `YTD/MTD Plot`,
                        font: {
                            size: 25,
                        },
                    },
                },
                responsive: true,
            })
        } else {
            setDataOptions({
                plugins: {
                    legend: {
                        labels: {
                            font: {
                                size: 16,
                            },
                        },
                    },
                    title: {
                        display: true,
                        text: `YTD/MTD Plot`,
                        font: {
                            size: 25,
                        },
                    },
                },
                responsive: true,
            })
        }
        setChartData(charts[chartName])
        setIsLoading(false)
    }

    const [variableData, setVariableData] = useState({
        mainLabel1: [],
        mainLabel2: [],
        dataSetLabel1: d.getFullYear() - 1,
        datSetData1: [],
        dataSetLabel2: d.getFullYear(),
        datSetData2: [],
    })

    let aaa = {}
    if (variableData) {
        aaa = {
            labels: monthYear === 'Yearly' ? variableData?.mainLabel1 : variableData?.mainLabel2,
            datasets: [
                {
                    label: variableData?.dataSetLabel1,
                    data: variableData?.datSetData1,
                    backgroundColor: [
                        'rgba(11, 127, 171,0.5)',
                    ],
                    borderColor: [
                        'rgba(11, 127, 171,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
                {
                    label: variableData?.dataSetLabel2,
                    data: variableData?.datSetData2,
                    backgroundColor: [
                        'rgba(242, 38, 19,0.5)',
                    ],
                    borderColor: [
                        'rgba(242, 38, 19,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
            ]
        }
    }

    const [userData, setUserData] = useState(aaa)

    const handleInput = (e) => {
        let { name, value } = e.target
        if (name === 'catName' && value === 'all') {
            setPlotForm({ ...plotForm, [name]: value, 'catSubType': 'all' })
        } else {
            setPlotForm({ ...plotForm, [name]: value })
        }
    }

    const handleDownload = () => {
        setIsDownloading(true)
        const data = chartRef.current.toBase64Image()
        let filename = "";
        const keys = Object.keys(plotForm)
        var time = d.getHours() + "-" + d.getMinutes() + "-" + d.getSeconds();

        filename += `${time}_${d.toDateString()}_YTD/MTD Plot`

        filename = filename + '.png'
        const link = document.createElement('a');
        link.download = filename;
        link.href = chartRef.current.toBase64Image();
        link.click();
        setPlotForm({ "wo#": "", "article#": "" })
        setPlotForm({})
        setIsDownloading(false)
    }

    const downloadXLSX = () => {
        setDownloading(true)
        let tableData = monthYear === 'Yearly' ? document.getElementById("tabletoxlsx0") : document.getElementById("tabletoxlsx1")
        const ws = utils.table_to_sheet(tableData);
        const merge = [
            { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
            { s: { r: 0, c: monthYear === 'Yearly' ? 1 : 2 }, e: { r: 0, c: monthYear === 'Yearly' ? 12 : xlsxRawData?.weeks?.length + 1 } },
            { s: { r: 0, c: monthYear === 'Yearly' ? 13 : xlsxRawData?.weeks?.length + 2 }, e: { r: 1, c: monthYear === 'Yearly' ? 13 : xlsxRawData?.weeks?.length + 2 } },
        ];
        if (monthYear === 'Monthly') {
            merge.push({ s: { r: 0, c: 1 }, e: { r: 1, c: 1 } })
        }
        ws["!merges"] = merge;
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws);
        let filename = `YTD/MTD_${document.getElementById('floatingSelect0')?.value}_${document.getElementById('floatingSelect01')?.value}`;
        if (document.getElementById('catNameFloatingSelect')?.value !== 'all') {
            filename += `_${document.getElementById('catNameFloatingSelect')?.value}`
            if (document.getElementById('catSubTypeFloatingSelect')?.value !== 'all') {
                filename += `_${document.getElementById('catSubTypeFloatingSelect')?.value}`
            }
        }
        if (monthYear === 'Yearly') {
            filename += `_Yearly Report`
        } else {
            filename += `_${month_year[month]} Month Report`
        }
        filename = filename + '.xlsx'
        writeFileXLSX(wb, filename);
        setDownloading(false)
    }

    useEffect(() => {
        axios.get('/ppo/plots').then((res) => {
            setPpoData(res.data.ppos)
        })
        axios.get('/category/').then((res) => {
            if (res.status === 200) {
                setCategories(res.data.categories)
            }
        }).catch((e) => {
            console.log(e)
        })
    }, [])

    useEffect(() => {
        setUserData(aaa)
    }, [variableData, ChartData])

    return (
        <div className='mainHeight'>
            <div>
                <PlotsNav name='YTD/MTD' />
            </div>
            <div className="m-auto p-3">
                {/* {JSON.stringify(variableData)} */}
                <div className='ytdmtdInputs'>
                    <div className="form-floating" style={{ width: '100%' }} >
                        <select className="form-select" id='floatingSelect3' defaultValue="line" name="chartType" onChange={(e) => changeChart(e)}>
                            <option value="line">Line</option>
                            <option value="bar">Bar</option>
                            <option value="radar">Radar</option>
                        </select>
                        <label htmlFor="floatingSelect3">Select chart type</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }}>
                        <select className="form-select" id='floatingSelect2' onChange={(e) => setMonthYear(e.target.value)} defaultValue="Yearly">
                            <option value="Yearly">Yearly</option>
                            <option value="Monthly">Monthly</option>
                        </select>
                        <label htmlFor="floatingSelect2">Select yearly / monthly</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }}>
                        <select className="form-select" id='floatingSelect0' name='firstYear' onChange={(e) => handleInput(e)} defaultValue={d.getFullYear() - 1}>
                            {
                                years.years.map((year, i) => {
                                    return (
                                        <option key={i} value={year} disabled={year >= document.getElementById('floatingSelect01')?.value}>{year}</option>
                                    )
                                })
                            }
                            {/* <option value={2022} disabled={document.getElementById('floatingSelect01')?.value === document.getElementById('floatingSelect0')?.options[0].innerHTML ? true : false}>2022</option>
                            <option value={2021} disabled={document.getElementById('floatingSelect01')?.value === document.getElementById('floatingSelect0')?.options[1].innerHTML ? true : false}>2021</option>
                            <option value={2020} disabled={document.getElementById('floatingSelect01')?.value === document.getElementById('floatingSelect0')?.options[2].innerHTML ? true : false}>2020</option>
                            <option value={2019} disabled={document.getElementById('floatingSelect01')?.value === document.getElementById('floatingSelect0')?.options[3].innerHTML ? true : false}>2019</option>
                            <option value={2018} disabled={document.getElementById('floatingSelect01')?.value === document.getElementById('floatingSelect0')?.options[4].innerHTML ? true : false}>2018</option> */}
                        </select>
                        <label htmlFor="floatingSelect0" >Select first year</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }}>
                        <select className="form-select" id='floatingSelect01' name='secondYear' onChange={(e) => handleInput(e)} defaultValue={d.getFullYear()}>
                            {
                                years.years.map((year, i) => {
                                    return (
                                        <option key={i} value={year} disabled={year <= document.getElementById('floatingSelect0')?.value}>{year}</option>
                                    )
                                })
                            }
                            {/* <option value={2022} disabled={document.getElementById('floatingSelect0')?.value === document.getElementById('floatingSelect01')?.options[0].innerHTML ? true : false}>2022</option>
                            <option value={2021} disabled={document.getElementById('floatingSelect0')?.value === document.getElementById('floatingSelect01')?.options[1].innerHTML ? true : false}>2021</option>
                            <option value={2020} disabled={document.getElementById('floatingSelect0')?.value === document.getElementById('floatingSelect01')?.options[2].innerHTML ? true : false}>2020</option>
                            <option value={2019} disabled={document.getElementById('floatingSelect0')?.value === document.getElementById('floatingSelect01')?.options[3].innerHTML ? true : false}>2019</option>
                            <option value={2018} disabled={document.getElementById('floatingSelect0')?.value === document.getElementById('floatingSelect01')?.options[4].innerHTML ? true : false}>2018</option> */}
                        </select>
                        <label htmlFor="floatingSelect01" >Select second year</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }} hidden={monthYear === 'Yearly' ? true : false}>
                        <select className="form-select" id='select month' onChange={(e) => setMonth(e.target.value)} defaultValue={0}>
                            <option value={0}>January</option>
                            <option value={1}>February</option>
                            <option value={2}>March</option>
                            <option value={3}>April</option>
                            <option value={4}>May</option>
                            <option value={5}>June</option>
                            <option value={6}>July</option>
                            <option value={7}>August</option>
                            <option value={8}>September</option>
                            <option value={9}>October</option>
                            <option value={10}>November</option>
                            <option value={11}>December</option>
                        </select>
                        <label htmlFor="select month" >Select month</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }}>
                        <select className="form-select" id='catNameFloatingSelect' name='catName' defaultValue='all' onChange={(e) => handleInput(e)}>
                            <option value='all'>All</option>
                            {
                                [...new Set(categories?.reduce((arr, item) => {
                                    if (item?.enable) {
                                        arr[0].push(item.catName)
                                    } else {
                                        arr[1].push(item.catName)
                                    }
                                    return arr
                                }, [[], []])?.[0])]?.map((option, index) => {
                                    return <option key={index} value={option}>{option}</option>
                                })
                            }
                        </select>
                        <label htmlFor="catNameFloatingSelect">Select Product Group</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }}>
                        <select className="form-select" id='catSubTypeFloatingSelect' name='catSubType' defaultValue='all' onChange={(e) => handleInput(e)}>
                            <option value='all'>All</option>
                            {
                                categories?.filter((item1) => {
                                    if (item1?.enable) {
                                        return String(item1["catName"]).toLowerCase() === (String(document.getElementById("catNameFloatingSelect")?.value).toLocaleLowerCase())
                                    } else {
                                        return false
                                    }
                                }).map((item2) => {
                                    if (item2?.enable) {
                                        return item2.catType
                                    }
                                })?.map((option, index) => {
                                    return <option key={index} value={option}>{option}</option>
                                })
                            }
                        </select>
                        <label htmlFor="catSubTypeFloatingSelect">Select Type</label>
                    </div>
                    <form className="form-floating input-group-sm select_box" style={{ width: '100%' }} >
                        <input className="form-control" id="floatingTextarea" list="datalistOptions4" value={plotForm["article#"]} placeholder="Select Article Num" name='article#' onInput={(e) => handleInput(e)} autoComplete="off" />
                        <datalist id="datalistOptions4" >
                            {ppoData.map((item, i) => {
                                return <option key={i} value={item} />
                            })}
                        </datalist>
                        <label htmlFor="floatingTextarea">Select Article#</label>
                    </form>
                </div>
                <div className='d-flex justify-content-center gap-3 pt-3'>
                    <Button type='primary' onClick={() => getPlots()} loading={isLoading}>Plot</Button>
                    <Button type='primary' onClick={() => handleDownload()} loading={isDownloading}>Download Chart</Button>
                    <Button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => downloadXLSX()} loading={downloading}>Export</Button>
                </div>
                <div className='m-auto p-1 d-flex flex-column align-items-center chartWidth' >
                    <ChartData id='myChart' data={userData} options={dataOptions} ref={chartRef} />
                </div>
                <div style={{ display: 'none' }}>
                    <table className='table' id='tabletoxlsx0'>
                        <thead>
                            <tr>
                                <th rowSpan="2">YEARS</th>
                                <th colSpan="12">MONTHS</th>
                                <th rowSpan='2'>TOTAL</th>
                            </tr>
                            <tr>
                                <th>JANUARY</th>
                                <th>FEBRUARY</th>
                                <th>MARCH</th>
                                <th>APRIL</th>
                                <th>MAY</th>
                                <th>JUNE</th>
                                <th>JULY</th>
                                <th>AUGUST</th>
                                <th>SEPTEMBER</th>
                                <th>OCTOBER</th>
                                <th>NOVEMBER</th>
                                <th>DECEMBER</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.entries(xlsxRawData?.yearly)?.map((arr, i) => {
                                    let total = 0
                                    return (
                                        <tr>
                                            <td>{arr?.[0]}</td>
                                            {
                                                arr?.[1]?.map((qty) => {
                                                    total += qty
                                                    return (
                                                        <td>{qty}</td>
                                                    )
                                                })
                                            }
                                            <td>{total}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <table className='table' id='tabletoxlsx1'>
                        <thead>
                            <tr>
                                <th rowSpan='2'>MONTH</th>
                                <th rowSpan='2'>YEAR</th>
                                <th colSpan={xlsxRawData?.weeks?.length}>WEEKS</th>
                                <th rowSpan='2'>TOTAL</th>
                            </tr>
                            <tr>
                                {
                                    xlsxRawData?.weeks?.map((week, i) => {
                                        return (
                                            <th>{`WEEK ${i + 1}`}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.entries(xlsxRawData?.monthly)?.map((arr, i) => {
                                    let total = 0
                                    return (
                                        <tr>
                                            <td>{month_year[month]}</td>
                                            <td>{arr?.[0]}</td>
                                            {
                                                arr?.[1]?.map((qty, i) => {
                                                    total += qty
                                                    return (
                                                        <td>{qty}</td>
                                                    )
                                                })
                                            }
                                            <td>{total}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    )
}
