import React, { useEffect, useState } from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'
import { MesNav } from '../helper/MesNav'
import { formValidator } from '../../../services/formValidator';
import axios from '../../../api/axios';

import { Button } from 'antd';
import { QualityCheck } from './helper/QualityCheck';
import { QualityAlt } from './helper/QualityAlt';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { socket } from '../../../services/socket';

export const Quality = () => {
    const [qtyForm, setQtyForm] = useState({
        art_no: "",
        elec_mech: "",
        instr_name: "",
        param_desc: "",
        ref_unit: "",
        ref_val: "",
        supp_name: "",
        test_desc: "",
        tolerance: "",
        tolerance_unit: "",
        cust_proj: "",
    })
    const [formValidationError, setFormValidationError] = useState({});
    const [tests, setTests] = useState([])
    const [supp, setSupp] = useState([])
    const [cust, setCust] = useState([])
    const navigate = useNavigate()
    // console.log(test)
    // console.log(tests)
    let qty_Check = 0

    const handleInput = (e) => {
        setQtyForm({ ...qtyForm, [e.target.name]: e.target.value })
        if ((e.target.name) === "art_no") {
            const value = e.target.value
            const result = tests.find(({ art_no }) => art_no === value)
            // console.log(result.test_desc)
            setQtyForm({ ...qtyForm, 'test_desc': result })
            return setQtyForm({ ...qtyForm, [e.target.name]: e.target.value })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formError = formValidator(qtyForm, {
            po_num: "PO Num Required",
            po_date: "PO date Required",
            art_no: "Article Num Required",
            po_sample: "Test Sampling Required",
            rec_qty: "Received Qty Required",
            rec_date: "Received date Required",
            sam_qty: "Sample No/Size Required",
            supp_name: "Supplier Required",
            bill_no: "Bill Num Required",
            cust_proj: "Customer Required",
        })
        setFormValidationError(formError)
        if (Object.keys(formError).length === 0) {
            await axios.post('/quality/add', qtyForm).then((res) => {
                if (res.status === 200) {
                    const { _id } = res.data
                    setQtyForm({
                        art_no: "",
                        elec_mech: "",
                        instr_name: "",
                        param_desc: "",
                        ref_unit: "",
                        ref_val: "",
                        test_desc: "",
                        tolerance: "",
                        tolerance_unit: "",
                    })
                    navigate('/mesHome/qualitytest', { state: { qty: qtyForm, show: true, id: _id } })
                } else {
                    alert('Data not saved')
                }
            }).catch((e) => {
                console.log(e)
            })
        }
    }

    useEffect(() => {
        (async function () {
            await axios.get('/testplan/all').then((res) => {
                setTests(res.data.testplans)
            })
            await axios.get('/supplier/all').then((res) => {
                setSupp(res.data.supps)
            })
            await axios.get('/customer/select').then((res) => {
                setCust(res.data.custs)
            })
        })()
    }, [])

    useEffect(() => {
        socket.on('testPlan', (testPlans) => {
            setTests(testPlans)
        })
        socket.on('supplier', (supplier) => {
            setSupp(supplier)
        })
        socket.on('customerSelect', (customers) => {
            setCust(customers)
        })
    }, [socket])

    return (
        <div className='p-1 mainHeight'>
            <div>
                {/* <MesNav name='ppoDashboard' name='quality' /> */}
                <h3 className='text-center'>Incoming Quality</h3>
            </div>
            <div className='card'>
                <section className='masterbody'>
                    <FloatingLabel label="Enter PO Num">
                        <Form.Control name='po_num' type="text" onInput={handleInput} placeholder="none" autoComplete="off" />
                        <p className="text-danger">{formValidationError['po_num']}</p>
                    </FloatingLabel>
                    <FloatingLabel label="Select PO Date">
                        <Form.Control name='po_date' type="date" onInput={handleInput} placeholder="none" autoComplete="off" />
                        <p className="text-danger">{formValidationError['po_date']}</p>
                    </FloatingLabel>
                    {/* <FloatingLabel label=""> */}
                    <form className="form-floating input-group-sm select_box">
                        <input className="form-control" id="floatingTextarea" list="datalistOptions1" placeholder="Select Article Num" value={qtyForm.art_no} name='art_no' onInput={handleInput} autoComplete="off" />
                        <datalist id="datalistOptions1" >
                            {tests && tests.map((option, index) => (
                                option.enable ?
                                    <option key={index} value={option.art_no} />
                                    :
                                    null
                            ))}
                        </datalist>
                        <label htmlFor="floatingTextarea">Select Article Num</label>
                        <p className="text-danger">{formValidationError['art_no']}</p>
                    </form>
                    {/* </FloatingLabel> */}
                    <FloatingLabel label="">
                        <form className="form-floating input-group-sm select_box">
                            <input className="form-control" id="elec_mech_list" list="datalistOptions2" placeholder="Select Elect/Mech" defaultValue={qtyForm.po_sample} name='po_sample' onInput={handleInput} autoComplete="off" />
                            <datalist id="datalistOptions2">
                                <option key="Pieces" value="Pieces" />
                                <option key="Percentage" value="Percentage" />
                            </datalist>
                            <label htmlFor="elec_mech_list">Select Test Sampling</label>
                            <p className="text-danger">{formValidationError['po_sample']}</p>
                        </form>
                    </FloatingLabel>

                </section>
                <section className='masterbody'>
                    <FloatingLabel label="Received Qty">
                        <Form.Control name='rec_qty' type="number" onInput={handleInput} placeholder="none" autoComplete="off" />
                        <p className="text-danger">{formValidationError['rec_qty']}</p>
                    </FloatingLabel>
                    <FloatingLabel label="Ordered Qty">
                        <Form.Control name='order_qty' type="number" onInput={handleInput} placeholder="none" autoComplete="off" />
                    </FloatingLabel>
                    <FloatingLabel label="Recieved Date">
                        <Form.Control name='rec_date' type="date" onInput={handleInput} placeholder="none" autoComplete="off" />
                        <p className="text-danger">{formValidationError['rec_date']}</p>
                    </FloatingLabel>
                    <FloatingLabel label="Sample No/Size">
                        <Form.Control name='sam_qty' type="number" onInput={handleInput} onBlur={() => {
                            if (qtyForm.rec_qty && qtyForm.sam_qty) {
                                // console.log(1)
                                let res = 0
                                res = qtyForm.po_sample === "Percentage" ? Math.round((Number(qtyForm.sam_qty) / 100) * Number(qtyForm.rec_qty)) : qtyForm.sam_qty
                                if (res === 0) {
                                    res = 1
                                }
                                return setQtyForm({ ...qtyForm, 'qty_check': String(res) })
                            }
                        }} placeholder="none" autoComplete="off" />
                        <p className="text-danger">{formValidationError['sam_qty']}</p>
                    </FloatingLabel>
                </section>
                <section className='masterbody'>
                    <FloatingLabel label="">
                        <form className="form-floating input-group-sm select_box">
                            <input className="form-control" id="floatingTextarea" list="datalistOptions3" placeholder="Select Article Num" value={qtyForm.supp_name} name='supp_name' onInput={handleInput} autoComplete="off" />
                            <datalist id="datalistOptions3" >
                                {supp && supp.map((option, index) => (
                                    option.enable ?
                                        < option key={index} value={option.supp_name} />
                                        :
                                        null
                                ))}
                            </datalist>
                            <label htmlFor="floatingTextarea">Select Supplier</label>
                            <p className="text-danger">{formValidationError['supp_name']}</p>
                        </form>
                    </FloatingLabel>
                    <FloatingLabel label="Bill Num">
                        <Form.Control name='bill_no' type="text" onInput={handleInput} placeholder="none" autoComplete="off" />
                        <p className="text-danger">{formValidationError['bill_no']}</p>
                    </FloatingLabel>
                    <FloatingLabel label="">
                        <form className="form-floating input-group-sm select_box">
                            <input className="form-control" id="floatingTextarea" list="datalistOptions4" placeholder="Select Article Num" value={qtyForm.cust_proj} name='cust_proj' onInput={handleInput} autoComplete="off" />
                            <datalist id="datalistOptions4" >
                                {cust?.map((option, index) => (
                                    <option key={index} value={option} />
                                    :
                                null
                            ))}
                            </datalist>
                            <label htmlFor="floatingTextarea">Select Customer</label>
                            <p className="text-danger">{formValidationError['cust_proj']}</p>
                        </form>
                    </FloatingLabel>
                    <FloatingLabel label="Total Qty to test">
                        <Form.Control id='qty_check' name='qty_check' type="text" value={qtyForm.qty_check} placeholder="none" autoComplete="off" disabled />
                        <p className="text-danger">{formValidationError['qty_check']}</p>
                    </FloatingLabel>
                </section>
                <section className='masterbtn'>
                    <div >
                        <Button className='testBtn' type="primary" onClick={handleSubmit}>Start Testing</Button>
                    </div>
                </section>
            </div>
        </div>
    )
}
