import React from 'react'
import { Button, message, Spin } from 'antd'
import { useEffect } from 'react'
import { useState } from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'
import axios from '../../../../api/axios'
import DataTable from 'react-data-table-component'
import moment from 'moment';
import { ReportsNav } from '../helper/ReportsNav'
import { writeXLSX, utils, writeFileXLSX } from "xlsx";
import { socket } from '../../../../services/socket'

export const QualityReports = () => {

    const [qtyForm, setQtyForm] = useState({})
    const [art, setArt] = useState([])
    const [cust, setCust] = useState([])
    const [supp, setSupp] = useState([])
    const [data, setData] = useState({
        data1: [],
        data2: []
    })
    const [filteredData, setFilteredData] = useState([])
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState({
        isLoading: false,
        isDisabled: true,
        isDownloading: false,
        data: false,
    })

    const handleInput = (e) => {
        setQtyForm({ ...qtyForm, [e.target.name]: e.target.value })
        if (e.target.name === "bill_no" || e.target.name === "art_no" || e.target.name === "po_date" || e.target.name === "rec_date" || e.target.name === "supp_name" && e.target.value !== "") {
            setLoading({ ...loading, isDisabled: false })
        }
    }
    // console.log(filteredData)

    const handleSubmit = async () => {

    }

    const handleClear = () => {
        setQtyForm({})
        handleCustChange()
        document.getElementById('serchForm').reset()
    }

    const handleGet = (qtyForm) => {
        let { value } = document.getElementById('floatingSelect2')
        disp(qtyForm, value)
    }

    const disp = async (qtyForm, value) => {
        setLoading({ ...loading, isLoading: true, data: true })
        let find = { ...qtyForm }
        await axios.get('/customer/select').then((res) => {
            setCust(res.data.custs)
            if (value) {
                find["cust_proj"] = value
            } else {
                find["cust_proj"] = res.data.custs[0]
            }
        })
        // console.log(find, value)
        await axios.post('/quality/', find).then((res) => {
            setData({
                data1: [],
                data2: []
            })
            setFilteredData([])
            if (res.status === 200) {
                setData({
                    data1: res.data.quality,
                    data2: res.data.quality
                })
                setFilteredData(res.data.quality)
            }
            if (!res.data.quality?.length > 0) {
                setLoading({ ...loading, data: false })
            }
        })
        setLoading({ ...loading, isLoading: false })
    }

    useEffect(() => {
        socket.on('customerSelect', async (customers) => {
            setCust(customers)
            document.getElementById('floatingSelect2').value = customers?.[0]
            handleCustChange(customers)
        })
        socket.on('article', (articles) => {
            setArt(articles)
        })
        socket.on('supplier', (suppliers) => {
            setSupp(suppliers)
        })
        socket.on('quality', (quality) => {
            let temp = quality?.filter((item) => {
                return item?.["cust_proj"] === document?.getElementById('floatingSelect2')?.value
            })
            setData({
                data1: temp,
                data2: temp
            })
            setFilteredData(temp)
        })
    }, [socket])

    const handleCustChange = async (newCust) => {
        setQtyForm({})
        setData({
            data1: [],
            data2: []
        })
        setFilteredData([])
        document.getElementById('serchForm').reset()
        let { value } = document.getElementById('floatingSelect2')
        setLoading({ ...loading, isLoading: true, data: true })
        let find = {}
        find["cust_proj"] = newCust?.length > 0 ? newCust[0] : value
        await axios.post('/quality/', find).then((res) => {
            if (res.status === 200) {
                setData({
                    data1: res.data.quality,
                    data2: res.data.quality
                })
                setFilteredData(res.data.quality)
                setLoading({ ...loading, isLoading: false })
            }
            if (!res.data.quality?.length > 0) {
                setLoading({ ...loading, data: false })
            }
        })
        setLoading({ ...loading, isLoading: false })
    }

    const handleCatChange = async (e) => {
        let { value } = document.getElementById('floatingSelect3')
        if (value !== 'all') {
            let filteredDataTemp = [...data.data1]
            let temp = filteredDataTemp.filter((item) => {
                return String(item.catName).toLowerCase() === String(value).toLocaleLowerCase()
            })
            setData({
                ...data,
                data2: temp
            })
            if (!temp?.length > 0) {
                setLoading({ ...loading, data: false })
            }
        } else {
            setData({
                ...data,
                data2: data.data1
            })
        }
    }

    const handleCatSubTypeChange = async (e) => {
        let { value } = document.getElementById('floatingSelect4')
        if (value !== 'all') {
            let filteredDataTemp = [...data.data1]
            let find = {}
            find["cust_proj"] = document.getElementById('floatingSelect2').value
            find["catName"] = document.getElementById('floatingSelect3').value
            let temp = filteredDataTemp.filter((item) => {
                return String(item.catName).toLowerCase() === String(find.catName).toLocaleLowerCase()
            }).filter((item) => {
                return String(item.catSubType).toLowerCase() === String(value).toLocaleLowerCase()
            })
            setData({
                ...data,
                data2: temp
            })
            if (!temp?.length > 0) {
                setLoading({ ...loading, data: false })
            }
        } else {
            handleCatChange()
        }
    }

    const convertArrayOfObjectsToXLSX = (array) => {
        let excelResult = []
        let row = { 'param_desc': '', 'elec_mech': '', 'min_value': '', 'mesaured_val': '', 'instr_model': '', 'asset_id': '', 'instr_name': '', 'max_value': '', 'result': '', 'cal_date': '', 'art_no': '', 'test_desc': '', 'param_desc-0': '', 'po_num': '', 'po_date': '', 'po_sample': '', 'rec_qty': '', 'order_qty': '', 'rec_date': '', 'sam_qty': '', 'supp_name': '', 'bill_no': '', 'cust_proj': '', 'qty_check': '', 'date': '' }
        const keys = Object.keys(array?.[0]);
        const keys2 = Object.keys(array?.[0]?.tested_data?.[0]?.[0])
        keys.forEach((item) => {
            const checkIndex = (val) => {
                if (val === "tested_data") {
                    return val === "tested_data"
                }
                else if (val === "__v") {
                    return val === "__v"
                }
                else if (val === "_id") {
                    return val === "_id"
                } else {
                    return null
                }
            }
            let index1 = keys.findIndex(checkIndex)
            if (index1 !== -1) {
                keys.splice(index1, 1)
            }
        })

        keys2.forEach(() => {
            const checkIndex = (item) => {
                if (item === "ref_value") {
                    return item === "ref_value"
                }
                else if (item === "tolerance") {
                    return item === "tolerance"
                } else {
                    return null
                }
            }
            let index2 = keys2.findIndex(checkIndex)
            if (index2 !== -1) {
                if (keys2[index2] === "ref_value") {
                    keys2[index2] = "min_value"
                } else if (keys2[index2] === 'tolerance') {
                    keys2[index2] = 'max_value'
                }
            }
        })

        array.forEach(item2 => {
            item2.tested_data.map((item3, i) => {
                item3.forEach(ele => {
                    keys2.forEach(key => {
                        if (key === 'min_value') {
                            row['min_value'] = ele['ref_value']
                        } else if (key === 'max_value') {
                            row['max_value'] = ele['tolerance']
                        } else if (key === 'cal_date') {
                            if (ele['cal_date']) {
                                row['cal_date'] = moment(ele['cal_date']).utc().format("DD-MM-YYYY")
                            } else {
                                row['cal_date'] = ""
                            }
                        } else {
                            row[key] = ele[key]
                        }
                    });
                    keys.forEach(key => {
                        if (key === 'po_date') {
                            row[key] = moment(item2[key]).utc().format("DD-MM-YYYY")
                        } else if (key === 'rec_date') {
                            row[key] = moment(item2[key]).utc().format("DD-MM-YYYY")
                        } else if (key === 'date') {
                            row[key] = moment(item2[key]).utc().format("DD-MM-YYYY")
                        } else {
                            row[key] = item2[key]
                        }
                    });
                    excelResult.push(row)
                    row = { 'param_desc': '', 'elec_mech': '', 'min_value': '', 'mesaured_val': '', 'instr_model': '', 'asset_id': '', 'instr_name': '', 'max_value': '', 'result': '', 'cal_date': '', 'art_no': '', 'test_desc': '', 'param_desc-0': '', 'po_num': '', 'po_date': '', 'po_sample': '', 'rec_qty': '', 'order_qty': '', 'rec_date': '', 'sam_qty': '', 'supp_name': '', 'bill_no': '', 'cust_proj': '', 'qty_check': '', 'date': '' }
                })
            })
        });
        return excelResult;

    }

    // const downloadXLSX = () => {
    //     const link = document.createElement('a');
    //     if (filteredData.length === 0) {
    //         alert(msg)
    //     }
    //     let filename = "";
    //     const keys = Object.keys(qtyForm)
    //     if (keys.length !== 0) {
    //         keys.forEach((key, i) => {
    //             if (i !== keys.length - 1) {
    //                 filename += `${qtyForm[key]}_`
    //             } else {
    //                 filename += `${qtyForm[key]}`
    //                 filename += `_Quality Report`
    //             }
    //         })
    //     } else {
    //         filename += `Quality Report`
    //     }

    //     filename = filename + '.csv'
    //     let csv = convertArrayOfObjectsToXLSX(filteredData);
    //     if (csv == null) return;
    //     // let filename = 'export.csv';

    //     if (!csv.match(/^data:text\/csv/i)) {
    //         csv = `data:text/csv;charset=utf-8,${csv}`;

    //     }
    //     link.setAttribute('href', encodeURI(csv));
    //     link.setAttribute('download', filename);
    //     link.click();
    // }

    // const Export = ({ onExport }) => <Button type='primary' onClick={e => onExport(e.target.value)} style={{ zIndex: '500' }}>Export</Button>;

    const downloadXLSX = () => {
        setLoading({ ...loading, isDownloading: true })
        let data = convertArrayOfObjectsToXLSX(filteredData)
        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        let filename = "";
        const keys = Object.keys(qtyForm)
        if (keys.length !== 0) {
            keys.forEach((key, i) => {
                if (i !== keys.length - 1) {
                    filename += `${qtyForm[key]}_`
                } else {
                    filename += `${qtyForm[key]}`
                    filename += `_Quality Report`
                }
            })
        } else {
            filename += `Quality Report`
        }
        filename = filename + '.xlsx'
        writeFileXLSX(wb, filename);
        setLoading({ ...loading, isDownloading: false })
    }

    const columns = [
        {
            name: "art_no",
            cell: row => row.art_no,
        },
        {
            name: 'Product Group',
            cell: row => row.catName,
        },
        {
            name: 'Type',
            cell: row => row.catSubType,
        },
        {
            name: "test_desc",
            cell: row => row.test_desc
        },
        {
            name: "po_date",
            cell: row => moment(row.po_date).utc().format("DD-MM-YYYY")
        },
        {
            name: "po_sample",
            cell: row => row.po_sample
        },
        {
            name: "po_num",
            cell: row => row.po_num
        },
        {
            name: "rec_qty",
            cell: row => row.rec_qty
        },
        {
            name: "order_qty",
            cell: row => row.order_qty
        },
        {
            name: "rec_date",
            cell: row => moment(row.rec_date).utc().format("DD-MM-YYYY")
        },
        {
            name: "sam_qty",
            cell: row => row.sam_qty
        },
        {
            name: "supp_name",
            cell: row => row.supp_name
        },
        {
            name: "bill_no",
            cell: row => row.bill_no
        },
        {
            name: "cust_proj",
            cell: row => row.cust_proj
        },
        {
            name: "qty_check",
            cell: row => row.qty_check
        },
        {
            name: "date",
            cell: row => moment(row.date).utc().format("DD-MM-YYYY")
        },
    ]


    useEffect(() => {
        disp()
        axios.get('/article/').then((res) => {
            setArt(res.data.articles)
        }).catch((e) => {
            console.log(e)
        })
        axios.get('/supplier/all').then((res) => {
            setSupp(res.data.supps)
        }).catch((e) => {
            console.log(e)
        })
        axios.get('/category/').then((res) => {
            if (res.status === 200) {
                setCategories(res.data.categories)
            }
        }).catch((e) => {
            console.log(e)
        })
    }, [])

    return (
        <div className='mainHeight'>
            <div>
                <ReportsNav name='quality' />
                <h3 className='text-center'>Quality Report</h3>
            </div>
            <div className='d-flex justify-content-center p-2'>
                <div className="form-floating filtercust">
                    <select className="form-select" id='floatingSelect2' name='cust_proj' onChange={(e) => handleCustChange(e)}>
                        {cust?.map((option, index) => {
                            return <option key={index} value={option} >{option}</option>
                        })}
                    </select>
                    <label htmlFor="floatingSelect2">Select Customer</label>
                </div>
                <div className="form-floating filtercust">
                    <select className="form-select" id='floatingSelect3' name='catName' defaultValue='all' onChange={(e) => handleCatChange(e)}>
                        <option value='all'>All</option>
                        {
                            [...new Set(categories?.reduce((arr, item) => {
                                if (item?.enable) {
                                    arr[0].push(item.catName)
                                } else {
                                    arr[1].push(item.catName)
                                }
                                return arr
                            }, [[], []])?.[0])]?.map((option, index) => {
                                return <option key={index} value={option}>{option}</option>
                            })
                        }
                    </select>
                    <label htmlFor="floatingSelect3">Select Product Group</label>
                </div>
                <div className="form-floating filtercust">
                    <select className="form-select" id='floatingSelect4' name='catSubType' defaultValue='all' onChange={(e) => handleCatSubTypeChange(e)}>
                        <option value='all'>All</option>
                        {
                            categories?.filter((item1) => {
                                if (item1?.enable) {
                                    return String(item1["catName"]).toLowerCase() === (String(document.getElementById("floatingSelect3").value).toLocaleLowerCase())
                                } else {
                                    return false
                                }
                            }).map((item2) => {
                                if (item2?.enable) {
                                    return item2.catType
                                }
                            })?.map((option, index) => {
                                return <option key={index} value={option}>{option}</option>
                            })
                        }
                    </select>
                    <label htmlFor="floatingSelect4">Select Type</label>
                </div>
            </div>
            <div className='card'>
                <section >
                    <form id='serchForm' className='masterbody qtyRep' >
                        <FloatingLabel label="">
                            <input className="form-control" id="floatingTextarea" list="datalistOptions1" placeholder="Select Article Num" value={qtyForm.art_no} name='art_no' onChange={(e) => handleInput(e)} autoComplete="off" />
                            <datalist id="datalistOptions1" >
                                {
                                    art?.map((option, i) => {
                                        return <option key={i} value={option.art_no}></option>
                                    })
                                }
                            </datalist>
                            <label htmlFor="floatingTextarea">Select Article Num</label>
                        </FloatingLabel>
                        <FloatingLabel label="Bill Num">
                            <Form.Control name='bill_no' type="text" value={qtyForm.bill_no} onChange={(e) => handleInput(e)} placeholder="none" autoComplete="off" />
                        </FloatingLabel>
                        <FloatingLabel label="Select PO Date">
                            <Form.Control name='po_date' type="date" value={qtyForm.po_date} onChange={(e) => handleInput(e)} placeholder="none" autoComplete="off" />
                        </FloatingLabel>
                        <FloatingLabel label="Recieved Date">
                            <Form.Control name='rec_date' type="date" value={qtyForm.rec_date} onChange={(e) => handleInput(e)} placeholder="none" autoComplete="off" />
                        </FloatingLabel>
                        <FloatingLabel label="">
                            <input className="form-control" id="floatingTextarea" list="datalistOptions3" placeholder="Select Article Num" defaultValue={qtyForm.supp_name} name='supp_name' onChange={(e) => handleInput(e)} autoComplete="off" />
                            <datalist id="datalistOptions3" >
                                {
                                    supp?.map((option, i) => {
                                        return <option key={i} value={option.supp_name}></option>
                                    })
                                }
                            </datalist>
                            <label htmlFor="floatingTextarea">Select Supplier</label>
                        </FloatingLabel>
                    </form>

                </section>

                <section className='masterbtn'>
                    <div >
                        {/* <Button className='testBtn' type="primary" onClick={handleSubmit}>Get Report</Button> */}
                        {/* {actionsMemo} */}
                        <Button type="primary" onClick={() => handleGet(qtyForm)} loading={loading.isLoading} disabled={loading.isDisabled}>Get Report</Button>
                        <Button type="primary" onClick={() => handleClear()}>Clear</Button>
                    </div>
                </section>
            </div>
            <div className='card'>
                <DataTable
                    columns={columns}
                    data={data.data2}
                    pagination
                    fixedHeader
                    paginationDefaultPage={1}
                    fixedHeaderScrollHeight="380px"
                    highlightOnHover
                    selectableRows
                    selectableRowsHighlight
                    onSelectedRowsChange={(row) => setFilteredData(row.selectedRows)}
                    // selectableRowSelected={(row) => row.isSelected}
                    // selectableRowSelected={filteredData}
                    noDataComponent={loading.data ? <div className='p-3'><Spin /></div> : <div className='p-3'>No Data</div>}
                    actions
                    style={{ zIndex: '499' }}
                />
            </div>
            <Button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => downloadXLSX()} loading={loading.isDownloading}>Export</Button>
        </div>
    )
}
