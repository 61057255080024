import React, { useEffect, useRef, useState } from 'react'
import { PlotsNav } from '../helper/PlotsNav'
import { Button, DatePicker, message } from 'antd'
import Chart from "chart.js/auto";
import { Bar, Line, Radar } from 'react-chartjs-2';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import axios from '../../../../api/axios';
import moment from 'moment';
Chart.register(ChartjsPluginStacked100);


const { RangePicker } = DatePicker;

export const WoInProgressPlot = () => {
    const [ChartData, setChartData] = useState(Bar)
    const chartRef = useRef(null)
    const d = new Date()
    const [isLoading, setIsLoading] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [dailyWeekly, setDailyWeekly] = useState('Daily')
    const [dates, setDates] = useState(null);
    const [hackValue, setHackValue] = useState(null);
    const [value, setValue] = useState([moment(d, "DD/MM/YYYY"), moment(d, "DD/MM/YYYY")]);
    const [dataOptions, setDataOptions] = useState({})

    const getPlots = async () => {
        setIsLoading(true)
        let find = {}
        if (value) {
            find.fromDate = moment(value[0]._d).utc().format("DD-MM-YYYY")
            find.toDate = moment(value[1]._d).utc().format("DD-MM-YYYY")
        } else {
            find.fromDate = moment(d).utc().format("DD-MM-YYYY")
            find.toDate = moment(d).utc().format("DD-MM-YYYY")
        }
        await axios.post('/plot/comp', find).then((res) => {
            if ((Object.keys(res.data[0])).length > 0) {
                setVariableData({
                    mainLabel1: Object.keys(res.data[0]),
                    dataSetLabel1: 'WO Quantity',
                    datSetData1: Object.keys(res.data[0])?.map((key) => res.data[0][key][0]),
                    dataSetLabel2: 'Shortage',
                    datSetData2: Object.keys(res.data[0])?.map((key) => res.data[0][key][1]),
                    dataSetLabel3: 'Rejection',
                    datSetData3: Object.keys(res.data[0])?.map((key) => res.data[0][key][2]),
                })
            } else {
                message.warning("Data not found")
                setVariableData({
                    mainLabel1: [],
                    dataSetLabel1: 'WO Quantity',
                    datSetData1: [],
                    dataSetLabel2: 'Shortage',
                    datSetData2: [],
                    dataSetLabel3: 'Rejection',
                    datSetData3: [],
                })
            }
        }).catch((e) => {
            console.log(e)
        })
        setDataOptions({
            scales: {
                yAxis: {
                    title: {
                        display: true,
                        text: 'Quantity in numbers',
                        font: {
                            size: 16,
                        },
                    },
                    ticks: {
                        beginAtZero: true,
                    },
                },
                xAxis: {
                    // stacked: true,
                    title: {
                        display: true,
                        text: 'Work order numbers',
                        font: {
                            size: 16,
                        },
                    },
                },
            },
            plugins: {
                stacked100: {
                    enable: true,
                },
                legend: {
                    labels: {
                        font: {
                            size: 16,
                        },
                    },
                },
                title: {
                    display: true,
                    text: `WO In-progress Plot`,
                    font: {
                        size: 25,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: label,
                    }
                }
            },
            responsive: true,
        })
        setIsLoading(false)
    }

    const [variableData, setVariableData] = useState({
        mainLabel1: [],
        dataSetLabel1: 'WO In Progress',
        datSetData1: [],
        dataSetLabel2: 'Shortage',
        datSetData2: [],
        dataSetLabel3: 'Rejection',
        datSetData3: [],
    })

    let aaa = {}
    if (variableData) {
        aaa = {
            labels: variableData.mainLabel1,
            datasets: [
                {
                    label: variableData.dataSetLabel1,
                    data: variableData.datSetData1,
                    backgroundColor: [
                        'rgba(129, 91, 91,.5)'

                        // 'rgba(0,128,0,0.5)',
                    ],
                    borderColor: [
                        // 'rgba(129, 198, 232, 1)'
                        'rgba(129, 91, 91,1)'
                        // 'rgba(0,128,0,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
                {
                    label: variableData.dataSetLabel2,
                    data: variableData.datSetData2,
                    backgroundColor: [
                        'rgba(255,165,0,0.5)',
                    ],
                    borderColor: [
                        'rgba(255,69,0,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
                {
                    label: variableData.dataSetLabel3,
                    data: variableData.datSetData3,
                    backgroundColor: [
                        'rgba(242, 38, 19,0.5)',
                    ],
                    borderColor: [
                        'rgba(242, 38, 19,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
            ]
        }
    }

    const label = (tooltipItems) => {
        const data = tooltipItems.chart.data;
        const datasetIndex = tooltipItems.datasetIndex;
        const index = tooltipItems.dataIndex;
        const datasetLabel = data.datasets[datasetIndex].label || "";
        // You can use two type values.
        // `data.originalData` is raw values,
        // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)
        const originalValue = data.originalData[datasetIndex][index];
        const rateValue = data.calculatedData[datasetIndex][index];
        return `${datasetLabel}: ${originalValue} (${rateValue}%)`;
    }

    const [userData, setUserData] = useState(aaa)


    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }
        if (dailyWeekly === 'Monthly') {
            const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
            const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
            return !!tooEarly || !!tooLate;
        } else if (dailyWeekly === 'Weekly') {
            const tooLate = dates[0] && current.diff(dates[0], 'days') > 6;
            const tooEarly = dates[1] && dates[1].diff(current, 'days') > 6;
            return !!tooEarly || !!tooLate;
        } else if (dailyWeekly === 'Daily') {
            const tooLate = dates[0] && current.diff(dates[0], 'days') > 0;
            const tooEarly = dates[1] && dates[1].diff(current, 'days') > 0;
            return !!tooEarly || !!tooLate;
        }
    };

    const onOpenChange = (open) => {
        if (open) {
            setHackValue([null, null]);
            setDates([null, null]);
        } else {
            setHackValue(null);
        }
    };
    const handleDownload = () => {
        setIsDownloading(true)
        const data = chartRef.current.toBase64Image()
        let filename = "";
        var time = d.getHours() + "-" + d.getMinutes() + "-" + d.getSeconds();
        filename += `${moment(value[0]._d).utc().format("DD-MM-YYYY")} to ${moment(value[1]._d).utc().format("DD-MM-YYYY")}`
        filename += `_${time}_${d.toDateString()}_WO In-progress Plot`
        filename = filename + '.png'
        const link = document.createElement('a');
        link.download = filename;
        link.href = chartRef.current.toBase64Image();
        link.click();
        setIsDownloading(false)
    }

    useEffect(() => {
        setUserData(aaa)
    }, [variableData, ChartData])

    return (
        <div className='mainHeight'>
            <div>
                <PlotsNav name='WO in-progress' />
            </div>
            <div className="m-auto p-3">
                <div className='woInProgressInputs'>
                    <div className="form-floating" style={{ width: '100%' }}>
                        <select className="form-select" id='floatingSelect4' onChange={(e) => setDailyWeekly(e.target.value)} defaultValue="Daily">
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                        </select>
                        <label htmlFor="floatingSelect4">Select Daily / Weekly / Monthly</label>
                    </div>
                    <div className='w-100 d-grid'>
                        <RangePicker
                            value={hackValue || value}
                            disabledDate={disabledDate}
                            onCalendarChange={(val) => setDates(val)}
                            onChange={(val) => setValue(val)}
                            onOpenChange={onOpenChange}
                            format={"DD/MM/YYYY"}
                            placement="bottomRight"
                            inputReadOnly={true}
                        />
                    </div>
                    <Button type='primary' onClick={() => getPlots()} loading={isLoading}>Plot</Button>
                    <Button type='primary' onClick={() => handleDownload()} loading={isDownloading}>Download Chart</Button>
                </div>
                <div className='m-auto p-1 d-flex flex-column align-items-center chartWidth' >
                    <ChartData id='myChart' data={userData} options={dataOptions} ref={chartRef} />
                </div>
            </div>
        </div >
    )
}
