import React, { useEffect, useState } from 'react'
import axios from '../../../../api/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { FloatingLabel, Form } from 'react-bootstrap';
import { Button, Drawer, message, Modal } from 'antd';
import { Backdrop, CircularProgress } from '@mui/material'
import moment from 'moment';

const Instrs = (inst, setInstrs) => {
    let instrs = {}
    inst.forEach((ins) => {
        if (ins !== 'None') {
            axios.post('/instrument/findone', { "instr_name": ins }).then((res) => {
                instrs[ins] = res.data.instr.instrs
                setInstrs(instrs)
            })
        }
    })
}

export const QualityAlt = () => {
    const location = useLocation()
    const { qty, id, show } = location.state
    const { art_no, qty_check } = qty;
    const [testData, setTestData] = useState([])
    const [resetForm, setResetForm] = useState([])
    const [count, setCount] = useState(1)
    const [instruments, setInstruments] = useState([])
    const [tests, setTests] = useState([])
    const navigate = useNavigate()
    const [visible, setVisible] = useState(show);
    const [isElecModalVisible, setIsElecModalVisible] = useState(false);
    const [isMechModalVisible, setIsMechModalVisible] = useState(false);
    const [elecurl, setElecUrl] = useState(``)
    const [mechurl, setMechUrl] = useState(``)
    const [loading, setLoading] = useState(false)

    const showModal = (name) => {
        if (name === "eDraw") {
            setIsElecModalVisible(true);
            // setElecUrl(`http://localhost:8000/testplan/avatar/${tests?.art_no}/${"eDraw"}`)
            setElecUrl(`https://snsindustries.co.in/api/testplan/avatar/${tests?.art_no}/${"eDraw"}`)

        } else {
            setIsMechModalVisible(true)
            // setMechUrl(`http://localhost:8000/testplan/avatar/${tests?.art_no}/${"mDraw"}`)
            setMechUrl(`https://snsindustries.co.in/api/testplan/avatar/${tests?.art_no}/${"mDraw"}`)
        }
    };
    const handleOk = () => {
        setIsElecModalVisible(false);
        setIsMechModalVisible(false)
    };

    const handleCancel = () => {
        setIsElecModalVisible(false);
        setIsMechModalVisible(false)
    };

    const onClose = () => {
        setVisible(false);
        navigate('/mesHome/quality')
        setElecUrl('')
        setMechUrl('')
    };

    const handleInput = (e, i) => {
        const { name, value } = e.target
        const temp = [...testData]
        temp[i][name] = value

        if (name === 'asset_id') {
            let data = Array.from(instruments[temp[i]["instr_name"]]).find((inst) => {
                return inst.asset_id === value
            })
            temp[i]['cal_date'] = data.cal_due_date
            temp[i]['instr_model'] = data.instr_model
        }

        if (name === 'mesaured_val') {
            const min = Number((temp[i]['ref_value']).substring(0, (temp[i]['ref_value']).indexOf(' ')))
            const max = Number((temp[i]['tolerance']).substring(0, (temp[i]['tolerance']).indexOf(' ')))
            let curr = {}
            if ((temp[i]['mesaured_val']).indexOf(" ") < 1) {
                curr = Number((temp[i]['mesaured_val']))
            } else {
                curr = Number((temp[i]['mesaured_val']).substring(0, (temp[i]['mesaured_val']).indexOf(' ')))
            }
            if (temp[i]['mesaured_val'] !== "") {
                if (curr < min || curr > max) {
                    temp[i]['result'] = "Fail"
                } else {
                    temp[i]['result'] = "Pass"
                }
            } else {
                temp[i]['result'] = ""
            }
        }
        setTestData(temp)
    }

    let countCheck = `${count}/${qty_check}`

    const next = async (e) => {
        // save data to db 
        await axios.post('/quality/push', { testData, id }).then((res) => {
            if (res.status === 200) {
                // reset form
                setTestData(JSON.parse(JSON.stringify(resetForm)))
                if (Number(qty_check) !== count) {
                    setCount(count + 1)
                } else {
                    onClose()
                }
            } else {
                alert('Data not saved')
            }
        }).catch((e) => {
            console.log(e)
        })
        setLoading(false)
    }

    const nextClose = (e) => {
        next()
        message.success('Test completed successfully');
    }

    const handleSubmit = async (e) => {
        e.preventDefault()


    }

    useEffect(() => {
        (async function () {
            if (count === 1) {
                let inst = []
                let instrs = []
                await axios.post('/testplan/testPlan', { art_no }).then((res) => {
                    setTests(res.data.testplan)
                    let formulatedData = res.data.testplan.parms.map((parm) => {
                        let refVal_Unit = `${parm.ref_val} ${parm.ref_unit}`
                        let tolVal_Unit = `${parm.tolerance} ${parm.tolerance_unit}`
                        inst.push(parm.instr_name)
                        if (parm.elec_mech === "elect") {
                            return {
                                "param_desc": parm.param_desc,
                                "elec_mech": parm.elec_mech,
                                "ref_value": "None",
                                "mesaured_val": "None",
                                "instr_name": parm.instr_name,
                                "instr_model": "",
                                "asset_id": "None",
                                "tolerance": "None",
                                "result": "",
                                "cal_date": "",
                                "enable": parm.enable,
                            }
                        } else {
                            return {
                                "param_desc": parm.param_desc,
                                "elec_mech": parm.elec_mech,
                                "ref_value": refVal_Unit,
                                "mesaured_val": "",
                                "instr_name": parm.instr_name,
                                "instr_model": "",
                                "asset_id": "",
                                "tolerance": tolVal_Unit,
                                "result": "",
                                "cal_date": "",
                                "enable": parm.enable,
                            }
                        }
                    })
                    inst = [...new Set(inst)]
                    Instrs(inst, setInstruments)
                    setTestData(formulatedData)
                    setResetForm(JSON.parse(JSON.stringify(formulatedData)))
                })
            }
        })()
    }, [count])


    return (
        <div style={{ padding: '10px' }}>
            {/* <p>{count}/5</p> */}
            {/* {JSON.stringify(instruments)} */}
            {/* <br /> */}
            <Drawer
                title="Quality Check"
                placement="bottom"
                size="large"
                height="100vh"
                onClose={onClose}
                open={visible}
                maskClosable={false}
            >
                <br /><br />
                <section className='QtyBody'>
                    <h4 className='text-center qtyTitle'>Quality Check</h4><hr />
                    <section className='QtyCol1'>
                        <FloatingLabel label="Article Num">
                            <Form.Control name='art_no' type="text" value={tests?.art_no} onInput={handleInput} placeholder="none" autoComplete="off" disabled />
                            {/* <p className="text-danger">{formValidationError['art_no']}</p> */}
                        </FloatingLabel>
                        <FloatingLabel label="Test Description">
                            <Form.Control
                                className='QtyTestDesc'
                                as="textarea"
                                placeholder="Leave a comment here"
                                name='test_desc'
                                value={tests?.test_desc}
                                autoComplete="off"
                                disabled
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Count">
                            <Form.Control className='QtyCount' name='qty_check' type="text" value={countCheck} placeholder="none" autoComplete="off" disabled />
                            {/* <p className="text-danger">{formValidationError['qty_check']}</p> */}
                        </FloatingLabel>
                        <Button className="DrawBtn" type="primary" onClick={() => showModal("eDraw")}>
                            E-Draw
                        </Button>
                        <Button className="" type="primary" onClick={() => showModal("mDraw")}>
                            M-Draw
                        </Button>
                    </section>
                    <hr />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <section className='QtyCol2Body'>
                        {testData?.map((data, i) => {
                            if (data.enable === true) {
                                // console.log(data.enable)
                                return <section className='QtyCol2'>
                                    <FloatingLabel label="Parameter Description">
                                        <Form.Control
                                            className='QtyTestDesc2'
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            style={{ height: '100%' }}
                                            defaultValue={data.param_desc}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </FloatingLabel>
                                    <section className='innerQtyCol2'>
                                        <div>
                                            <FloatingLabel label="Elect/Mech">
                                                <Form.Control name='elec_mech' type="text" value={data.elec_mech} onInput={(e) => handleInput(e, i)} placeholder="none" autoComplete="off" disabled />
                                            </FloatingLabel>
                                            <FloatingLabel label="Instrument">
                                                <Form.Control name='instr_name' type="text" value={data.instr_name} onInput={(e) => handleInput(e, i)} placeholder="none" autoComplete="off" disabled />
                                            </FloatingLabel>
                                        </div>
                                        <div>
                                            <FloatingLabel label="Min Val">
                                                <Form.Control name='ref_value' type="text" value={data.ref_value} onInput={(e) => handleInput(e, i)} placeholder="none" autoComplete="off" disabled />
                                            </FloatingLabel>
                                            <FloatingLabel label="Max Val">
                                                <Form.Control name='tolerance' type="text" value={data.tolerance} onInput={(e) => handleInput(e, i)} placeholder="none" autoComplete="off" disabled />
                                            </FloatingLabel>
                                        </div>
                                        <div>
                                            <FloatingLabel label="Measured val">
                                                <Form.Control name='mesaured_val' type="text" value={data.mesaured_val} onInput={(e) => handleInput(e, i)} placeholder="none" autoComplete="off" />
                                                {/* <p className="text-danger">{formValidationError['mesaured_val']}</p> */}
                                            </FloatingLabel>
                                            {/* <FloatingLabel label="Result">
                                            <Form.Control name='art_no' type="text" onInput={(e)=>handleInput(e,i)} placeholder="none" autoComplete="off" />
                                        </FloatingLabel> */}
                                            {/* <form className="form-floating input-group-sm select_box" style={{ height: "0px" }}>
                                                <input className="form-control" id="elec_mech_list" value={data.result} list="datalistOptions" placeholder="Select Elect/Mech" name='result' onInput={(e) => handleInput(e, i)} autoComplete="off" />
                                                <datalist id="datalistOptions">
                                                    <option value="Pass" />
                                                    <option value="Fail" />
                                                </datalist>
                                                <label htmlFor="elec_mech_list">Result</label>
                                                <p className="text-danger">{formValidationError['elec_mech']}</p>
                                            </form> */}
                                            {data.elec_mech === "elect" ?
                                                <form className="form-floating input-group-sm select_box" style={{ height: "0px" }}>
                                                    <input className="form-control" id="elec_mech_list" value={data.result} list="datalistOptions" placeholder="Select Elect/Mech" name='result' onInput={(e) => handleInput(e, i)} autoComplete="off" />
                                                    <datalist id="datalistOptions">
                                                        <option value="Pass" />
                                                        <option value="Fail" />
                                                    </datalist>
                                                    <label htmlFor="elec_mech_list">Result</label>
                                                </form>
                                                :
                                                <FloatingLabel label="Result">
                                                    <Form.Control name='result' type="text" value={data.result} onInput={(e) => handleInput(e, i)} placeholder="none" autoComplete="off" disabled />
                                                </FloatingLabel>
                                            }
                                        </div>
                                        <div>
                                            {data.elec_mech === "elect" ?
                                                <FloatingLabel label="Inst Model">
                                                    <Form.Control name='asset_id' type="text" value={data.asset_id} onInput={(e) => handleInput(e, i)} placeholder="none" autoComplete="off" />
                                                </FloatingLabel>
                                                :
                                                <div className="form-floating">
                                                    <select className="form-select" id="floatingSelect" defaultValue={data.instr_model} aria-label="Floating label select example" name="asset_id" onInput={(e) => handleInput(e, i)}>
                                                        <option value="Select Model">Select Model</option>
                                                        {instruments[data['instr_name']] ?
                                                            Array.from((instruments[data['instr_name']])).map((inst, i) => {
                                                                return <option value={inst.asset_id}>{`${inst.instr_model}-${inst.asset_id}`} </option>
                                                            })
                                                            : <option value='none'>None</option>
                                                        }
                                                    </select>
                                                    <label htmlFor="floatingSelect">Inst Model</label>
                                                </div>
                                            }
                                            <FloatingLabel label="Cal Date">
                                                {data['cal_date'] ?
                                                    <Form.Control name='cal_date' type="text" defaultValue={moment(data['cal_date'])?.utc().format("DD-MM-YYYY")} placeholder="none" autoComplete="off" onInput={(e) => handleInput(e, i)} readOnly />
                                                    :
                                                    <Form.Control name='cal_date' type="text" defaultValue="" placeholder="none" autoComplete="off" disabled />
                                                }
                                            </FloatingLabel>
                                        </div>
                                    </section>
                                    {/* <FloatingLabel label="Result">
                                        <Form.Control name='param_desc' type="text" defaultValue={data.param_desc}  autoComplete="off" />
                                    </FloatingLabel> */}
                                    <FloatingLabel label="Parameter Description">
                                        <Form.Control
                                            className='QtyTestDesc2'
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            style={{ height: '100%' }}
                                            name='param_desc'
                                            defaultValue={data.param_desc}
                                            onInput={(e) => handleInput(e, i)}
                                            autoComplete="off"
                                        />
                                    </FloatingLabel>
                                </section>
                            }
                        })


                        }
                    </section>
                </section>
                {((count) < (Number(qty_check))) ?
                    <input type="button" value="Next" onClick={() => { setLoading(true); next() }} className="btn btn-primary" />
                    :
                    <input type="button" value="Save & Close" onClick={() => nextClose()} className="btn btn-primary" />

                }
            </Drawer>
            <Modal
                title={<h4>E-Draw</h4>}
                centered
                open={isElecModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                keyboard={false}
            >
                <div id="pdfId1" style={{ width: '90%', aspectRatio: '1', border: '1px' }}>
                    {/* <embed style={{ width: '100%', aspectRatio: '1' }} src={url} type='application/pdf' alt="" /> */}
                    <iframe style={{ width: '90%', aspectRatio: '1' }} src={elecurl} type='application/pdf' frameborder="0"></iframe>
                </div>
            </Modal>
            <Modal
                title={<h4>M-Draw</h4>}
                centered
                open={isMechModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                keyboard={false}
            >
                <div id="pdfId2" className='d-flex justify-content-center' style={{ width: '90%', aspectRatio: '1', border: '1px' }}>
                    {/* <embed style={{ width: '100%', aspectRatio: '1' }} src={url} type='application/pdf' alt="" /> */}
                    <iframe style={{ width: '90%', aspectRatio: '1' }} src={mechurl} type='application/pdf' frameborder="0"></iframe>
                </div>
            </Modal>
        </div>
    )
}
