import React, { useState, useEffect } from 'react'
import { Modal, FloatingLabel, Form } from 'react-bootstrap';
import { useGetUserDetails } from '../../../hooks/useGetUserDetails';
import { Button, Checkbox, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { formValidator } from '../../../services/formValidator';
import axios from '../../../api/axios';

message.config({ top: '80%' })
export const PPOWO = ({ ppo }) => {
    const ppos = (ppo)
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const woDefaultDate = new Date().toISOString().split("T")[0];
    const initialWoInput = { woDate: woDefaultDate };
    const [woInput, setWoInput] = useState(initialWoInput);
    const [cust, setCust] = useState([])
    const [artNums, setArtNums] = useState(ppo["article#"]);
    const [pposNos, setPposNos] = useState(ppo["ppo#"]);
    const [pposItems, setPposItems] = useState(ppo["item#"]);
    const [formValidationError, setFormValidationError] = useState({});
    const { user } = useGetUserDetails()
    const [isDisabled, setIsDisabled] = useState(true)

    const handleClose = () => {
        setShow(false);
        setWoInput(initialWoInput);
    };

    const handleWOsubmit = async (e) => {
        e.preventDefault();
        let formError = formValidator(woInput, {
            "wo#": "WO Number is required",
            woDate: "WO Date is Required",
            wo_qty: "WO Qty is required",
            "cust_proj": "Customer is required",
        })
        setFormValidationError(formError)
        if (Object.keys(formError).length === 0) {
            await axios
                .post("/wo/add", { ...woInput, createdBy: user.email })
                .then((res) => {
                    if (res.status === 200) {
                        handleClose()
                        return message.success({ content: 'Wo Added successfully', style: { zIndex: 99 } });
                    }
                    return message.error({ content: 'Unable to add WO', style: { zIndex: 99 } });
                })
                .catch((e) => {
                    message.error({ content: 'Unable to add WO', style: { zIndex: 99 } });
                });
        }
        setIsDisabled(true)
    };

    const handleWOInput = (e) => {
        setWoInput({ ...woInput, "ppoItem": `${ppo['ppo#']}-${ppo['item#']}`, "ppo_qty": ppo.qty, "catName": ppo.catName, "catSubType": ppo.catSubType, "article#": ppo['article#'], "cust_proj": ppo['cust_proj'], [e.target.name]: e.target.value });
    }

    const handleChecked = (e) => {
        setIsDisabled(!e.target.checked)
    }

    const disp = async () => {
        await axios.get('/customer/select').then((res) => {
            setCust(res.data.custs)
        })
    };

    useEffect(() => {
        disp()
    }, [])

    return (
        <div>
            <Button type="primary" shape="circle" icon={<PlusOutlined />} size="default" onClick={handleShow} />
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size="sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title>New W.O</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex gap-2 my-2">
                        <FloatingLabel style={{ width: "70vw" }} className="my-2 plc1" label="PPO Item#">
                            <Form.Control
                                type="text"
                                placeholder="PPO Item#"
                                name='ppoItem'
                                value={`${ppo['ppo#']}-${ppo['item#']}`}
                                onChange={handleWOInput}
                                autoComplete="off"
                                disabled
                            />
                        </FloatingLabel>
                        <FloatingLabel style={{ width: "30vw" }} className="my-2 plc2" label="PPOQty">
                            <Form.Control
                                type="text"
                                placeholder="PPO qty"
                                name='qty'
                                value={ppo.qty}
                                onChange={handleWOInput}
                                autoComplete="off"
                                disabled
                            />
                        </FloatingLabel>
                    </div>
                    <div className="d-flex gap-2 my-2">
                        <FloatingLabel style={{ width: "50vw" }} className="my-2 plc1" label="Category">
                            <Form.Control
                                type="text"
                                placeholder="PPO Item#"
                                name='catName'
                                value={ppo['catName']}
                                onChange={handleWOInput}
                                autoComplete="off"
                                disabled
                            />
                        </FloatingLabel>
                        <FloatingLabel style={{ width: "50vw" }} className="my-2 plc2" label="Sub Type">
                            <Form.Control
                                type="text"
                                placeholder="PPO qty"
                                name='catSubType'
                                value={ppo['catSubType']}
                                onChange={handleWOInput}
                                autoComplete="off"
                                disabled
                            />
                        </FloatingLabel>
                    </div>
                    <FloatingLabel className='mb-3' controlId="floatingSelect" label="article#">
                        <Form.Control
                            type="text"
                            placeholder="article#"
                            name='article#'
                            value={ppo['article#']}
                            onChange={handleWOInput}
                            autoComplete="off"
                            disabled
                        />
                    </FloatingLabel>
                    <FloatingLabel className='mb-3' controlId="floatingSelect" label="Customer">
                        <Form.Control
                            type="text"
                            placeholder="Customer"
                            name='cust_proj'
                            value={ppo['cust_proj']}
                            onChange={handleWOInput}
                            autoComplete="off"
                            disabled
                        />
                    </FloatingLabel>
                    {/* <form className="form-floating input-group-sm select_box" >
                        <input className="form-control" id="floatingTextarea" list="datalistOptions2" placeholder=" " name='cust_proj' onInput={handleWOInput} autoComplete="off" />
                        <datalist id="datalistOptions2" >
                            {cust?.map((option, index) => {
                                return <option key={index} value={option} />
                            })}
                        </datalist>
                        <label for="floatingTextarea">Select Customer</label>
                        <p className="text-danger">{formValidationError['cust_proj']}</p>
                    </form> */}
                    <div className="d-flex gap-2 mt-2">
                        <FloatingLabel style={{ width: "70vw" }} label="WO#">
                            <Form.Control
                                type="text"
                                placeholder="woNum"
                                name='wo#'
                                onChange={handleWOInput}
                                autoComplete="off"
                            />
                            <p className='text-danger'>{formValidationError["wo#"]}</p>
                        </FloatingLabel>
                        <FloatingLabel style={{ width: "30vw" }} label="WOQty">
                            <Form.Control
                                type="number"
                                placeholder="qty"
                                name='wo_qty'
                                onChange={handleWOInput}
                                autoComplete="off"
                            />
                            <p className='text-danger'>{formValidationError["wo_qty"]}</p>

                        </FloatingLabel>
                    </div>
                    <FloatingLabel className="mb-3" label="WO Date#">
                        <Form.Control
                            type="date"
                            placeholder="woDate"
                            name="woDate"
                            onChange={handleWOInput}
                            autoComplete="off"
                            min={woDefaultDate}
                            defaultValue={woDefaultDate}
                        />
                    </FloatingLabel>
                    <div className="d-flex gap-2 mt-2 border rounded">
                        <FloatingLabel style={{ width: "70vw" }} className="m-auto" label="Serial#">
                            <Form.Control
                                type="text"
                                placeholder="serialNo"
                                name="serial#"
                                onChange={handleWOInput}
                                autoComplete="off"
                                disabled={isDisabled}
                            />
                        </FloatingLabel>
                        <Checkbox style={{ width: "30vw" }} className="m-auto" onChange={(e) => handleChecked(e)}>Required</Checkbox>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" id="close" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        name="saveAndclose"
                        // onClick={handleWOsubmit}
                    >
                        Save & Close
                    </Button> */}
                    <Button type="danger" id="close" onClick={handleClose} >Close</Button>
                    <Button type="primary" name="saveAndclose" onClick={handleWOsubmit}>Save & Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
