import io from "socket.io-client";

// export const socket = io.connect("http://192.168.0.108:8000");
// export const socket = io.connect("http://localhost:8000");
// export const socket = io.connect("https://snsindustries.co.in/api");

// export const socket = io("http://localhost:8000", {
// export const socket = io("/", {
// export const socket = io("http://192.168.5.135:8000", {
// export const socket = io("http://192.168.0.108:8000", {
export const socket = io("/", {
    path: '/chat/socket.io',
    transports: ['websocket'],
    upgrade: false,
    query: { userId: window.localStorage.getItem('userId') }
});