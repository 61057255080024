import React, { useEffect, useRef, useState } from 'react'
import { MesNav } from '../helper/MesNav'
import { Button, DatePicker, message } from 'antd'
import Chart from "chart.js/auto";
import { Bar, Line, Radar } from 'react-chartjs-2';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import axios from '../../../api/axios';
import moment from 'moment';
Chart.register(ChartjsPluginStacked100);


const { RangePicker } = DatePicker;
const charts = {
    line: Line,
    bar: Bar,
    radar: Radar
}


export const Plots = () => {
    const [ChartData, setChartData] = useState(Line)
    const chartRef = useRef(null)
    const d = new Date()
    const [ppoData, setPpoData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [plotForm, setPlotForm] = useState({ firstYear: (d.getFullYear() - 1).toString(), secondYear: (d.getFullYear()).toString() })
    const [plotType, setPlotType] = useState('3')
    const [monthYear, setMonthYear] = useState('Yearly')
    const [dailyWeekly, setDailyWeekly] = useState('Daily')
    const [month, setMonth] = useState('0')
    const [dates, setDates] = useState(null);
    const [hackValue, setHackValue] = useState(null);
    const [value, setValue] = useState([moment(d, "DD/MM/YYYY"), moment(d, "DD/MM/YYYY")]);
    const changeChart = (e) => {
        setChartData(charts[e.target.value])
    }
    // console.log(plotForm)
    const [dataOptions, setDataOptions] = useState({})

    const getPlots = async () => {
        setIsLoading(true)
        if (plotType === '1') {
            await axios.post('/plot/ytd', plotForm).then((res) => {
                let currYearData = []
                let prevYearData = []
                Object.keys(res.data[0].currMonthsData).map((key) => {
                    currYearData.push(res.data[0].currMonthsData[key])
                    prevYearData.push(res.data[0].prevMonthsData[key])
                })
                let weeks = []
                let currWeeksData = []
                let prevWeeksData = []
                // console.log(document.getElementById('select month').options[0].text)
                // let len = Object.keys(res.data[1].week_list[month]).length
                // for (var i = 1; i <= len; i++) {
                // }
                let firstWeekNum = (parseInt(res.data[1].currYearWeek_list[month]['firstWeekNum'])) - 1
                Object.keys(res.data[1].currYearWeek_list[month]).forEach((key) => {
                    if (key != 'firstWeekNum') {
                        if (month != 0) {
                            weeks.push(`Week ${parseInt(key) - firstWeekNum}`)
                        } else {
                            weeks.push(`Week ${key}`)
                        }
                        currWeeksData.push(res.data[1].currYearWeek_list[month][key][0])
                        prevWeeksData.push(res.data[1].currYearWeek_list[month][key][1])
                    }
                })
                // console.log(currWeeksData)
                // console.log(prevWeeksData)
                // console.log(currYearData)
                // console.log(prevYearData)
                setVariableData({
                    mainLabel1: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    mainLabel2: weeks,
                    dataSetLabel1: monthYear === 'Yearly' ? plotForm?.firstYear : `${document.getElementById('select month').options[month].text} ${plotForm?.firstYear}`,
                    datSetData1: monthYear === 'Yearly' ? prevYearData : prevWeeksData,
                    dataSetLabel2: monthYear === 'Yearly' ? plotForm?.secondYear : `${document.getElementById('select month').options[month].text} ${plotForm?.secondYear}`,
                    datSetData2: monthYear === 'Yearly' ? currYearData : currWeeksData,
                })
            }).catch((e) => {
                console.log(e)
            })
            // setDataOptions({ ...dataOptions, scales: { ...dataOptions.scales, xAxis: { ...dataOptions.scales.xAxis, title: { ...dataOptions.scales.xAxis.title, text: monthYear === 'Yearly' ? 'Months' : 'Weeks' } } } })
            setDataOptions({
                scales: {
                    yAxis: {
                        title: {
                            display: true,
                            text: 'Quantity in numbers',
                            font: {
                                size: 16,
                            },
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                    xAxis: {
                        title: {
                            display: true,
                            text: monthYear === 'Yearly' ? 'Months' : 'Weeks',
                            font: {
                                size: 16,
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        labels: {
                            font: {
                                size: 16,
                            },
                        },
                    },
                    title: {
                        display: true,
                        text: `YTD/MTD Plot - ${d.toDateString()}`,
                        font: {
                            size: 25,
                        },
                    },
                },
                responsive: true,
            })

        } else if (plotType === '2') {
            await axios.post('/plot/short', plotForm).then((res) => {
                // console.log(res.data[0])
                if ((Object.keys(res.data[0])).length > 0) {
                    setVariableData({
                        mainLabel1: Object.keys(res.data[0]),
                        dataSetLabel1: 'Shortage',
                        datSetData1: Object.keys(res.data[0]).map((key) => res.data[0][key][0]),
                        dataSetLabel2: 'Rejection',
                        datSetData2: Object.keys(res.data[0]).map((key) => res.data[0][key][1]),
                    })
                } else {
                    message.warning("Data not found")
                    setVariableData({
                        mainLabel1: [],
                        dataSetLabel1: 'Shortage',
                        datSetData1: [],
                        dataSetLabel2: 'Rejection',
                        datSetData2: [],
                    })
                }
            }).catch((e) => {
                console.log(e)
            })
            setDataOptions({
                scales: {
                    yAxis: {
                        stacked: true,
                        title: {
                            display: true,
                            text: 'Quantity in numbers',
                            font: {
                                size: 16,
                            },
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                    xAxis: {
                        stacked: true,
                        title: {
                            display: true,
                            text: 'Article#',
                            font: {
                                size: 16,
                            },
                        },
                    }
                },
                plugins: {
                    legend: {
                        labels: {
                            font: {
                                size: 16,
                            },
                        },
                    },
                    title: {
                        display: true,
                        text: `Shortage/Rejection Plot - ${d.toDateString()}`,
                        font: {
                            size: 25,
                        },
                    },
                    tooltip: {
                        callbacks: {
                            footer: footer,
                        }
                    }
                },
                responsive: true,
            })
        } else if (plotType === '3') {
            let find = {}
            if (value) {
                find.fromDate = moment(value[0]._d).utc().format("DD-MM-YYYY")
                find.toDate = moment(value[1]._d).utc().format("DD-MM-YYYY")
            } else {
                find.fromDate = moment(d).utc().format("DD-MM-YYYY")
                find.toDate = moment(d).utc().format("DD-MM-YYYY")
            }
            await axios.post('/plot/comp', find).then((res) => {
                // console.log(res.data[0])
                setChartData(charts['bar'])
                if ((Object.keys(res.data[0])).length > 0) {
                    setVariableData({
                        mainLabel1: Object.keys(res.data[0]),
                        dataSetLabel1: 'WO Quantity',
                        datSetData1: Object.keys(res.data[0])?.map((key) => res.data[0][key][0]),
                        dataSetLabel2: 'Shortage',
                        datSetData2: Object.keys(res.data[0])?.map((key) => res.data[0][key][1]),
                        dataSetLabel3: 'Rejection',
                        datSetData3: Object.keys(res.data[0])?.map((key) => res.data[0][key][2]),
                    })
                } else {
                    message.warning("Data not found")
                    setVariableData({
                        mainLabel1: [],
                        dataSetLabel1: 'WO Quantity',
                        datSetData1: [],
                        dataSetLabel2: 'Shortage',
                        datSetData2: [],
                        dataSetLabel3: 'Rejection',
                        datSetData3: [],
                    })
                }
            }).catch((e) => {
                console.log(e)
            })
            setDataOptions({
                scales: {
                    yAxis: {
                        title: {
                            display: true,
                            text: 'Quantity in numbers',
                            font: {
                                size: 16,
                            },
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                    xAxis: {
                        // stacked: true,
                        title: {
                            display: true,
                            text: 'Work order numbers',
                            font: {
                                size: 16,
                            },
                        },
                    },
                },
                plugins: {
                    stacked100: {
                        enable: true,
                    },
                    legend: {
                        labels: {
                            font: {
                                size: 16,
                            },
                        },
                    },
                    title: {
                        display: true,
                        text: `WO In-progress Plot - ${d.toDateString()}`,
                        font: {
                            size: 25,
                        },
                    },
                    tooltip: {
                        callbacks: {
                            label: label,
                        }
                    }
                },
                responsive: true,
            })
        }
        setIsLoading(false)
    }

    const [variableData, setVariableData] = useState({
        mainLabel1: [],
        dataSetLabel1: 'WO Quantity',
        datSetData1: [],
        dataSetLabel2: 'Shortage',
        datSetData2: [],
        dataSetLabel3: 'Rejection',
        datSetData3: [],
    })

    let aaa = {}
    if (plotType === '1') {
        aaa = {
            labels: monthYear === 'Yearly' ? variableData.mainLabel1 : variableData.mainLabel2,
            datasets: [
                {
                    label: variableData.dataSetLabel1,
                    data: variableData.datSetData1,
                    backgroundColor: [
                        // 'rgba(225,99,132,0.2)',
                        'rgba(11, 127, 171,0.5)',
                        // 'rgba(255,206,86,0.2)',
                        // 'rgba(75,192,192,0.2)',
                        // 'rgba(153,102,255,0.2)',
                        // 'rgba(255,159,64,0.2)',
                    ],
                    borderColor: [
                        // 'rgba(225,99,132,1)',
                        'rgba(11, 127, 171,1)',
                        // 'rgba(255,206,86,1)',
                        // 'rgba(75,192,192,1)',
                        // 'rgba(153,102,255,1)',
                        // 'rgba(255,159,64,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
                {
                    label: variableData.dataSetLabel2,
                    data: variableData.datSetData2,
                    backgroundColor: [
                        'rgba(242, 38, 19,0.5)',
                        // 'rgba(54,162,235,0.2)',
                        // 'rgba(255,206,86,0.2)',
                        // 'rgba(75,192,192,0.2)',
                        // 'rgba(153,102,255,0.2)',
                        // 'rgba(255,159,64,0.2)',
                    ],
                    borderColor: [
                        'rgba(242, 38, 19,1)',
                        // 'rgba(54,162,235,1)',
                        // 'rgba(255,206,86,1)',
                        // 'rgba(75,192,192,1)',
                        // 'rgba(153,102,255,1)',
                        // 'rgba(255,159,64,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
            ]
        }
    } else if (plotType === '2') {
        aaa = {
            labels: variableData.mainLabel1,
            datasets: [
                {
                    label: variableData.dataSetLabel1,
                    data: variableData.datSetData1,
                    backgroundColor: [
                        // 'rgba(225,99,132,0.2)',
                        'rgba(255,165,0,0.5)',
                        // 'rgba(255,206,86,0.2)',
                        // 'rgba(75,192,192,0.2)',
                        // 'rgba(153,102,255,0.2)',
                        // 'rgba(255,159,64,0.2)',
                    ],
                    borderColor: [
                        // 'rgba(225,99,132,1)',
                        'rgba(255,69,0,1)',
                        // 'rgba(255,206,86,1)',
                        // 'rgba(75,192,192,1)',
                        // 'rgba(153,102,255,1)',
                        // 'rgba(255,159,64,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
                {
                    label: variableData.dataSetLabel2,
                    data: variableData.datSetData2,
                    backgroundColor: [
                        'rgba(242, 38, 19,0.5)',
                        // 'rgba(54,162,235,0.2)',
                        // 'rgba(255,206,86,0.2)',
                        // 'rgba(75,192,192,0.2)',
                        // 'rgba(153,102,255,0.2)',
                        // 'rgba(255,159,64,0.2)',
                    ],
                    borderColor: [
                        'rgba(242, 38, 19,1)',
                        // 'rgba(54,162,235,1)',
                        // 'rgba(255,206,86,1)',
                        // 'rgba(75,192,192,1)',
                        // 'rgba(153,102,255,1)',
                        // 'rgba(255,159,64,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
            ]
        }
    } else if (plotType === '3') {
        aaa = {
            labels: variableData.mainLabel1,
            datasets: [
                {
                    label: variableData.dataSetLabel1,
                    data: variableData.datSetData1,
                    backgroundColor: [
                        // 'rgba(225,99,132,0.2)',
                        // 'rgba(54,162,235,0.2)',
                        // 'rgba(255,206,86,0.2)',
                        // 'rgba(75,192,192,0.2)',
                        // 'rgba(153,102,255,0.2)',
                        'rgba(0,128,0,0.5)',
                    ],
                    borderColor: [
                        // 'rgba(225,99,132,1)',
                        // 'rgba(54,162,235,1)',
                        // 'rgba(255,206,86,1)',
                        // 'rgba(75,192,192,1)',
                        // 'rgba(153,102,255,1)',
                        'rgba(0,128,0,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
                {
                    label: variableData.dataSetLabel2,
                    data: variableData.datSetData2,
                    backgroundColor: [
                        'rgba(255,165,0,0.5)',
                        // 'rgba(54,162,235,0.2)',
                        // 'rgba(255,206,86,0.2)',
                        // 'rgba(75,192,192,0.2)',
                        // 'rgba(153,102,255,0.2)',
                        // 'rgba(255,159,64,0.2)',
                    ],
                    borderColor: [
                        'rgba(255,69,0,1)',
                        // 'rgba(54,162,235,1)',
                        // 'rgba(255,206,86,1)',
                        // 'rgba(75,192,192,1)',
                        // 'rgba(153,102,255,1)',
                        // 'rgba(255,159,64,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
                {
                    label: variableData.dataSetLabel3,
                    data: variableData.datSetData3,
                    backgroundColor: [
                        'rgba(242, 38, 19,0.5)',
                        // 'rgba(54,162,235,0.2)',
                        // 'rgba(255,206,86,0.2)',
                        // 'rgba(75,192,192,0.2)',
                        // 'rgba(153,102,255,0.2)',
                        // 'rgba(255,159,64,0.2)',
                    ],
                    borderColor: [
                        'rgba(242, 38, 19,1)',
                        // 'rgba(54,162,235,1)',
                        // 'rgba(255,206,86,1)',
                        // 'rgba(75,192,192,1)',
                        // 'rgba(153,102,255,1)',
                        // 'rgba(255,159,64,1)',
                    ],
                    borderWidth: 1,
                    tension: document.getElementById('floatingSelect3')?.value !== 'radar' ? 0.3 : 0,
                    fill: 'origin',
                },
            ]
        }
    }

    const footer = (tooltipItems) => {
        let sum = 0;
        tooltipItems.forEach(function (tooltipItem) {
            sum = tooltipItem.parsed._stacks.y[0] + tooltipItem.parsed._stacks.y[1]
        });
        return ('Total: ' + sum);
    };

    const label = (tooltipItems) => {
        const data = tooltipItems.chart.data;
        const datasetIndex = tooltipItems.datasetIndex;
        const index = tooltipItems.dataIndex;
        const datasetLabel = data.datasets[datasetIndex].label || "";
        // You can use two type values.
        // `data.originalData` is raw values,
        // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)
        const originalValue = data.originalData[datasetIndex][index];
        const rateValue = data.calculatedData[datasetIndex][index];
        // console.log(`${datasetLabel}: ${rateValue}% (raw ${originalValue})`)
        // return `${datasetLabel}: ${rateValue}% (qty ${originalValue})`;
        return `${datasetLabel}: ${originalValue} (${rateValue}%)`;
    }

    const [userData, setUserData] = useState(aaa)

    const handleInput = (e) => {
        let { name, value } = e.target
        setPlotForm({ ...plotForm, [name]: value })
    }

    const handleDate = (date, dateString) => {
        var fromDate = dateString[0]
        var toDate = dateString[1]
        // console.log(new Date(moment(fromDate).utc()._d).toISOString, moment(toDate).utc()._d)
        setPlotForm({ ...plotForm, ["fromDate"]: fromDate, ["toDate"]: toDate })
    }

    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }

        if (dailyWeekly === 'Weekly') {
            const tooLate = dates[0] && current.diff(dates[0], 'days') > 6;
            const tooEarly = dates[1] && dates[1].diff(current, 'days') > 6;
            return !!tooEarly || !!tooLate;
        } else if (dailyWeekly === 'Daily') {
            const tooLate = dates[0] && current.diff(dates[0], 'days') > 0;
            const tooEarly = dates[1] && dates[1].diff(current, 'days') > 0;
            return !!tooEarly || !!tooLate;
        }
    };

    const onOpenChange = (open) => {
        if (open) {
            setHackValue([null, null]);
            setDates([null, null]);
        } else {
            setHackValue(null);
        }
    };
    const handleDownload = () => {
        // console.log(aaa)
        setIsDownloading(true)
        const data = chartRef.current.toBase64Image()
        // console.log(data)
        let filename = "";
        const keys = Object.keys(plotForm)
        var time = d.getHours() + "-" + d.getMinutes() + "-" + d.getSeconds();
        // console.log(chartRef.current)

        if (plotType === '1') {
            filename += `${time}_${d.toDateString()}_YTD/MTD Plot`
        } else if (plotType === '2') {
            if (keys.length > 0) {
                keys.forEach((key, i) => {
                    if (i !== keys.length - 1) {
                        filename += `${plotForm[key]}_`
                    } else {
                        filename += `${plotForm[key]}`
                        filename += `_${time}_${d.toDateString()}_Shortage/Rejection Plot`
                    }
                })
            }
            filename += `${time}_${d.toDateString()}_Shortage/Rejection Plot`
        } else {
            filename += `${moment(value[0]._d).utc().format("DD-MM-YYYY")} to ${moment(value[1]._d).utc().format("DD-MM-YYYY")}`
            filename += `_${time}_${d.toDateString()}_WO In-progress Plot`
        }
        filename = filename + '.png'
        const link = document.createElement('a');
        link.download = filename;
        link.href = chartRef.current.toBase64Image();
        link.click();
        setPlotForm({ "wo#": "", "article#": "" })
        setPlotForm({})
        setIsDownloading(false)
        // console.log(document.getElementById('myChart').getContext('2d'))
        // var thisChart = new Chart(document.getElementById('myChart').getContext('2d'))
    }
    console.log(plotForm)
    useEffect(() => {
        axios.get('/ppo/plots').then((res) => {
            setPpoData(res.data.ppos)
        })

        let find = {}
        if (value) {
            find.fromDate = moment(value[0]._d).utc().format("DD-MM-YYYY")
            find.toDate = moment(value[1]._d).utc().format("DD-MM-YYYY")
        } else {
            find.fromDate = moment(d).utc().format("DD-MM-YYYY")
            find.toDate = moment(d).utc().format("DD-MM-YYYY")
        }
        axios.post('/plot/comp', find).then((res) => {
            console.log(res.data[0])
            setChartData(charts['bar'])
            if ((Object.keys(res.data[0])).length > 0) {
                setVariableData({
                    mainLabel1: Object.keys(res.data[0]),
                    dataSetLabel1: 'WO Quantity',
                    datSetData1: Object.keys(res.data[0]).map((key) => res.data[0][key][0]),
                    dataSetLabel2: 'Shortage',
                    datSetData2: Object.keys(res.data[0]).map((key) => res.data[0][key][1]),
                    dataSetLabel3: 'Rejection',
                    datSetData3: Object.keys(res.data[0]).map((key) => res.data[0][key][2]),
                })
            } else {
                message.warning("Data not found")
            }
        }).catch((e) => {
            console.log(e)
        })
        setDataOptions({
            scales: {
                yAxis: {
                    title: {
                        display: true,
                        text: 'Quantity in numbers',
                        font: {
                            size: 16,
                        },
                    },
                    ticks: {
                        beginAtZero: true,
                    },
                },
                xAxis: {
                    // stacked: true,
                    title: {
                        display: true,
                        text: 'Work order numbers',
                        font: {
                            size: 16,
                        },
                    },
                },
            },
            plugins: {
                stacked100: {
                    enable: true,
                },
                legend: {
                    labels: {
                        font: {
                            size: 16,
                        },
                    },
                },
                title: {
                    display: true,
                    text: `WO In-progress Plot - ${d.toDateString()}`,
                    font: {
                        size: 25,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: label,
                    }
                }
            },
            responsive: true,
        })
    }, [])

    useEffect(() => {
        setUserData(aaa)
    }, [variableData, ChartData])

    return (
        <div className='mainHeight'>
            {/* <MesNav name='plots' /> */}
            <div className="m-auto p-3">
                <div className='d-flex gap-3 align-items-center'>
                    <div className="form-floating" style={{ width: '100%' }} hidden={plotType === '3' ? true : false}>
                        <select className="form-select" id='floatingSelect3' defaultValue="line" value={plotType === '3' ? "bar" : null} name="chartType" onChange={(e) => changeChart(e)}>
                            <option value="line">Line</option>
                            <option value="bar" selected>Bar</option>
                            <option value="radar">Radar</option>
                        </select>
                        <label for="floatingSelect3">Select chart type</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }}>
                        <select className="form-select" id='floatingSelect1' onChange={(e) => setPlotType(e.target.value)} defaultValue={plotType}>
                            <option value="3">WO in-progress</option>
                            <option value="1">YTD/MTD</option>
                            <option value="2">WO shortage/rejection</option>
                        </select>
                        <label for="floatingSelect1">Select plot type</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }} hidden={plotType === '1' ? false : true}>
                        <select className="form-select" id='floatingSelect2' onChange={(e) => setMonthYear(e.target.value)} defaultValue="Yearly">
                            <option value="Yearly">Yearly</option>
                            <option value="Monthly">Monthly</option>
                        </select>
                        <label for="floatingSelect2">Select yearly / monthly</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }} hidden={plotType === '3' ? false : true}>
                        <select className="form-select" id='floatingSelect4' onChange={(e) => setDailyWeekly(e.target.value)} defaultValue="Daily">
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                        </select>
                        <label for="floatingSelect4">Select Daily / Weekly</label>
                    </div>
                    <div className="container" hidden={plotType === '3' ? false : true}>
                        <RangePicker
                            value={hackValue || value}
                            disabledDate={disabledDate}
                            onCalendarChange={(val) => setDates(val)}
                            onChange={(val) => setValue(val)}
                            onOpenChange={onOpenChange}
                            // defaultValue={[
                            //     moment(d, "DD/MM/YYYY"),
                            //     moment(d, "DD/MM/YYYY")
                            // ]}
                            format={"DD/MM/YYYY"}
                        />
                    </div>
                    {/* {console.log(document.getElementById('floatingSelect01').options[1].innerHTML)} */}
                    <div className="form-floating" style={{ width: '100%' }} hidden={plotType === '1' ? false : true}>
                        <select className="form-select" id='floatingSelect0' name='firstYear' onChange={(e) => handleInput(e)} defaultValue={2021}>
                            <option value={2022} disabled={document.getElementById('floatingSelect01')?.value === document.getElementById('floatingSelect0')?.options[0].innerHTML ? true : false}>2022</option>
                            <option value={2021} disabled={document.getElementById('floatingSelect01')?.value === document.getElementById('floatingSelect0')?.options[1].innerHTML ? true : false}>2021</option>
                            <option value={2020} disabled={document.getElementById('floatingSelect01')?.value === document.getElementById('floatingSelect0')?.options[2].innerHTML ? true : false}>2020</option>
                            <option value={2019} disabled={document.getElementById('floatingSelect01')?.value === document.getElementById('floatingSelect0')?.options[3].innerHTML ? true : false}>2019</option>
                            <option value={2018} disabled={document.getElementById('floatingSelect01')?.value === document.getElementById('floatingSelect0')?.options[4].innerHTML ? true : false}>2018</option>
                        </select>
                        <label for="floatingSelect0" >Select first year</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }} hidden={plotType === '1' ? false : true}>
                        <select className="form-select" id='floatingSelect01' name='secondYear' onChange={(e) => handleInput(e)} defaultValue={2022}>
                            <option value={2022} disabled={document.getElementById('floatingSelect0')?.value === document.getElementById('floatingSelect01')?.options[0].innerHTML ? true : false}>2022</option>
                            <option value={2021} disabled={document.getElementById('floatingSelect0')?.value === document.getElementById('floatingSelect01')?.options[1].innerHTML ? true : false}>2021</option>
                            <option value={2020} disabled={document.getElementById('floatingSelect0')?.value === document.getElementById('floatingSelect01')?.options[2].innerHTML ? true : false}>2020</option>
                            <option value={2019} disabled={document.getElementById('floatingSelect0')?.value === document.getElementById('floatingSelect01')?.options[3].innerHTML ? true : false}>2019</option>
                            <option value={2018} disabled={document.getElementById('floatingSelect0')?.value === document.getElementById('floatingSelect01')?.options[4].innerHTML ? true : false}>2018</option>
                        </select>
                        <label for="floatingSelect01" >Select second year</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }} hidden={plotType === '1' ? monthYear === 'Yearly' ? true : false : true}>
                        <select className="form-select" id='select month' onChange={(e) => setMonth(e.target.value)} defaultValue={0}>
                            <option value={0}>January</option>
                            <option value={1}>February</option>
                            <option value={2}>March</option>
                            <option value={3}>April</option>
                            <option value={4}>May</option>
                            <option value={5}>June</option>
                            <option value={6}>July</option>
                            <option value={7}>August</option>
                            <option value={8}>September</option>
                            <option value={9}>October</option>
                            <option value={10}>November</option>
                            <option value={11}>December</option>
                        </select>
                        <label for="select month" >Select month</label>
                    </div>

                    <div className="form-floating" style={{ width: '100%' }} hidden={plotType === '2' ? false : true}>
                        <input type="text" name='wo#' className="form-control" id="floatingInput1" value={plotForm["wo#"]} onInput={(e) => handleInput(e)} placeholder=" " autoComplete='off' disabled={!plotForm['article#'] ? false : true} />
                        <label for="floatingInput1">Enter Wo#</label>
                    </div>
                    <div className="form-floating" style={{ width: '100%' }} hidden={plotType === '2' ? false : true}>
                        <input type="text" name='article#' className="form-control" id="floatingInput2" value={plotForm["article#"]} onInput={(e) => handleInput(e)} placeholder=" " autoComplete='off' disabled={!plotForm['wo#'] ? false : true} />
                        <label for="floatingInput2">Enter Article#</label>
                    </div>

                    <div hidden={plotType === '2' ? false : true} >
                        <RangePicker className='col-sm-3' format={'DD/MM/YYYY'} onChange={(date, dateString) => handleDate(date, dateString)} style={{ width: '18vw' }} disabled={!plotForm['wo#'] ? false : true} />
                    </div>

                    <Button type='primary' onClick={() => getPlots()} loading={isLoading}>Plot</Button>
                    <Button type='primary' onClick={() => handleDownload()} loading={isDownloading}>Download Chart</Button>
                </div>
                {/* <div className='d-flex pt-3 gap-3 justify-content-center align-items-center'>
                    <Button type='primary' onClick={() => handleDownload()}>Download Chart</Button>
                </div> */}
                <div className='m-auto p-1 d-flex flex-column align-items-center' style={{ width: '80vw' }} >
                    <form className="form-floating input-group-sm select_box" style={{ width: '20vw' }} hidden={plotType === '1' ? false : true}>
                        <input className="form-control" id="floatingTextarea" list="datalistOptions4" value={plotForm["article#"]} placeholder="Select Article Num" name='article#' onInput={(e) => handleInput(e)} autoComplete="off" />
                        <datalist id="datalistOptions4" >
                            {ppoData.map((item, i) => {
                                return <option key={i} value={item} />
                            })}
                        </datalist>
                        <label for="floatingTextarea">Select Article#</label>
                    </form>
                    <ChartData id='myChart' data={userData} options={dataOptions} ref={chartRef} />
                </div>
            </div>
        </div >
    )
}
