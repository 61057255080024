import React from 'react'

function TableRows({ rowsData, deleteTableRows, handleChange,isShortage }) {
    return (
        rowsData.map((data, index) => {
            return (
                <tr key={index}>
                    <td>
                        <input type="text" value={data['article#']} required onChange={(e) => (handleChange(e, index,isShortage))} name="article#" className="form-control" />
                    </td>
                    <td><input type="text" value={data['qty']} required onChange={(e) => (handleChange(e, index, isShortage))} name="qty" className="form-control" /> </td>
                    <td><input type="text" value={data['desc']} required onChange={(e) => (handleChange(e, index, isShortage))} name="desc" className="form-control" /> </td>
                    <td><input type='button' className="btn btn-dark" onClick={() => (deleteTableRows(index, isShortage))} value='x' /></td>
                </tr>
            )
        })

    )

}

export default TableRows