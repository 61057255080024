import React, { useEffect, useState } from 'react'
import { Form, FloatingLabel } from 'react-bootstrap';
import { Masternav } from '../../helper/MasterNav';
import axios from '../../../../../api/axios'
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { formValidator } from '../../../../../services/formValidator';
import { Button, message, Spin } from 'antd';
import { socket } from '../../../../../services/socket';
import { Backdrop, CircularProgress } from '@mui/material';
import { useGetUserDetails } from '../../../../../hooks/useGetUserDetails';

export const Customer = ({ hidden }) => {
    const { user } = useGetUserDetails()
    const [custForm, setCustForm] = useState({ cust_proj: '' })
    const [cust, setCust] = useState([])
    const [filteredcust, setFilteredCust] = useState([])
    const [search, setSearch] = useState("")
    const [formValidationError, setFormValidationError] = useState({});
    const [loading, setLoading] = useState({
        uploading: false,
        enabling: false,
        data: true,
    })

    const handleInput = (e) => {
        setCustForm({ ...custForm, [e.target.name]: e.target.value })
    }

    const handleCancel = () => {
        setCustForm({ cust_proj: '' })
        setFormValidationError({})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formError = formValidator(custForm, { cust_proj: 'Customer/Project Required' })
        setFormValidationError(formError)
        console.log(formError)
        if (Object.keys(formError).length === 0) {
            await axios.post('/customer/add', custForm).then((res) => {
                if (res.status === 200) {
                    message.success(res.data.msg)
                } else if (res.status === 202) {
                    setLoading({ ...loading, uploading: false })
                    message.warning(res.data.msg)
                } else {
                    setLoading({ ...loading, uploading: false })
                    message.error("Data not saved")
                }
                setCustForm({ cust_proj: '' })
            }).catch((e) => {
                setLoading({ ...loading, uploading: false })
                console.log(e)
            })
        }
    }

    const handleEnable = async (e, _id) => {
        // await axios.post('/customer/enable', { _id, "enable": e.target.checked })
        await axios.post('/customer/enable', { _id, "enable": e.target.checked }).then((res) => {
            if (res.status !== 200) {
                setLoading({ ...loading, enabling: false })
            }
        }).catch((e) => {
            setLoading({ ...loading, enabling: false })
            console.log(e)
        })
    }

    const columns = [
        {
            name: 'Customer/Project',
            cell: row => row.cust_proj
        },
        {
            name: 'Active',
            cell: row => <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={row.enable} onChange={(e) => { setLoading({ ...loading, enabling: true }); handleEnable(e, row._id); }} disabled={loading.enabling} style={{ cursor: 'pointer' }} />
            </div>,
            width: '100px',
            omit: hidden,
            center: true,
        },
        {
            name: 'Date',
            selector: row => <div>{moment(row.date).utc().format("DD-MM-YYYY")}</div>,
            width: '110px',
            right: true,
            omit: user?.access?.Date > 0 ? false : true,
        },
    ]

    useEffect(() => {
        (async function () {
            await axios.get('/customer/all').then((res) => {
                setFilteredCust(res.data.custs)
                setCust(res.data.custs)
                if (!res.data.custs?.length > 0) {
                    setLoading({ ...loading, data: false })
                }
            })
        })()
    }, [])

    useEffect(() => {
        socket.on('customer', async (customers) => {
            setFilteredCust(customers)
            setCust(customers)
            setLoading({
                uploading: false,
                enabling: false
            })
        })
    }, [socket])

    useEffect(() => {
        const res = cust.filter((item) => {
            return item.cust_proj.toLowerCase().match(search.toLocaleLowerCase())
        })
        setFilteredCust(res)
    }, [search])

    return (
        <div className='mainHeight'>
            <Masternav name='customer' />
            <div>
                <div className='card'>
                    <DataTable
                        // title='uom'
                        columns={columns}
                        data={filteredcust}
                        pagination
                        fixedHeader
                        paginationDefaultPage={1}
                        fixedHeaderScrollHeight="410px"
                        highlightOnHover
                        actions={<input className='form-control' placeholder='Search Customer/Project' value={search} onChange={(e) => setSearch(e.target.value)} />}
                        noDataComponent={loading?.data ? <div className='p-3'><Spin /></div> : <div className='p-3'>No Data</div>}
                    />
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading.uploading || loading.enabling}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <hr />
                <div className='card'>
                    <section className='masterbody' hidden={hidden}>
                        <FloatingLabel label="Customer/Project">
                            <Form.Control name='cust_proj' type="text" onInput={handleInput} value={custForm?.cust_proj} placeholder="none" autoComplete="off" />
                            <p className="text-danger">{formValidationError['cust_proj']}</p>
                        </FloatingLabel>
                    </section>
                    <section className='masterbtn' hidden={hidden}>
                        <div >
                            <Button type="primary" onClick={(e) => { setLoading({ ...loading, uploading: true }); handleSubmit(e); }} loading={loading.uploading}>
                                {loading.uploading ? 'Adding' : 'Add'}
                            </Button>
                            <Button type="primary" onClick={handleCancel}>Cancel</Button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}
