import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Components/Home'
import { Master } from './Components/MES/Master/Master'
import { Article } from './Components/MES/Master/subcomponents/Article/Article'
import { Customer } from './Components/MES/Master/subcomponents/Customer/Customer'
import { Supplier } from './Components/MES/Master/subcomponents/Supplier/Supplier'
import { Stages } from './Components/MES/Master/subcomponents/Stages/Stages'
import { Uom } from './Components/MES/Master/subcomponents/Uom/Uom'
import { MesHome } from './Components/MES/MesHome'
import { PPO } from './Components/MES/PPO/PPO'
import { WO } from './Components/MES/WO/WO'
import { Instrument } from './Components/MES/Master/subcomponents/Instrument/Instrument'
import { TestPlan } from './Components/MES/Master/subcomponents/Testplan/Testplan'
import { Quality } from './Components/MES/Quality/Quality'
import { QualityAlt } from './Components/MES/Quality/helper/QualityAlt'
import { QualityReports } from './Components/MES/Reports/QualityReports/QualityReports'
import { ProcessReports } from './Components/MES/Reports/ProcessReports/ProcessReports'
import { ImageUpload } from './Components/MES/Master/subcomponents/Instrument/helper/ImageUpload'
import { ReportsHome } from './Components/MES/Reports/ReportsHome'
import { Plots } from './Components/MES/Plots/Plots'
import { UserRoles } from './Components/MES/Roles/UserRoles'
import RequiredAuth from './Components/Auth/Permissions/RequiredAuth'
import { Test } from './Components/Test'
import { Bom } from './Components/MES/Master/subcomponents/Bom/Bom'
import { useGetUserDetails } from './hooks/useGetUserDetails'
import { S3bucket } from './Components/MES/S3bucket/S3bucket'
import { PlotsHome } from './Components/MES/Plots/PlotsHome'
import { WoInProgressPlot } from './Components/MES/Plots/WO in-progress/WoInProgressPlot'
import { WoShortRejPlot } from './Components/MES/Plots/WO shortage or rejection/WoShortRejPlot'
import { YtdMtdPlot } from './Components/MES/Plots/YTD_MTD/YtdMtdPlot'
import { Category } from './Components/MES/Master/subcomponents/Category/Category'

export const Router = () => {
  const { isUser, user } = useGetUserDetails();
  return (
    <Routes>
      <Route index element={<Home />}></Route>
      <Route path="/mesHome/*">
        <Route index element={<RequiredAuth roleRequired=""><MesHome /></RequiredAuth >} />
        <Route path="ppoDashboard/" element={<RequiredAuth allowedRoles="PPO"><PPO hidden={user ? user?.access?.["PPO"] > 1 ? false : true : true} /></RequiredAuth>} />
        <Route path="woDashboard/" element={<RequiredAuth allowedRoles="WO"><WO hidden={user ? user?.access?.["WO"] > 1 ? false : true : true} /></RequiredAuth>} />
        <Route path="master/" element={<RequiredAuth ><Master /></RequiredAuth>} />
        <Route path="quality/" element={<RequiredAuth allowedRoles="Incoming Quality"><Quality /></RequiredAuth>} />
        <Route path="qualitytest/" element={<QualityAlt />} />
        <Route path="reports/" element={<ReportsHome />} />
        <Route path="plots/" element={<PlotsHome />} />
        <Route path="bucket/" element={<S3bucket />} />
        <Route path="roles/" element={<RequiredAuth allowedRoles="Acm"><UserRoles /></RequiredAuth>} />
      </Route>
      <Route path="/mesHome/master/*">
        <Route index element={<Master />} />
        <Route path="article/" element={<RequiredAuth allowedRoles="Article Master"><Article hidden={user ? user?.access?.["Article Master"] > 1 ? false : true : true} /></RequiredAuth>} />
        <Route path="category/" element={<RequiredAuth allowedRoles="Category Master"><Category hidden={user ? user?.access?.["Category Master"] > 1 ? false : true : true} /></RequiredAuth>} />
        <Route path="stages/" element={<RequiredAuth allowedRoles="Stages Master"><Stages hidden={user ? user?.access?.["Stages Master"] > 1 ? false : true : true} /></RequiredAuth>} />
        <Route path="uom/" element={<RequiredAuth allowedRoles="UOM Master"><Uom hidden={user ? user?.access?.["UOM Master"] > 1 ? false : true : true} /></RequiredAuth>} />
        <Route path="customer/" element={<RequiredAuth allowedRoles="Customer Master"><Customer hidden={user ? user?.access?.["Customer Master"] > 1 ? false : true : true} /></RequiredAuth>} />
        <Route path="supplier/" element={<RequiredAuth allowedRoles="Supplier Master"><Supplier hidden={user ? user?.access?.["Supplier Master"] > 1 ? false : true : true} /></RequiredAuth>} />
        <Route path="instrument/" element={<RequiredAuth allowedRoles="Instrument Master"><Instrument hidden={user ? user?.access?.["Instrument Master"] > 1 ? false : true : true} /></RequiredAuth>} />
        <Route path="testplan/" element={<RequiredAuth allowedRoles="Test Plan Master"><TestPlan hidden={user ? user?.access?.["Test Plan Master"] > 1 ? false : true : true} /></RequiredAuth>} />
        <Route path="bom/" element={<RequiredAuth allowedRoles="Bom Master"><Bom hidden={user ? user?.access?.["Bom Master"] > 1 ? false : true : true} /></RequiredAuth>} />
      </Route>
      <Route path="/mesHome/reports/*">
        <Route index element={<ReportsHome />} />
        <Route path="process/" element={<RequiredAuth allowedRoles="Process Reports"><ProcessReports /></RequiredAuth>} />
        <Route path="quality/" element={<RequiredAuth allowedRoles="Quality Reports"><QualityReports /></RequiredAuth>} />
      </Route>
      <Route path="/mesHome/plots/*">
        <Route index element={<PlotsHome />} />
        <Route path="inProgress/" element={<RequiredAuth allowedRoles="Process Reports"><WoInProgressPlot /></RequiredAuth>} />
        <Route path="short_rej/" element={<RequiredAuth allowedRoles="Quality Reports"><WoShortRejPlot /></RequiredAuth>} />
        <Route path="ytd_mtd/" element={<RequiredAuth allowedRoles="Quality Reports"><YtdMtdPlot /></RequiredAuth>} />
      </Route>
    </Routes>
  )
}
