import React from 'react';
import { Button, message, Modal, Space, Upload } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import { UploadOutlined } from '@ant-design/icons';
import { useEffect } from 'react';

export const ImageUpload = (items) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [url, setUrl] = useState(``)

    const showModal = () => {
        setIsModalVisible(true);

    };

    const handleOk = () => {
        setIsModalVisible(false);
        setUrl('')
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setUrl('')
    };

    // const props = {
    //     name: 'file',
    //     multiple: false,
    //     //   action: "http://snsindustries.co.in/ppo/upload",
    //     action: "http://localhost:8000/instrument/upload",

    //     onChange(info) {
    //         if (info.file.status !== 'uploading') {
    //             // console.log(info.file, info.fileList);
    //         }

    //         if (info.file.status === 'done') {
    //             message.success(`${info.file.name} file uploaded successfully`);
    //         } else if (info.file.status === 'error') {
    //             message.error(`${info.file.name} file upload failed.`);
    //         }
    //     },
    // };

    const handleUpload = () => {
        const formData = new FormData();
        fileList.forEach((file) => {
            // console.log(...file)
            // file = { ...file, 'ab': 'aba' }
            formData.append('files', file);
        });
        setUploading(true);
        // fetch(`http://localhost:8000/instrument/upload/${items.rowId}/${items.colId}`, {
        fetch(`https://snsindustries.co.in/api/instrument/upload/${items.rowId}/${items.colId}`, {
            method: 'POST',
            body: formData,
        })
            .then((res) => res.json())
            .then(() => {
                setFileList([]);
                message.success('uploaded successfully.');
                setUrl(``)
            })
            .catch(() => {
                message.error('upload failed.');
            })
            .finally(() => {
                setUploading(false);
            });
    };


    const props = {
        multiple: false,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            // console.log(file)
            if (file.type === 'application/pdf') {
                setFileList([file]);
            } else {
                message.error('Upload PDF files only')
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
            }
            return false;
        },
        fileList,
    };
    // console.log(url)
    useEffect(() => {
        if (isModalVisible === true) {
            // setUrl(`http://localhost:8000/instrument/avatar/${items.rowId}/${items.colId}`)
            setUrl(`https://snsindustries.co.in/api/instrument/avatar/${items.rowId}/${items.colId}`)
        } else if (isModalVisible === false) {
            setUrl('')
        }
    }, [isModalVisible, uploading])

    return (
        <div >
            <Button type="primary" onClick={() => showModal()}>
                {items.hidden ? "View" : "View/Add"}
            </Button>
            <Modal
                // title={<h4>Upload image for:</h4>}
                centered
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                keyboard={false}
            >
                <p>
                    <b>{`${items.item1.instr_name}, 
                    ${items.item2.instr_model}
                    , ${items.item2.asset_id}, ${moment(
                        items.item2.cal_due_date
                    )
                            .utc()
                            .format('DD-MM-YYYY')}`}</b>
                </p>
                <div className='m-auto card' id="pdfId" style={{ width: '80%', aspectRatio: '1' }}>
                    {/* <embed style={{ width: '100%', aspectRatio: '1' }} src={url} type='application/pdf' alt="" /> */}
                    {url ?
                        <iframe style={{ width: '100%', aspectRatio: '1' }} src={url} type='application/pdf' frameborder="0"></iframe>
                        : null
                    }
                </div>
                <div hidden={items.hidden}>
                    <Upload {...props}>
                        <Button className='d-flex align-items-center' style={{ marginTop: 16, }} icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                    <Button
                        type="primary"
                        onClick={() => handleUpload()}
                        disabled={fileList.length === 0}
                        loading={uploading}
                        style={{
                            marginTop: 16,
                        }}
                    >
                        {uploading ? 'Uploading' : 'Start Upload'}
                    </Button>
                </div>
            </Modal>
        </div>
    );
};
