import React, { useEffect, useState, useRef } from 'react'
import { Button, Drawer, Layout } from 'antd'
import axios from '../../../../api/axios'

import { StageTab } from './subComponent/StageTab'
import { Tab, Tabs } from 'react-bootstrap'
import { socket } from '../../../../services/socket'
const stages = [
    "Stage A",
    "Stage B",
    "Stage C",
    "Stage D",
    "Stage E",
    "Stage F",
    "Stage G",
    "Stage H",
    "Stage I",
    "Stage J",
];
export const Stages = ({ wo, hidden, loading, setLoading }) => {
    const reset = useRef()
    const [visible, setVisible] = useState(false);
    const [artStages, setArtStages] = useState({})

    const [formData, setFormData] = useState({})
    // console.log(wo['article#'])
    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
        setArtStages([])
    };

    const handleClick = () => {
        (async function () {
            await axios.post(`/stage/of`, { art_no: wo['article#'] }).then((res) => {
                if (res.status === 200) {
                    console.log(res.data)
                    if (Object.keys(res.data.article)?.includes('stages')) {
                        setArtStages(res.data.article.stages)
                        let ab = wo.stages.find((stage) => stage.stage === 'Stage A')
                        // console.log(ab)
                    }
                }
            }).catch((e) => {
                setArtStages([])
            })
        })()
    }

    // useEffect(() => {
    //     (async function () {
    //         await axios.post(`/stage/of`, { art_no: wo['article#'] }).then((res) => {
    //             if (res.status === 200) {
    //                 console.log(wo['article#'])
    //                 console.log(res.data)
    //                 if (Object.keys(res.data.article)?.includes('stages')) {
    //                     setArtStages(res.data.article.stages)
    //                     let ab = wo.stages.find((stage) => stage.stage === 'Stage A')
    //                     // console.log(ab)
    //                 }
    //             }
    //         }).catch((e) => {
    //             setArtStages([])
    //         })
    //     })()
    // }, [wo])

    return (
        <div>
            <Button className="px-2" type="primary" onClick={() => { showDrawer(); handleClick() }}>
                Open
            </Button>
            <Drawer
                title="Stages"
                height='90vh'
                placement='bottom'
                closable={true}
                onClose={onClose}
                open={visible}
                maskClosable={true}
                keyboard={false}
            // style={{ zIndex: "1200" }}
            >
                <div className="d-flex stage-head">
                    <h3 id='stage-headh3'>WO#: {wo["wo#"]}</h3>
                    <h3 id='stage-headh3'>Article#: {wo["article#"]}</h3>
                    <h3 id='stage-headh3'>PPO#: {wo["ppoItem"]}</h3>
                    <h3 id='stage-headh3'>Wo_qty: {wo["wo_qty"]}</h3>
                </div>
                <Tabs
                    defaultActiveKey="Stage A"
                    className="mb-3"
                >
                    {Object.keys(artStages).length > 0 ?
                        Object.entries(artStages).map((stage, i) =>
                        (<Tab eventKey={stage[0]} title={stage[1]} key={i}>
                            <br />
                            <StageTab hidden={hidden} loading={loading} setLoading={setLoading} setVisible={setVisible} stage={stage[0]} _id={wo._id} stageData={wo?.stages?.find((stg) => stg.stage === stage[0])} StageName={stage[0]} onChange={setFormData} formData={formData} reset={reset} stageName={stage[1]} />
                        </Tab>)
                        ) :
                        stages.map((stage, i) => (
                            <Tab eventKey={stage} title={stage} key={i}>
                                <StageTab hidden={hidden} loading={loading} setLoading={setLoading} setVisible={setVisible} stage={stage} _id={wo._id} stageData={wo?.stages?.find((stg) => stg.stage === stage)} StageName={stage} onChange={setFormData} formData={formData} reset={reset} stageName={stage} />
                            </Tab>
                        ))
                    }
                </Tabs>
            </Drawer>
        </div>
    );
}
