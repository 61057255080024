import React, { useState, useEffect } from 'react';
import { Form, FloatingLabel, } from 'react-bootstrap';
import axios from '../../../../../api/axios'
import moment from "moment"
import { Masternav } from "../../helper/MasterNav"
import DataTable from 'react-data-table-component'
import { formValidator } from '../../../../../services/formValidator';
import { Button, message, Spin, Switch, Icon } from 'antd';
import { socket } from '../../../../../services/socket';
import { Backdrop, CircularProgress } from '@mui/material';
import { useGetUserDetails } from '../../../../../hooks/useGetUserDetails';

export const Uom = ({ hidden }) => {
    const { user } = useGetUserDetails()
    const [uomForm, setUomForm] = useState({ physical_parm: '', uom: '' })
    const [uom, setUom] = useState([])
    const [filtereduom, setFilteredUom] = useState([])
    const [search, setSearch] = useState("")
    const [formValidationError, setFormValidationError] = useState({});
    const [data, setData] = useState(true);
    const [loading, setLoading] = useState({
        uploading: false,
        enabling: false
    })

    const handleInput = (e) => {
        setUomForm({ ...uomForm, [e.target.name]: e.target.value })
    }

    const handleCancel = () => {
        setUomForm({ physical_parm: '', uom: '' })
        setFormValidationError({})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formError = formValidator(uomForm, { physical_parm: 'Physical Parameter Required', uom: 'UOM Required' })
        setFormValidationError(formError)
        console.log(formError)
        if (Object.keys(formError).length === 0) {
            await axios.post('/uom/add', uomForm).then((res) => {
                if (res.status === 200) {
                    message.success(res.data.msg)
                    setUomForm({ physical_parm: '', uom: '' })
                } else if (res.status === 202) {
                    setLoading({ ...loading, uploading: false })
                    message.warning(res.data.msg)
                } else {
                    setLoading({ ...loading, uploading: false })
                    message.error("Data not saved")
                }
            }).catch((e) => {
                setLoading({ ...loading, uploading: false })
                console.log(e)
            })
        }
    }

    const handleEnable = async (e, _id) => {
        await axios.post('/uom/enable', { _id, "enable": e.target.checked }).then((res) => {
            if (res.status !== 200) {
                setLoading({ ...loading, enabling: false })
            }
        }).catch((e) => {
            setLoading({ ...loading, enabling: false })
            console.log(e)
        })
    }

    const columns = [
        {
            name: 'Physical Parameter',
            cell: row => row.physical_parm
        },
        {
            name: 'UOM',
            cell: row => row.uom,
            center: true,
        },
        {
            name: 'enable',
            cell: row => <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={row.enable} onChange={(e) => { setLoading({ ...loading, enabling: true }); handleEnable(e, row._id); }} disabled={loading.enabling} style={{ cursor: 'pointer' }} />
            </div>,
            width: '100px',
            omit: hidden,
            center: true,
        },
        {
            name: 'Date',
            selector: row => <div>{moment(row.date).utc().format("DD-MM-YYYY")}</div>,
            width: '110px',
            right: true,
            omit: user?.access?.Date > 0 ? false : true,
        },
    ]

    useEffect(() => {
        (async function () {
            axios.get('/uom/all').then((res) => {
                setFilteredUom(res.data.uoms)
                setUom(res.data.uoms)
                if (!res.data.uoms?.length > 0) {
                    setData(false)
                }
            }).catch((e) => {
                console.log(e)
            })
        })()
    }, [])

    useEffect(() => {
        socket.on('uom', (Uoms) => {
            setFilteredUom(Uoms)
            setUom(Uoms)
            setLoading({
                uploading: false,
                enabling: false
            })
        })
    }, [socket])

    useEffect(() => {
        const res = uom.filter((item) => {
            return item.physical_parm.toLowerCase().match(search.toLocaleLowerCase())
        })
        setFilteredUom(res)
    }, [search])

    return (
        <div className='mainHeight'>
            <Masternav name='uom' />
            <div>
                <div className='card'>
                    <DataTable
                        // title='uom'
                        columns={columns}
                        data={filtereduom}
                        pagination
                        fixedHeader
                        paginationDefaultPage={1}
                        fixedHeaderScrollHeight="410px"
                        highlightOnHover
                        actions={<input className='form-control' placeholder='Search Physical Parameter' value={search} onChange={(e) => setSearch(e.target.value)} />}
                        noDataComponent={data ? <div className='p-3'><Spin /></div> : <div className='p-3'>No Data</div>}
                    />
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading.uploading || loading.enabling}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <hr />
                <div className='card'>
                    <section className='masterbody' hidden={hidden}>
                        <FloatingLabel label="Physical Parameter">
                            <Form.Control name='physical_parm' type="text" onInput={handleInput} value={uomForm?.physical_parm} placeholder="none" autoComplete="off" />
                            <p className="text-danger">{formValidationError['physical_parm']}</p>
                        </FloatingLabel>
                        <FloatingLabel label="UOM">
                            <Form.Control name='uom' type="text" onInput={handleInput} value={uomForm?.uom} placeholder="none" autoComplete="off" />
                            <p className="text-danger">{formValidationError['uom']}</p>
                        </FloatingLabel>
                    </section>
                    <section className='masterbtn' hidden={hidden}>
                        <div >
                            <Button type="primary" onClick={(e) => { setLoading({ ...loading, uploading: true }); handleSubmit(e); }} loading={loading.uploading}>
                                {loading.uploading ? 'Adding' : 'Add'}
                            </Button>
                            <Button type="primary" onClick={handleCancel}>Cancel</Button>
                        </div>
                    </section>
                </div>
            </div >
        </div >
    )
}
