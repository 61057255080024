import { createSlice } from "@reduxjs/toolkit";

const initialValue = { isUser: false, data: {} };

export const userSlice = createSlice({
  name: "userInfo",
  initialState: { value: initialValue },
  reducers: {
    userLogin: (state, action) => {
      // console.log(action.payload) 
      let payload = action.payload;
      if (payload.user.lastLogin) { payload.user.lastLogin = [] };
      if (payload.user.avatar) {
        payload.user.avatar = true;
      }
      // console.log(JSON.stringify({ ...payload }));
      state.value = { isUser: true, ...payload };
    },
    userLogout: (state, action) => {
      state.value = initialValue;
    },
  },
});

export const { userLogin, userLogout } = userSlice.actions;

export default userSlice.reducer;
