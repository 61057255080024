import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { MesNav } from '../helper/MesNav'
import moment from "moment";
import { Spin, message, Button } from 'antd';
import { Backdrop, CircularProgress } from '@mui/material'
import axios from '../../../api/axios'
import { Stages } from './helper/Stages';
import { useDispatch, useSelector } from 'react-redux'
import { socket } from '../../../services/socket';
import { setWO } from '../../../features/wo';
message.config({ top: '40%' })

export const WO = ({ hidden }) => {
    // let res = useSelector((state) => state.woInfo.value.wos)
    const [wos, setWoss] = useState([]);
    const [filteredwos, setFilteredWos] = useState([]);
    const [data, setData] = useState(false);
    const [cust, setCust] = useState([])
    const [count, setCount] = useState(1);
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState({
        loading: false,
        isLoading: false,
    })
    const [switchWO, setSwitchWO] = useState(false)
    const [categories, setCategories] = useState([])

    const dispatcher = useDispatch()

    const handleEnable = async (e, row) => {
        const { _id } = row
        const { checked } = e.target
        await axios.get(`/wo/toggle/${_id}/${checked}`).then((res) => {
            if (res.status === 200) {
                return message.success("Toggled")
            }
            setLoading({ ...loading, loading: false })
            return message.error("Error")
        }).catch((e) => {
            setLoading({ ...loading, loading: false })
            return message.error("Error")
        })
    };

    // function to find if a specified KEY is present in a specified OBJECT
    const findPath = (ob, key) => {
        const path = [];
        const keyExists = (obj) => {
            if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
                return false;
            }
            else if (obj.hasOwnProperty(key)) {
                return true;
            }
            else if (Array.isArray(obj)) {
                let parentKey = path.length ? path.pop() : "";

                for (let i = 0; i < obj.length; i++) {
                    path.push(`${parentKey}[${i}]`);
                    const result = keyExists(obj[i], key);
                    if (result) {
                        return result;
                    }
                    path.pop();
                }
            }
            else {
                for (const k in obj) {
                    path.push(k);
                    const result = keyExists(obj[k], key);
                    if (result) {
                        return result;
                    }
                    path.pop();
                }
            }
            return false;
        };

        keyExists(ob);

        return path.join(".");
    }

    const conditionalRowStyles = [
        {
            when: row => findPath(row, "info"),
            style: {
                backgroundColor: "#815B5B",
                color: 'white',
            },
        },
        {
            when: row => findPath(row.stages, "article#"),
            style: {
                backgroundColor: "rgba(255,12,0,0.8)",
                color: 'white',
            },
        },
        {
            when: row => row.toggleSelected,
            style: {
                backgroundColor: "rgba(50,205,50,0.8)",
                color: 'black',
            },
        },
    ];

    const columns = [
        {
            name: 'Done',
            cell: row => <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" checked={row.toggleSelected} onChange={(e) => { setLoading({ ...loading, loading: true }); handleEnable(e, row); }} style={{ cursor: 'pointer' }} />
            </div>,
            width: '65px',
            omit: hidden,
            hide: 'sm',
        },
        {
            name: 'Option',
            cell: row => <Stages wo={row} hidden={hidden} loading={loading} setLoading={setLoading} />,
            width: '75px',
            hide: 'sm',
        },
        {
            name: 'WO#',
            cell: row => <div>{row["wo#"]}</div>,
            width: '70px'
        },
        {
            name: 'WoDate#',
            cell: row => <div>{moment(row.woDate).utc().format("DD-MM-YYYY")}</div>,
            width: '100px',
        },
        {
            name: 'Article#',
            cell: row => row["article#"],
        },
        {
            name: 'Product Group',
            cell: row => row.catName,
        },
        {
            name: 'Type',
            cell: row => row.catSubType,
        },
        {
            name: 'PpoItem',
            cell: row => row.ppoItem,
            hide: 'md',
        },
        {
            name: 'Serial#',
            cell: row => row["serial#"],
            hide: 'md',
        },

        {
            name: 'Ppo_qty',
            cell: row => row.ppo_qty,
            width: '80px',
        },
        {
            name: 'Ppo_c_qty',
            cell: row => row.ppo_c_qty,
            width: '95px',
            hide: 'md',
        },
        {
            name: 'Wo_qty',
            cell: row => row.wo_qty,
            width: '80px',
        },
        {
            name: 'Wo_c_qty',
            cell: row => row.wo_c_qty,
            width: '95px',
            hide: 'md',
        },
        // {
        //     name: 'Info',
        //     cell: row => <td style={{ disabled: true }}>
        //     </td>,
        // },
    ]

    const handleCustChange = async (newCust) => {
        let find = {}
        let data = []
        let { value } = document.getElementById('floatingSelect2')
        find["cust_proj"] = newCust?.length > 0 ? newCust?.[0] : value
        setData(true)
        if (newCust?.length > 0) {
            await axios.get('/wo/').then((res) => {
                if (res.status === 200) {
                    // dispatcher(setWO(res.data.wos))
                    setWoss(res.data.wos)
                    data = res.data.wos?.filter((item) => {
                        return String(item.cust_proj).toLowerCase() === String(find.cust_proj).toLocaleLowerCase()
                    })
                    setFilteredWos(data)
                }
            }).catch((e) => {
                console.log(e)
            })
        } else {
            data = wos?.filter((item) => {
                return String(item.cust_proj).toLowerCase() === String(find.cust_proj).toLocaleLowerCase()
            })
            setFilteredWos(data)
        }
        if (!data?.length > 0) {
            setData(false)
        }
    }

    const handleCatChange = (e) => {
        let { value } = document.getElementById('floatingSelect3')
        if (value !== 'all') {
            let filteredWoDataTemp = [...wos]
            let find = {}
            find["cust_proj"] = document.getElementById('floatingSelect2').value
            let data = filteredWoDataTemp.filter((item) => {
                return String(item.cust_proj).toLowerCase() === String(find.cust_proj).toLocaleLowerCase()
            }).filter((item) => {
                return String(item.catName).toLowerCase() === String(value).toLocaleLowerCase()
            })
            setFilteredWos(data)
            if (!data?.length > 0) {
                setData(false)
            }
        } else {
            handleCustChange()
        }
    }

    const handleCatSubTypeChange = (e) => {
        let { value } = document.getElementById('floatingSelect4')
        if (value !== 'all') {
            let filteredWoDataTemp = [...wos]
            let find = {}
            find["cust_proj"] = document.getElementById('floatingSelect2').value
            find["catName"] = document.getElementById('floatingSelect3').value
            let data = filteredWoDataTemp.filter((item) => {
                return String(item.cust_proj).toLowerCase() === String(find.cust_proj).toLocaleLowerCase()
            }).filter((item) => {
                return String(item.catName).toLowerCase() === String(find.catName).toLocaleLowerCase()
            }).filter((item) => {
                return String(item.catSubType).toLowerCase() === String(value).toLocaleLowerCase()
            })
            setFilteredWos(data)
            if (!data?.length > 0) {
                setData(false)
            }
        } else {
            handleCatChange()
        }
    }

    const handleShowAllWO = async () => {
        await axios.get('/wo/all', {
            onUploadProgress: (e) => {
                console.log(e)
            }
        }).then((res) => {
            if (res.status === 200) {
                // dispatcher(setWO(res.data.wos))
                setSwitchWO(true)
                setWoss(res.data.wos)
                const data = res.data.wos?.filter((item) => {
                    return String(item.cust_proj).toLowerCase() === String(document.getElementById('floatingSelect2').value).toLocaleLowerCase()
                })
                setFilteredWos(data)
            }
        }).catch((e) => {
            console.log(e)
        })
        setLoading({ ...loading, loading: false })
    }

    const handleShowLessWO = async () => {
        await axios.get('/wo/').then((res) => {
            if (res.status === 200) {
                // dispatcher(setWO(res.data.wos))
                setSwitchWO(false)
                setWoss(res.data.wos)
                const data = res.data.wos?.filter((item) => {
                    return String(item.cust_proj).toLowerCase() === String(document.getElementById('floatingSelect2').value).toLocaleLowerCase()
                })
                setFilteredWos(data)
            }
        }).catch((e) => {
            console.log(e)
        })
        setLoading({ ...loading, loading: false })
    }

    useEffect(() => {
        (async function () {
            let find = {}
            setData(true)
            await axios.get('/customer/select').then((res) => {
                setCust(res.data.custs)
                find["cust_proj"] = res.data.custs[0]
            }).catch((e) => {
                console.log(e)
            })
            await axios.get('/wo/').then((res) => {
                if (res.status === 200) {
                    // dispatcher(setWO(res.data.wos))
                    console.log(res.data.wos)
                    setWoss(res.data.wos)
                    const data = res.data.wos?.filter((item) => {
                        return String(item.cust_proj).toLowerCase() === String(find.cust_proj).toLocaleLowerCase()
                    })
                    setFilteredWos(data)
                    if (!data?.length > 0) {
                        setData(false)
                    }
                }
            }).catch((e) => {
                console.log(e)
            })
            await axios.get('/category/').then((res) => {
                if (res.status === 200) {
                    setCategories(res.data.categories)
                }
            }).catch((e) => {
                console.log(e)
            })
        })()
    }, [])

    useEffect(() => {
        socket.on('wo', (wo) => {
            console.log(wo)
            // dispatcher(setWO(wo))
            setLoading({ ...loading, loading: false, isLoading: false })
            setSwitchWO(false)
            let { value } = document.getElementById('floatingSelect2')
            let find = {}
            find.search = document.getElementById('searchInput').value
            find.catName = document.getElementById('floatingSelect3').value
            find.catSubType = document.getElementById('floatingSelect4').value
            let temp = wo.filter((item) => {
                return String(item.cust_proj).toLowerCase() === String(value).toLocaleLowerCase()
            })
            if (find.catName !== 'all') {
                let temp1 = [...temp]
                temp = temp1.filter((item) => {
                    return String(item.catName).toLowerCase() === String(find.catName).toLocaleLowerCase()
                })
                if (find.catSubType !== 'all') {
                    let temp2 = [...temp]
                    temp = temp2.filter((item) => {
                        return String(item.catSubType).toLowerCase() === String(find.catSubType).toLocaleLowerCase()
                    })
                }
            }
            setWoss(wo)
            const data = temp.filter((item) => {
                return (String(item.ppoItem).toLowerCase().match(String(find.search).toLocaleLowerCase())
                    || String(item["wo#"]).toLowerCase().match(String(find.search).toLocaleLowerCase())
                    || String(item["article#"]).toLowerCase().match(String(find.search).toLocaleLowerCase())
                )
            })
            if (!data?.length > 0) {
                setData(false)
            }
            setFilteredWos(data)
            console.log("wo changed")
        })
        socket.on('customerSelect', (customers) => {
            setFilteredWos([])
            setCust(customers)
            handleCustChange(customers)
        })
        socket.on('category', (cat) => {
            setCategories(cat)
        })
    }, [socket])

    useEffect(() => {
        let { value } = document.getElementById('floatingSelect2')
        if (value) {
            const temp = wos?.filter((item) => {
                return String(item["cust_proj"]).toLowerCase().match(String(value).toLocaleLowerCase())
            })
            const data = temp?.filter((item) => {
                return (String(item.ppoItem).toLowerCase().match(String(search).toLocaleLowerCase())
                    || String(item["wo#"]).toLowerCase().match(String(search).toLocaleLowerCase())
                    || String(item["article#"]).toLowerCase().match(String(search).toLocaleLowerCase())
                )
            })
            setFilteredWos(data)
        }
    }, [search])

    return (
        <div className="woDashboard mainHeight" >
            {/* <MesNav name='woDashboard' /> */}
            {/* <div className="contentHeader">
            </div> */}
            <div className="d-flex justify-content-between py-3 px-0 w-100">
                <div className="form-floating filtercust">
                    <select className="form-select" id='floatingSelect2' name='cust_proj' onChange={(e) => handleCustChange(e)}>
                        {cust?.map((option, index) => {
                            return <option key={index} value={option} >{option}</option>
                        })}
                    </select>
                    <label htmlFor="floatingSelect2">Select Customer</label>
                </div>
                <div className="form-floating filtercust">
                    <select className="form-select" id='floatingSelect3' name='catName' defaultValue='all' onChange={(e) => handleCatChange(e)}>
                        <option value='all'>All</option>
                        {
                            [...new Set(categories?.reduce((arr, item) => {
                                if (item?.enable) {
                                    arr[0].push(item.catName)
                                } else {
                                    arr[1].push(item.catName)
                                }
                                return arr
                            }, [[], []])?.[0])]?.map((option, index) => {
                                return <option key={index} value={option}>{option}</option>
                            })
                        }
                    </select>
                    <label htmlFor="floatingSelect3">Select Product Group</label>
                </div>
                <div className="form-floating filtercust">
                    <select className="form-select" id='floatingSelect4' name='catSubType' defaultValue='all' onChange={(e) => handleCatSubTypeChange(e)}>
                        <option value='all'>All</option>
                        {
                            categories?.filter((item1) => {
                                if (item1?.enable) {
                                    return String(item1["catName"]).toLowerCase() === (String(document.getElementById("floatingSelect3").value).toLocaleLowerCase())
                                } else {
                                    return false
                                }
                            }).map((item2) => {
                                if (item2?.enable) {
                                    return item2.catType
                                }
                            })?.map((option, index) => {
                                return <option key={index} value={option}>{option}</option>
                            })
                        }
                    </select>
                    <label htmlFor="floatingSelect4">Select Type</label>
                </div>
                <div className="form-floating searchBox input-group-sm select_box">
                    <input className='form-control' id='searchInput' placeholder=' ' value={search} onChange={(e) => setSearch(e.target.value)} />
                    <label htmlFor="floatingTextarea">Search WO# / PpoItem / Article#</label>
                </div>
            </div>
            {/* {JSON.stringify(filteredwos)} */}
            {/* {JSON.stringify(loading)} */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading.loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='card'>
                <DataTable
                    columns={columns}
                    data={filteredwos}
                    pagination
                    fixedHeader
                    paginationDefaultPage={1}
                    fixedHeaderScrollHeight="450px"
                    highlightOnHover
                    // actions={<input className='form-control' placeholder='Search WO#/PpoItem' value={search} onChange={(e) => setSearch(e.target.value)} />}
                    noDataComponent={data ? <div className='p-3'><Spin /></div> : <div className='p-3'>No Data</div>}
                    conditionalRowStyles={conditionalRowStyles}
                />
            </div>
            <Button type='primary' onClick={() => { switchWO ? handleShowLessWO() : handleShowAllWO(); setLoading({ ...loading, loading: true }) }}>{switchWO ? "Show current WO" : "Show all WO"}</Button>
        </div>
    )
}
