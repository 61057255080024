import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetUserDetails } from "../../../hooks/useGetUserDetails";

export default function RequiredAuth({ allowedRoles, children }) {
  const { isUser, user } = useGetUserDetails();
  const navigate = useNavigate()
  // const { user } = useSelector((state) => state.userInfo.value);
  const location = useLocation();
  // const access = { "Article Master": 2, "Stages Master": 2, "UOM Master": 2, "Customer Master": 2, "Supplier Master": 2, "Instrument Master": 2, "Test Plan Master": 2, "WO": 2, "Process Reports": 2, "Incoming Quality": 2, "Quality Reports": 2, "PPO": 2, "Plots": 2, "Roles": 2 }
  const access = user.access
  // console.log(access, allowedRoles)

  // console.log(
  //   user ? (
  //     user?.verified ? (
  //       false ? (
  //         "Permission"
  //       ) : (
  //         <h1>No permission</h1>
  //       )
  //     ) : (
  //       <h1>Not verified </h1>
  //     )
  //   ) : (
  //     <h1>No user found</h1>
  //   )
  // );
  return (
    isUser ?
      Object.keys(access).includes(allowedRoles) ?
        <div>
          {children}
        </div> :
        <div>
          401
        </div> :
      navigate("/")
  )
  // return user ? (user?.verified ? ( true ? <Outlet /> : <h2>No permission</h2>) : <div className="d-flex justify-content-center align-items-center"><h2 className="text-center"> <span className="text-capitalize">{user?.userName}</span>, Your Verification is in process By team SNS.. </h2></div>) : <h2>No user found</h2>
  // return user?.roles?.find((role) => allowedRoles?.includes(role)) && user?.verified ? ( <Outlet /> ) : user ? ( <p>User Not verified</p>) : ( <Navigate to="unauthorized" state={{ from: location }} replace />)
};