import React, { useEffect, useState } from 'react'
import { Form, FloatingLabel } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../../api/axios';
import { Masternav } from '../../helper/MasterNav';
import moment from 'moment';
import { formValidator } from '../../../../../services/formValidator';
import { Button, message, Spin } from 'antd';
import { socket } from '../../../../../services/socket';
import { Backdrop, CircularProgress } from '@mui/material';
import { useGetUserDetails } from '../../../../../hooks/useGetUserDetails';

export const Supplier = ({ hidden }) => {
    const { user } = useGetUserDetails()
    const [suppForm, setSuppForm] = useState({ supp_name: '', supplies: '', addr_1: '', addr_2: '' })
    const [supp, setSupp] = useState([])
    const [filteredsupp, setFilteredSupp] = useState([])
    const [search, setSearch] = useState("")
    const [formValidationError, setFormValidationError] = useState({});
    const [loading, setLoading] = useState({
        uploading: false,
        enabling: false,
        data: true,
    })

    const handleInput = (e) => {
        setSuppForm({ ...suppForm, [e.target.name]: e.target.value })
    }

    const handleCancel = () => {
        setSuppForm({ supp_name: '', supplies: '', addr_1: '', addr_2: '' })
        setFormValidationError({})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formError = formValidator(suppForm, { supp_name: 'Supplier Name Required', supplies: 'Supplies Required' })
        setFormValidationError(formError)
        console.log(formError)
        if (Object.keys(formError).length === 0) {
            await axios.post('/supplier/add', suppForm).then((res) => {
                if (res.status === 200) {
                    message.success(res.data.msg)
                    setSuppForm({ supp_name: '', supplies: '', addr_1: '', addr_2: '' })
                } else if (res.status === 202) {
                    message.warning(res.data.msg)
                    setLoading({ ...loading, uploading: false })
                    setSuppForm({ supp_name: '', supplies: '', addr_1: '', addr_2: '' })
                } else {
                    message.error("Data not saved")
                    setLoading({ ...loading, uploading: false })
                    setSuppForm({ supp_name: '', supplies: '', addr_1: '', addr_2: '' })
                }
            }).catch((e) => {
                setLoading({ ...loading, uploading: false })
                console.log(e)
            })
        }
    }

    const handleEnable = async (e, _id) => {
        await axios.post('/supplier/enable', { _id, "enable": e.target.checked }).then((res) => {
            if (res.status !== 200) {
                setLoading({ ...loading, enabling: false })
            }
        }).catch((e) => {
            setLoading({ ...loading, enabling: false })
            console.log(e)
        })
    }

    const columns = [
        {
            name: 'Supplier Name',
            cell: row => row.supp_name
        },
        {
            name: 'Supplies',
            cell: row => row.supplies,
        },
        {
            name: 'Addr Line 1',
            cell: row => row.addr_1,
            center: true,
        },
        {
            name: 'Addr Line 2',
            cell: row => row.addr_2,
            center: true,
        },
        {
            name: 'Active',
            cell: row => <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={row.enable} onChange={(e) => { setLoading({ ...loading, enabling: true }); handleEnable(e, row._id); }} disabled={loading.enabling} style={{ cursor: 'pointer' }} />
            </div>,
            width: '100px',
            omit: hidden,
            center: true,
        },
        {
            name: 'Date',
            selector: row => <div>{moment(row.date).utc().format("DD-MM-YYYY")}</div>,
            width: '110px',
            right: true,
            omit: user?.access?.Date > 0 ? false : true,
        },
    ]

    useEffect(() => {
        (async function () {
            await axios.get('/supplier/all').then((res) => {
                setFilteredSupp(res.data.supps)
                setSupp(res.data.supps)
                if (!res.data.supps?.length > 0) {
                    setLoading({ ...loading, data: false })
                }
            }).catch((e) => {
                console.log(e)
            })
        })()
    }, [])

    useEffect(() => {
        socket.on('supplier', (suppliers) => {
            setFilteredSupp(suppliers)
            setSupp(suppliers)
            setLoading({
                uploading: false,
                enabling: false
            })
        })
    }, [socket])

    useEffect(() => {
        const res = supp.filter((item) => {
            return item.supp_name.toLowerCase().match(search.toLocaleLowerCase())
        })
        setFilteredSupp(res)
    }, [search])

    const navigate = useNavigate();
    return (
        <div className='mainHeight'>
            <Masternav name='supplier' />
            <div>
                <div className='card'>
                    <DataTable
                        columns={columns}
                        data={filteredsupp}
                        pagination
                        fixedHeader
                        paginationDefaultPage={1}
                        fixedHeaderScrollHeight="410px"
                        highlightOnHover
                        actions={<input className='form-control' placeholder='Search Supplier Name' value={search} onChange={(e) => setSearch(e.target.value)} />}
                        noDataComponent={loading.data ? <div className='p-3'><Spin /></div> : <div className='p-3'>No Data</div>}
                    />
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading.uploading || loading.enabling}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <hr />
                <div className='card'>
                    <section className='masterbody' hidden={hidden}>
                        <FloatingLabel label="Supplier name">
                            <Form.Control name='supp_name' type="text" onInput={handleInput} value={suppForm?.supp_name} placeholder="none" autoComplete="off" />
                            <p className="text-danger">{formValidationError['supp_name']}</p>
                        </FloatingLabel>
                        <FloatingLabel label="Supplies">
                            <Form.Control name='supplies' type="text" onInput={handleInput} value={suppForm?.supplies} placeholder="none" autoComplete="off" />
                            <p className="text-danger">{formValidationError['supplies']}</p>
                        </FloatingLabel>
                        <FloatingLabel label="Address Line 1">
                            <Form.Control name='addr_1' type="text" onInput={handleInput} value={suppForm?.addr_1} placeholder="none" autoComplete="off" />
                            <p className="text-danger">{formValidationError['addr_1']}</p>
                        </FloatingLabel>
                        <FloatingLabel label="Address Line 2">
                            <Form.Control name='addr_2' type="text" onInput={handleInput} value={suppForm?.addr_2} placeholder="none" autoComplete="off" />
                            <p className="text-danger">{formValidationError['addr_2']}</p>
                        </FloatingLabel>
                    </section>
                    <section className='masterbtn' hidden={hidden}>
                        <div >
                            <Button type="primary" onClick={(e) => { setLoading({ ...loading, uploading: true }); handleSubmit(e) }} loading={loading.uploading}>
                                {loading.uploading ? 'Adding' : 'Add'}
                            </Button>
                            <Button type="primary" onClick={handleCancel}>Cancel</Button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}
