import React from 'react'
import { Link } from 'react-router-dom';
import { Nav, Navbar, Container } from 'react-bootstrap';
import WithPermission from '../../../Auth/Permissions/WithPermission';
import { Tabs } from 'antd';

export const ReportsNav = ({ name }) => {
    const tabs = [
        {
            role: "Process Reports",
            link: "/mesHome/reports/process",
            name: "process",
            title: "Process Reports",
        },
        {
            role: "Quality Reports",
            link: "/mesHome/reports/quality",
            name: "quality",
            title: "Quality Reports",
        },
    ]

    return (
        <div>
            {/* <Navbar bg="light" variant="light" expand="lg">
                <Container fluid>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto my-2 my-lg-0 responsive-nav d-flex justify-content-center"
                            style={{ maxHeight: '200px', width: "100%" }}
                            navbarScroll
                        >
                            <WithPermission roleRequired="Process Reports">
                                <Nav.Link ><Link to="/mesHome/reports/process" style={name === 'process' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>Process Reports</Link></Nav.Link>
                            </WithPermission>
                            <WithPermission roleRequired="Quality Reports">
                                <Nav.Link ><Link to="/mesHome/reports/quality" style={name === 'quality' ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>Quality Reports</Link></Nav.Link>
                            </WithPermission>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar> */}
            <Tabs centered defaultActiveKey={name} tabPosition="top"
                items={
                    tabs.map((tab) => {
                        return {
                            label: <WithPermission roleRequired={tab.role}><Link to={tab.link} style={name === tab.name ? { 'background': '#0D6EFD', 'color': 'white' } : { 'background': '##6c757d' }} className='btn btn-light'>{tab.title}</Link></WithPermission>,
                            key: tab.name,
                        }
                    })
                }
            />
        </div>
    )
}
