import React, { useState } from 'react'
import { FloatingLabel, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Masternav } from '../../helper/MasterNav';
import moment from 'moment';
import axios from '../../../../../api/axios';
import { formValidator } from '../../../../../services/formValidator';
import { useEffect } from 'react';
import { Button, message, Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { socket } from '../../../../../services/socket';
import { Backdrop, CircularProgress } from '@mui/material';
import { useGetUserDetails } from '../../../../../hooks/useGetUserDetails';

export const TestPlan = ({ hidden }) => {
    const { user } = useGetUserDetails()
    const [testForm, setTestForm] = useState({
        art_no: "",
        elec_mech: "",
        instr_name: "",
        param_desc: "",
        ref_unit: "",
        ref_val: "",
        test_desc: "",
        tolerance: "",
        tolerance_unit: "",
    })
    const [tests, setTests] = useState([])
    const [filteredtest, setFilteredTest] = useState([])
    const [search, setSearch] = useState("")
    const [cust, setCust] = useState([])
    const [art, setArt] = useState([])
    const [filteredArt, setFilteredArt] = useState([])
    const [uom, setUom] = useState([])
    const [inst, setInst] = useState([])
    const [formValidationError, setFormValidationError] = useState({});
    const [isDisabled, setDisabled] = useState(false);
    const [isHidden, setHidden] = useState(true);
    const [loading, setLoading] = useState({
        uploading: false,
        enabling: false,
        data: true,
    })
    const [elecFileList, setElecFileList] = useState([]);
    const [mechFileList, setMechFileList] = useState([]);

    const handleInput = (e) => {
        setTestForm({ ...testForm, [e.target.name]: e.target.value })
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        await axios
            .post('/testplan/update', testForm)
            .then((res) => {
                if (res.status === 200) {
                    message.success(res.data.msg)
                    setTestForm({
                        art_no: "",
                        elec_mech: "",
                        instr_name: "",
                        param_desc: "",
                        ref_unit: "",
                        ref_val: "",
                        test_desc: "",
                        tolerance: "",
                        tolerance_unit: "",
                    })
                } else {
                    message.error('Data not updated')
                    setLoading({ ...loading, uploading: false })
                }
            })
            .catch((e) => {
                setLoading({ ...loading, uploading: false })
                console.log(e);
            });
        setDisabled(false);
        setHidden(true);
    };

    const handleEdit = (e, rowId, colId) => {
        // console.log(rowId);
        // console.log(colId);
        // console.log(tests);
        let testAll = tests.find((a) => {
            return a._id === rowId;
        });
        let testInd = testAll.parms.find((a) => {
            return a._id === colId
        })
        setTestForm({
            rowId: rowId,
            colId: colId,
            art_no: testAll.art_no,
            elec_mech: testInd.elec_mech,
            instr_name: testInd.instr_name,
            param_desc: testInd.param_desc,
            ref_unit: testInd.ref_unit,
            ref_val: testInd.ref_val,
            test_desc: testAll.test_desc,
            tolerance: testInd.tolerance,
            tolerance_unit: testInd.tolerance_unit,
        });
        setDisabled(true);
        setHidden(false);
    };

    const handleCancel = () => {
        setTestForm({
            art_no: "",
            elec_mech: "",
            instr_name: "",
            param_desc: "",
            ref_unit: "",
            ref_val: "",
            test_desc: "",
            tolerance: "",
            tolerance_unit: "",
        })
        setFormValidationError({})
        setDisabled(false);
        setHidden(true);
    }

    const handleEnable = async (e, rowId, colId) => {
        await axios.post('/testplan/enable', { rowId, colId, "enable": e.target.checked }).then((res) => {
            if (res.status !== 200) {
                setLoading({ ...loading, enabling: false })
            }
        }).catch((e) => {
            setLoading({ ...loading, enabling: false })
            console.log(e)
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formError = formValidator(testForm, {
            art_no: "Article Num Required",
            elec_mech: "Elect/Mech Required",
            instr_name: "Instrument Name Required",
            ref_unit: "Reference Unit Required",
            ref_val: "Reference Value Required",
            tolerance: "Tolerance Value Required",
            tolerance_unit: "Tolerance Unit Required"
        })
        setFormValidationError(formError)
        if (Object.keys(formError).length === 0) {
            setLoading({ ...loading, uploading: true });
            await axios.post('/testplan/add', testForm).then((res) => {
                if (res.status === 200) {
                    message.success(res.data.msg)
                    setTestForm({
                        art_no: "",
                        elec_mech: "",
                        instr_name: "",
                        param_desc: "",
                        ref_unit: "",
                        ref_val: "",
                        test_desc: "",
                        tolerance: "",
                        tolerance_unit: "",
                    })
                } else if (res.status === 202) {
                    message.warning(res.data.msg)
                    setLoading({ ...loading, uploading: false })
                    setTestForm({
                        art_no: "",
                        elec_mech: "",
                        instr_name: "",
                        param_desc: "",
                        ref_unit: "",
                        ref_val: "",
                        test_desc: "",
                        tolerance: "",
                        tolerance_unit: "",
                    })
                } else {
                    setLoading({ ...loading, uploading: false })
                    message.error('Data not saved')
                }
            }).catch((e) => {
                setLoading({ ...loading, uploading: false })
                console.log(e)
            })
        }
    }

    const handleCustChange = async (newCust) => {
        const { value } = document.getElementById('floatingSelect2')
        let temp = []
        if (newCust?.length > 0) {
            await axios.get('/article/').then((res) => {
                if (res.status === 200) {
                    setArt(res.data.articles)
                    temp = res.data.articles?.filter((item) => {
                        return String(item?.cust_proj).toLowerCase() === String(newCust?.[0]).toLocaleLowerCase()
                    })
                    setFilteredArt(temp)
                }
            })
        } else {
            temp = art?.filter((item) => {
                return String(item?.cust_proj).toLowerCase() === String(value).toLocaleLowerCase()
            })
            setFilteredArt(temp)
            console.log(temp)
        }
    }

    const handleUpload = () => {
        // console.log(elecFileList, mechFileList)
        let artno = document.getElementById('floatingTextarea').value
        // console.log(artno)
        const formData = new FormData();
        if (elecFileList.length > 0 && mechFileList.length > 0) {
            elecFileList.forEach((file) => {
                // console.log(...file)
                // file = { ...file, 'ab': 'aba' }
                formData.append('elecfiles', file);
            });
            mechFileList.forEach((file) => {
                // console.log(...file)
                // file = { ...file, 'ab': 'aba' }
                formData.append('mechfiles', file);
            });
            if (artno) {
                // fetch(`http://localhost:8000/testplan/upload/${artno}`, {
                fetch(`https://snsindustries.co.in/api/testplan/upload/${artno}`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((res) => res.json())
                    .then(() => {
                        setMechFileList([]);
                        setElecFileList([]);
                        message.success('uploaded successfully.');
                    })
                    .catch((e) => {
                        console.log(e)
                        message.error('upload failed.');
                    })
            } else {
                message.error('Enter article number');
            }
        } else {
            message.error('Please upload necessary files');
        }
    }

    const props1 = {
        multiple: false,
        onRemove: (file) => {
            const index = elecFileList.indexOf(file);
            const newFileList = elecFileList.slice();
            newFileList.splice(index, 1);
            setElecFileList(newFileList);
        },
        beforeUpload: (file) => {
            // console.log(file)
            if (file.type === 'application/pdf') {
                setElecFileList([file]);
            } else {
                message.error('Upload PDF files only')
                const index = elecFileList.indexOf(file);
                const newFileList = elecFileList.slice();
                newFileList.splice(index, 1);
                setElecFileList(newFileList);
            }
            return false;
        },
        elecFileList,
    };

    const props2 = {
        multiple: false,
        onRemove: (file) => {
            const index = mechFileList.indexOf(file);
            const newFileList = mechFileList.slice();
            newFileList.splice(index, 1);
            setMechFileList(newFileList);
        },
        beforeUpload: (file) => {
            // console.log(file)
            if (file.type === 'application/pdf') {
                setMechFileList([file]);
            } else {
                message.error('Upload PDF files only')
                const index = mechFileList.indexOf(file);
                const newFileList = mechFileList.slice();
                newFileList.splice(index, 1);
                setMechFileList(newFileList);
            }
            return false;
        },
        mechFileList,
    };

    const columns = [
        {
            name: 'Article Num',
            cell: row => <td title={row.art_no}>{row.art_no}</td>,
            width: '150px',
        },
        {
            name: 'Test Desc',
            cell: row => row.test_desc,
            width: '150px',
        },
        {
            name: 'Param Desc',
            selector: row => row.parms.map((test) => {
                return <tr>
                    <td className='p-3 px-0' title={test.param_desc}>{test.param_desc}</td>

                </tr>
            }),
        },
        {
            name: 'Elec/Mech',
            selector: row => row.parms.map((test) => {
                return <tr>
                    <td className='p-3 px-0'>{test.elec_mech}</td>
                </tr>
            }),
            width: '95px',
            center: true,
        },
        {
            name: 'Ref_Val',
            selector: row => row.parms.map((test) => {
                return <tr>
                    <td className='p-3 px-0'>{test.ref_val}</td>
                </tr>
            }),
            width: '80px',
            center: true,
        },
        {
            name: 'Ref_unit',
            selector: row => row.parms.map((test) => {
                return <tr>
                    <td className='p-3 px-0'>{test.ref_unit}</td>
                </tr>
            }),
            width: '85px',
            center: true,
        },
        {
            name: 'Instrument Name',
            selector: row => row.parms.map((test) => {
                return <tr>
                    <td className='p-3 px-0' title={test.instr_name}>{test.instr_name}</td>
                </tr>
            }),
            width: '150px',
            center: true,
        },
        {
            name: 'Tol',
            selector: row => row.parms.map((test) => {
                return <tr>
                    <td className='p-3 px-0'>{test.tolerance}</td>
                </tr>
            }),
            width: '65px',
            center: true,
        },
        {
            name: 'Tol_unit',
            selector: row => row.parms.map((test) => {
                return <tr>
                    <td className='p-3 px-0'>{test.tolerance_unit}</td>
                </tr>
            }),
            width: '80px',
            center: true,
        },
        {
            name: 'Enable/Disable',
            selector: row => row.parms.map((test) => {
                return <tr>
                    <td className='m-0 px-0' style={{ padding: "10px" }}>
                        <div className="form-check form-switch px-5">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={test.enable} onChange={(e) => { setLoading({ ...loading, enabling: true }); handleEnable(e, row._id, test._id); }} disabled={loading.enabling} style={{ cursor: 'pointer' }} />
                        </div>
                    </td>
                </tr>
            }),
            width: '120px',
            omit: hidden,
            center: true,
        },
        {
            name: 'Date',
            selector: row => row.parms.map((test) => {
                return <tr>
                    <td className='p-3 px-0'>{moment(row.date).utc().format("DD-MM-YYYY")}</td>
                </tr>
            }),
            width: '100px',
            center: true,
            omit: user?.access?.Date > 0 ? false : true,
        },
        {
            name: 'Edit',
            selector: row => row.parms.map((test) => {
                return <td className='d-flex p-2 px-0'>
                    <Button Button type="primary" disabled={isDisabled} onClick={(e) => handleEdit(e, row._id, test._id)}>
                        Edit
                    </Button>
                </td>
            }),
            width: '90px',
            omit: hidden,
            right: true,
        },
        // {
        //     name: 'Date',
        //     selector: row => moment(row.date).utc().format("DD-MM-YYYY"),
        //     width:"6%",
        // },
        // {
        //     name: 'Edit',
        //     cell: (row) => (
        //         <td>
        //             <Button Button type="primary" disabled={isDisabled} onClick={(e, _id) => handleEdit(e, row._id)}>
        //                 Edit
        //             </Button>
        //         </td>
        //     ),
        // },
    ]

    useEffect(() => {
        (async function () {
            let find = {}
            await axios.get('/customer/select').then((res) => {
                setCust(res.data.custs)
                find["cust_proj"] = res.data.custs[0]
            })
            await axios.get('/testplan/all').then((res) => {
                setTests(res.data.testplans)
                setFilteredTest(res.data.testplans)
                if (!res.data.testplans > 0) {
                    setLoading({ ...loading, data: false })
                }
            })
            await axios.get('/article/').then((res) => {
                if (res.status === 200) {
                    setArt(res.data.articles)
                    const temp = res.data.articles.filter((item) => {
                        return String(item?.cust_proj).toLowerCase().match(String(find?.cust_proj).toLocaleLowerCase())
                    })
                    setFilteredArt(temp)
                }
            })
            await axios.get('/uom/select').then((res) => {
                setUom(res.data.uoms)
            })
            await axios.get('/instrument/all').then((res) => {
                setInst(res.data.instruments)
            })
        })()
    }, [])

    useEffect(() => {
        socket.on('testPlan', (testPlans) => {
            setTests(testPlans)
            setFilteredTest(testPlans)
            setLoading({
                uploading: false,
                enabling: false
            });
        })
        socket.on('customerSelect', (customers) => {
            setCust(customers)
            handleCustChange(customers)
        })
        socket.on('article', (articles) => {
            setArt(articles)
            const temp = articles.filter((item) => {
                return String(item?.cust_proj).toLowerCase().match(String(document.getElementById("floatingSelect2").value).toLocaleLowerCase())
            })
            setFilteredArt(temp)
        })
        socket.on('uomSelect', (Uoms) => {
            setUom(Uoms)
        })
        socket.on('instrument', (instruments) => {
            setInst(instruments)
        })
    }, [socket])

    useEffect(() => {
        const res = tests.filter((item) => {
            return String(item.art_no).toLowerCase().match(String(search).toLocaleLowerCase())
        })
        setFilteredTest(res)
    }, [search])

    return (
        <div className='mainHeight'>
            <Masternav name='Qualityplan' />
            <div className='card'>
                <DataTable
                    columns={columns}
                    data={filteredtest}
                    pagination
                    fixedHeader
                    paginationDefaultPage={1}
                    fixedHeaderScrollHeight="410px"
                    highlightOnHover
                    actions={<input className='form-control' placeholder='Search' value={search} onChange={(e) => setSearch(e.target.value)} />}
                    noDataComponent={loading.data ? <div className='p-3'><Spin /></div> : <div className='p-3'>No Data</div>}
                />
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading.uploading || loading.enabling}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <hr />
            <div className='card'>
                <section className='Test_body' hidden={hidden}>
                    <section className='Test_body_col1'>
                        <div className="d-flex justify-content-between px-0 w-100">
                            <div className="form-floating filtercust w-100">
                                <select className="form-select" id='floatingSelect2' name='cust_proj' onChange={(e) => handleCustChange(e)}>
                                    {cust?.map((option, index) => {
                                        return <option key={index} value={option} >{option}</option>
                                    })}
                                </select>
                                <label for="floatingSelect2">Select Customer</label>
                            </div>
                        </div>
                        <FloatingLabel label="">
                            <form class="form-floating input-group-sm select_box">
                                <input class="form-control" id="floatingTextarea" list="datalistOptions1" placeholder="Select Article Num" value={testForm.art_no} name='art_no' disabled={isDisabled} onInput={handleInput} autoComplete="off" />
                                <datalist id="datalistOptions1" >
                                    {filteredArt && filteredArt?.map((option, index) => (
                                        option.enable ?
                                            < option key={index} value={option?.art_no} />
                                            :
                                            null
                                    ))}
                                </datalist>
                                <label for="floatingTextarea">Select Article Num</label>
                                <p className="text-danger">{formValidationError['art_no']}</p>
                            </form>
                        </FloatingLabel>
                        <FloatingLabel label="Test Description">
                            <Form.Control
                                as="textarea"
                                placeholder="Leave a comment here"
                                name='test_desc'
                                value={testForm.test_desc}
                                onInput={handleInput}
                                autoComplete="off"
                            />
                        </FloatingLabel>
                    </section>
                    <section className='Test_body_col2'>
                        <Form.Group >
                            {/* <Form.Label>Electrical</Form.Label> */}
                            {/* <Form.Control id="elecFileInput" name='elec_file' type="file" /> */}
                            <Upload {...props1}>
                                <Button className='d-flex align-items-center' style={{ marginTop: 16, }} icon={<UploadOutlined />}>Select File for Electrical</Button>
                            </Upload>
                        </Form.Group>
                        <Form.Group >
                            {/* <Form.Label>Mechanical</Form.Label> */}
                            {/* <Form.Control id="mechFileInput" name='mech_file' type="file" /> */}
                            <Upload {...props2}>
                                <Button className='d-flex align-items-center' style={{ marginTop: 16, }} icon={<UploadOutlined />}>Select File for Mechanical</Button>
                            </Upload>
                        </Form.Group>
                        <Button style={{ 'height': 'fit-content' }} type="primary" onClick={handleUpload}>Map Drawing</Button>
                    </section>
                    <section className='Test_body_col3'>
                        <FloatingLabel label="Parameter Description">
                            <Form.Control
                                as="textarea"
                                placeholder="Leave a comment here"
                                style={{ height: '100%' }}
                                name='param_desc'
                                value={testForm.param_desc}
                                onInput={handleInput}
                                autoComplete="off"
                            />
                        </FloatingLabel>
                        <div className='Test_body_col4'>
                            <form class="form-floating input-group-sm select_box" >
                                <input class="form-control" id="elec_mech_list" list="datalistOptions2" placeholder="Select Elect/Mech" value={testForm.elec_mech === "elect" || testForm.elec_mech === "Electrical" ? "Electrical" : testForm.elec_mech === "mech" || testForm.elec_mech === "Mechanical" ? "Mechanical" : ""} name='elec_mech' onInput={handleInput} autoComplete="off" />
                                <datalist id="datalistOptions2">
                                    <option value="Electrical" />
                                    <option value="Mechanical" />
                                </datalist>
                                <label for="elec_mech_list">Select Elect/Mech</label>
                                <p className="text-danger">{formValidationError['elec_mech']}</p>
                            </form>
                            <FloatingLabel label="Reference Value">
                                <Form.Control name='ref_val' type="text" placeholder="none" value={testForm.ref_val} onInput={handleInput} autoComplete="off" />
                                <p className="text-danger">{formValidationError['ref_val']}</p>
                            </FloatingLabel>
                            <form class="form-floating input-group-sm select_box" >
                                <input class="form-control" id="Reference_Unit_List" list="datalistOptions3" placeholder="Select Reference Unit" value={testForm.ref_unit} name='ref_unit' onInput={handleInput} autoComplete="off" />
                                <datalist id="datalistOptions3" >
                                    {uom && uom.map((option, index) => {
                                        return <option key={index} value={option} />
                                    })}
                                </datalist>
                                <label for="Reference_Unit_List">Select Reference Unit</label>
                                <p className="text-danger">{formValidationError['ref_unit']}</p>
                            </form>
                            <form class="form-floating input-group-sm select_box" >
                                <input class="form-control" id="Instrument_Name_List" list="datalistOptions4" placeholder="Select Instrument Name" value={testForm.instr_name} name='instr_name' onInput={handleInput} autoComplete="off" />
                                <datalist id="datalistOptions4">
                                    {inst && inst.map((option, index) => (
                                        option.enable ?
                                            <option key={index} value={option.instr_name} />
                                            :
                                            null
                                    ))}
                                </datalist>
                                <label for="Instrument_Name_List">Select Instrument Name</label>
                                <p className="text-danger">{formValidationError['instr_name']}</p>
                            </form>
                            <FloatingLabel label="Tolerance Value">
                                <Form.Control name='tolerance' type="text" placeholder="none" value={testForm.tolerance} onInput={handleInput} autoComplete="off" />
                                <p className="text-danger">{formValidationError['tolerance']}</p>
                            </FloatingLabel>
                            <div class="form-floating input-group-sm" >
                                <input class="form-control" id="Tolerance_Unit_List" list="datalistOptions5" placeholder="Select Tolerance Unit" value={testForm.tolerance_unit} name='tolerance_unit' onInput={handleInput} autoComplete="off" />
                                <datalist id="datalistOptions5">
                                    {uom && uom.map((option, index) => {
                                        return <option key={index} value={option} />
                                    })}
                                </datalist>
                                <label for="Tolerance_Unit_List">Select Tolerance Unit</label>
                                <p className="text-danger">{formValidationError['tolerance_unit']}</p>
                            </div>
                        </div>
                    </section>
                </section>
                <section className='masterbtn' hidden={hidden}>
                    <div >
                        <Button type="primary" hidden={!isHidden} onClick={(e) => { handleSubmit(e); }} loading={loading.uploading}>
                            {loading.uploading ? 'Adding Parameter' : 'Add Parameter'}</Button>
                        <Button variant="outline-primary" style={{ backgroundColor: "#1FB556", color: "white" }} hidden={isHidden} onClick={(e) => { setLoading({ ...loading, uploading: true }); handleUpdate(e); }} loading={loading.uploading}>
                            {loading.uploading ? 'Updating' : 'Update'}
                        </Button>
                        <Button type="primary" onClick={handleCancel}>Cancel</Button>
                    </div>
                </section>
            </div>
        </div>
    )
}
