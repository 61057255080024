import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux'
import { MesNav } from "../helper/MesNav"
import { Spin, message, Upload, Button } from 'antd';
import { PPOWO } from '../helper/PPOWO';
import axios from '../../../api/axios';
import "./style.scss"
import { setPPOS } from '../../../features/ppos';
import { socket } from '../../../services/socket';
import moment from "moment";
import { useGetUserDetails } from '../../../hooks/useGetUserDetails';
import { Backdrop, CircularProgress } from '@mui/material';

const { Dragger } = Upload;

export const PPO = ({ hidden }) => {
    const { isUser, user } = useGetUserDetails();
    let res = useSelector((state) => state.pposInfo.value.ppos);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);
    const [filteredppoData, setFilteredPpoData] = useState([]);
    const [ppoData, setPpoData] = useState([]);
    const [cust, setCust] = useState([])
    const [currCust, setCurrCust] = useState('')
    const [search, setSearch] = useState("")
    const [switchPPO, setSwitchPPO] = useState(false)
    const [categories, setCategories] = useState([])

    const dispatcher = useDispatch()

    const props = {
        name: "ppo",
        multiple: false,
        // action: "http://localhost:8000/ppo/upload",
        action: "https://snsindustries.co.in/api/ppo/upload",
        data: { currCust, currDate: moment(new Date()).utc() },
        onChange(info) {
            const { status } = info.file;

            if (status !== "uploading") {
                console.log(info.file, info.fileList);
            }

            if (status === "done") {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
        },

        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
    };

    const columns = [
        {
            name: 'ADD WO',
            cell: row => <PPOWO ppo={row} />,
            width: '90px',
            omit: hidden,
            hide: 'sm',
        },
        // {
        //     name: 'PPO#',
        //     cell: row => row["ppo#"],
        // },
        // {
        //     name: 'ITEM#',
        //     cell: row => row["item#"],
        // },
        {
            name: 'PPoItem',
            cell: row => `${row["ppo#"]}-${row["item#"]}`,
        },
        {
            name: 'ARTICLE#',
            cell: row => row["article#"],
        },
        {
            name: 'Product Group',
            cell: row => row.catName,
        },
        {
            name: 'Type',
            cell: row => row.catSubType,
        },
        {
            name: 'DATE',
            cell: row => moment(row.ppoDate).utc().format("DD-MM-YYYY"),
            hide: 'md',
            omit: user?.access?.Date > 0 ? false : true,
        },
        {
            name: 'DESCRIPTION',
            cell: row => row.description,
            hide: 'md',
        },

        {
            name: 'QTY',
            cell: row => row.qty,
            right: true,
        },
        {
            name: 'UOM',
            cell: row => row.uom,
            right: true,
            hide: 'md',
        },
        {
            name: 'PRICE/UNIT',
            cell: row => row["price/unit"],
            width: '106px',
            right: true,
            hide: 'md',
        },
    ]

    const handleCustChange = async (newCust) => {
        let find = {}
        let data = []
        let { value } = document.getElementById('floatingSelect2')
        find["cust_proj"] = newCust?.length > 0 ? newCust?.[0] : value
        setCurrCust(newCust?.length > 0 ? newCust?.[0] : value)
        setData(true)
        if (newCust?.length > 0) {
            await axios.get('/ppo/').then((res) => {
                if (res.status === 200) {
                    setPpoData(res.data.ppos)
                    document.getElementById('floatingSelect2').value = find.cust_proj
                    data = res.data.ppos?.filter((item) => {
                        return String(item.cust_proj).toLowerCase() === String(find.cust_proj).toLocaleLowerCase()
                    })
                    setFilteredPpoData(data)
                }
            })
        } else {
            data = ppoData.filter((item) => {
                return String(item.cust_proj).toLowerCase() === String(find.cust_proj).toLocaleLowerCase()
            })
            setFilteredPpoData(data)
        }
        if (!data?.length > 0) {
            setData(false)
        }
    }

    const handleCatChange = (e) => {
        let { value } = document.getElementById('floatingSelect3')
        if (value !== 'all') {
            let filteredPpoDataTemp = [...ppoData]
            let find = {}
            find["cust_proj"] = document.getElementById('floatingSelect2')?.value
            let data = filteredPpoDataTemp.filter((item) => {
                return String(item.cust_proj).toLowerCase() === String(find.cust_proj).toLocaleLowerCase()
            }).filter((item) => {
                return String(item.catName).toLowerCase() === String(value).toLocaleLowerCase()
            })
            setFilteredPpoData(data)
            if (!data?.length > 0) {
                setData(false)
            }
        } else {
            handleCustChange()
        }
    }

    const handleCatSubTypeChange = (e) => {
        let { value } = document.getElementById('floatingSelect4')
        if (value !== 'all') {
            let filteredPpoDataTemp = [...ppoData]
            let find = {}
            find["cust_proj"] = document.getElementById('floatingSelect2')?.value
            find["catName"] = document.getElementById('floatingSelect3')?.value
            let data = filteredPpoDataTemp.filter((item) => {
                return String(item.cust_proj).toLowerCase() === String(find.cust_proj).toLocaleLowerCase()
            }).filter((item) => {
                return String(item.catName).toLowerCase() === String(find.catName).toLocaleLowerCase()
            }).filter((item) => {
                return String(item.catSubType).toLowerCase() === String(value).toLocaleLowerCase()
            })
            setFilteredPpoData(data)
            if (!data?.length > 0) {
                setData(false)
            }
        } else {
            handleCatChange()
        }
    }

    const handleShowAllPPO = async () => {
        await axios.get('/ppo/all').then((res) => {
            if (res.status === 200) {
                // dispatcher(setPPOS(res.data.ppos))
                setSwitchPPO(true)
                setPpoData(res.data.ppos)
                let data = res.data.ppos.filter((item) => {
                    return String(item.cust_proj).toLowerCase() === String(document.getElementById('floatingSelect2')?.value).toLocaleLowerCase()
                })
                setFilteredPpoData(data)
            }
        }).catch((e) => {
            console.log(e)
        })
        setLoading(false)
    }

    const handleShowLessPPO = async () => {
        await axios.get('/ppo/').then((res) => {
            if (res.status === 200) {
                // dispatcher(setPPOS(res.data.ppos))
                setSwitchPPO(false)
                setPpoData(res.data.ppos)
                let data = res.data.ppos.filter((item) => {
                    return String(item.cust_proj).toLowerCase() === String(document.getElementById('floatingSelect2')?.value).toLocaleLowerCase()
                })
                setFilteredPpoData(data)
            }
        }).catch((e) => {
            console.log(e)
        })
        setLoading(false)
    }

    useEffect(() => {
        (async function () {
            await axios.get('/ppo/').then((res) => {
                if (res.status === 200) {
                    // dispatcher(setPPOS(res.data.ppos))
                    setPpoData(res.data.ppos)
                    console.log(res.data.ppos)
                }
            }).catch((e) => {
                console.log(e)
            })
            let find = {}
            await axios.get('/customer/select').then((res) => {
                setCust(res.data.custs)
                find.cust_proj = res.data.custs[0]
            }).catch((e) => {
                console.log(e)
            })
            await axios.get('/category/').then((res) => {
                if (res.status === 200) {
                    setCategories(res.data.categories)
                }
            }).catch((e) => {
                console.log(e)
            })
        })()
    }, [])

    useEffect(() => {
        socket.on('ppo', (ppo) => {
            setPpoData(ppo)
            const temp = ppo.filter((item) => {
                return String(item.cust_proj).toLowerCase() === String(document.getElementById('floatingSelect2')?.value).toLocaleLowerCase()
            })
            setFilteredPpoData(temp)
        })
        socket.on('customerSelect', (customers) => {
            setFilteredPpoData([])
            handleCustChange(customers)
            setCust(customers)
        })
        socket.on('category', (cat) => {
            setCategories(cat)
        })
    }, [socket])

    useEffect(() => {
        if (currCust) {
            const temp = ppoData.filter((item) => {
                return String(item["cust_proj"]).toLowerCase().match(String(currCust).toLocaleLowerCase())
            })
            const res = temp.filter((item) => {
                return (String(item["ppo#"]).toLowerCase().match(String(search).toLocaleLowerCase())
                    || String(item["article#"]).toLowerCase().match(String(search).toLocaleLowerCase())
                )
            })
            setFilteredPpoData(res)
        }
    }, [search])

    return (
        <div className='mainHeight'>
            <div>
                {/* <MesNav name='ppoDashboard' /> */}
                <div className='searchBoxContainer'>
                    <div className="searchAndUploadPPo p-1">
                        <div hidden={hidden}>
                            <Dragger {...props} style={{ width: 'max-content' }} disabled={currCust === '' ? true : false}>
                                <p className="ant-upload-text mx-3 my-0">Click or drag file to this area to upload</p>
                            </Dragger>
                        </div>
                        {/* <div className="py-3 px-0 w-100"> */}

                        {/* </div> */}
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-3 px-0 w-100">
                        <div className="form-floating filtercust">
                            <select className="form-select" id='floatingSelect2' name='cust_proj' defaultValue='' onChange={(e) => handleCustChange(e)}>
                                <option value=''>Select Customer to view or upload</option>
                                {cust?.map((option, index) => {
                                    return <option key={index} value={option} >{option}</option>
                                })}
                            </select>
                            <label htmlFor="floatingSelect2">Select Customer</label>
                        </div>
                        <div className="form-floating filtercust">
                            <select className="form-select" id='floatingSelect3' name='catName' defaultValue='all' onChange={(e) => handleCatChange(e)}>
                                <option value='all'>All</option>
                                {
                                    [...new Set(categories?.reduce((arr, item) => {
                                        if (item?.enable) {
                                            arr[0].push(item.catName)
                                        } else {
                                            arr[1].push(item.catName)
                                        }
                                        return arr
                                    }, [[], []])?.[0])]?.map((option, index) => {
                                        return <option key={index} value={option}>{option}</option>
                                    })
                                }
                            </select>
                            <label htmlFor="floatingSelect3">Select Product Group</label>
                        </div>
                        <div className="form-floating filtercust">
                            <select className="form-select" id='floatingSelect4' name='catSubType' defaultValue='all' onChange={(e) => handleCatSubTypeChange(e)}>
                                <option value='all'>All</option>
                                {
                                    categories?.filter((item1) => {
                                        if (item1?.enable) {
                                            return String(item1["catName"]).toLowerCase() === (String(document.getElementById("floatingSelect3").value).toLocaleLowerCase())
                                        } else {
                                            return false
                                        }
                                    }).map((item2) => {
                                        if (item2?.enable) {
                                            return item2.catType
                                        }
                                    })?.map((option, index) => {
                                        return <option key={index} value={option}>{option}</option>
                                    })
                                }
                            </select>
                            <label htmlFor="floatingSelect4">Select Type</label>
                        </div>
                        <div className="form-floating searchBox input-group-sm">
                            <input className='form-control' placeholder='Search PPO#/ARTICLE#' value={search} onChange={(e) => setSearch(e.target.value)} />
                            <label htmlFor="floatingTextarea">Search WO# / PpoItem / Article#</label>
                        </div>
                    </div>
                </div>
            </div>
            {/* {JSON.stringify(filteredppoData)} */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='card'>
                <DataTable
                    columns={columns}
                    data={filteredppoData}
                    pagination
                    fixedHeader
                    paginationDefaultPage={1}
                    fixedHeaderScrollHeight="450px"
                    highlightOnHover
                    // actions={<input className='form-control' placeholder='Search PPO#/ARTICLE#' value={search} onChange={(e) => setSearch(e.target.value)} />}
                    noDataComponent={data ? <div className='p-3'><Spin /></div> : <div className='p-3'>No Data.</div>}
                />
            </div>
            <Button type='primary' onClick={() => { switchPPO ? handleShowLessPPO() : handleShowAllPPO(); setLoading(true) }}>{switchPPO ? "Show current PPO" : "Show all PPO"}</Button>
        </div>
    )
}
